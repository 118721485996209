const STORAGE_KEYS = ['token', 'role', 'lang']

const StorageService = {
  get(key) {
    const isValidKey = STORAGE_KEYS.includes(key)
    if (isValidKey) {
      const data = localStorage.getItem(key)

      return data
    }
    return false
  },

  save(key, data) {
    const isValidKey = STORAGE_KEYS.includes(key)
    if (isValidKey) {
      localStorage.setItem(key, data)
    }
    return false
  },

  remove(key) {
    const isValidKey = STORAGE_KEYS.includes(key)
    if (isValidKey) {
      localStorage.removeItem(key)
    }
    return false
  },
}

export { StorageService }
