import ApiService from '@/services/api'

const state = {
  brand: false,
  brands: false,
}

const getters = {
  getBrand: state => state.brand,
  getBrands: state => state.brands,
}

const actions = {
  async index({ commit }, params) {
    const { data, ok } = await ApiService.index('brands', params)

    if (ok) {
      commit('setBrands', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('brands', id)

    if (ok) {
      commit('setBrand', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setBrand(state, brand) {
    state.brand = brand
  },

  setBrands(state, brands) {
    state.brands = brands
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
