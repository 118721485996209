import ApiService from '@/services/api'

const state = {
  tag: false,
  tags: false,
}

const getters = {
  getTag: state => state.tag,
  getTags: state => state.tags,
}

const actions = {
  async index({ commit }, params) {
    const { data, ok } = await ApiService.index('tags', params)

    if (ok) {
      commit('setTags', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('tags', id)

    if (ok) {
      commit('setTag', data)
    }

    return { data, ok }
  },

  async create({ commit }, payload) {
    const { data, ok } = await ApiService.create({
      resource: 'tags',
      data: payload,
    })

    if (ok) {
      commit('setTag', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.update({
      resource: 'tags',
      id,
      data: payload,
    })

    if (ok) {
      commit('setTag', data)
    }

    return { data, ok }
  },

  async delete(_, id) {
    const { ok } = await ApiService.delete('tags', id)

    return { ok }
  },
}

const mutations = {
  setTag(state, tag) {
    state.tag = tag
  },

  setTags(state, tags) {
    state.tags = tags
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
