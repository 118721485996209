import ApiService from '@/services/api'

const state = {
  contentDistribution: null,
  kpiProgress: null,
  providerSentimentDistribution: null,
  sentimentDistribution: null,
  commentsDistribution: null,
}

const getters = {
  getContentDistribution: state => state.contentDistribution,
  getKpiProgress: state => state.kpiProgress,
  getProviderSentimentDistribution: state =>
    state.providerSentimentDistribution,
  getSentimentDistribution: state => state.sentimentDistribution,
  getCommentsDistribution: state => state.commentsDistribution,
}

const actions = {
  async contentDistribution({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/dashboard/content_distribution`,
      params
    )

    if (ok) {
      commit('setContentDistribution', data)
    }

    return { data, ok }
  },

  async kpiProgress({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/dashboard/kpi_progress/`,
      params
    )

    if (ok) {
      commit('setKpiProgress', data)
    }

    return { data, ok }
  },

  async providerSentimentDistribution({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/dashboard/comments/sentiments/providers/count`,
      params
    )

    if (ok) {
      commit('setProviderSentimentDistribution', data)
    }

    return { data, ok }
  },

  async sentimentDistribution({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/dashboard/comments/sentiments/count`,
      params
    )

    if (ok) {
      commit('setSentimentDistribution', data)
    }

    return { data, ok }
  },

  async commentsDistribution({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/dashboard/comments/distribution`,
      params
    )

    if (ok) {
      commit('setCommentsDistribution', data)
    }

    return { data, ok }
  },

  clear({ commit }) {
    commit('setContentDistribution', null)
  },
}

const mutations = {
  setContentDistribution(state, contentDistribution) {
    state.contentDistribution = contentDistribution
  },

  setKpiProgress(state, kpiProgress) {
    state.kpiProgress = kpiProgress
  },

  setProviderSentimentDistribution(state, providerSentimentDistribution) {
    state.providerSentimentDistribution = providerSentimentDistribution
  },

  setSentimentDistribution(state, sentimentDistribution) {
    state.sentimentDistribution = sentimentDistribution
  },

  setCommentsDistribution(state, commentsDistribution) {
    state.commentsDistribution = commentsDistribution
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
