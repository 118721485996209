<template>
  <div>
    <div class="modal-body py-0" v-loading="isLoading">
      <h2 class="mb-0">
        {{
          isUpdate
            ? $t('app_modal.contents.update_title')
            : $t('app_modal.contents.add_title')
        }}
      </h2>
      <p class="text-muted text-sm">
        {{
          isUpdate
            ? $t('app_modal.contents.update_subtitle')
            : $t('app_modal.contents.add_subtitle')
        }}
      </p>
      <validation-observer v-slot="{ invalid }">
        <div class="row mb-4">
          <div class="col">
            <card class="mb-3">
              <div class="row align-items-center" v-if="!isVideo">
                <div class="col-auto" v-if="hasMedias">
                  <app-image
                    :alt="caption"
                    :src="hasMedias"
                    style="height: 64px; width: 64px; object-fit: cover"
                    class="bg-light rounded"
                  />
                </div>
                <div class="col ml--2">
                  <template v-if="hasProvider">
                    <p class="text-sm text-muted mb-0" v-if="hasCaption">
                      {{ caption | truncate(100) }}
                    </p>
                    <p class="text-sm text-muted mb-0" v-else>
                      {{ $t('app_modal.contents.no_data') }}
                    </p>
                  </template>
                  <template v-else>
                    <div
                      class="row"
                      :class="{ 'mb--3': !isUpdate || hasMedias }"
                    >
                      <div class="col">
                        <base-input name="caption">
                          <textarea
                            class="form-control"
                            rows="5"
                            resize="vertical"
                            v-model="payload.data.caption"
                          ></textarea>
                        </base-input>
                      </div>
                    </div>
                  </template>
                  <template v-if="!hasMedias && isUpdate">
                    <dropzone-file-upload
                      v-model="payload.media_url"
                      :loading="isLoading"
                      @add="addFile"
                    />
                  </template>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col text-center">
                  <video class="card-img" controls>
                    <source :src="hasMedias" type="video/mp4" />
                  </video>
                </div>
              </div>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-left">
            <base-input
              :label="$t('app_modal.contents.price.label')"
              :name="$t('app_modal.contents.price.name')"
              rules="min_value:0"
              min="0"
              type="number"
              v-model.number="payload.price"
              @input="handlePrice"
            >
              <template slot="infoBlock">
                <div class="mt--2 mb-2">
                  <small>{{
                    $t('app_modal.contents.price.helper_text')
                  }}</small>
                </div>
              </template>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6 text-left">
            <base-input
              :label="$t('app_modal.contents.type.label')"
              :name="$t('app_modal.contents.type.name')"
              :rules="{ required: true }"
            >
              <el-select
                class="select-danger"
                :placeholder="$t('app_modal.contents.type.placeholder')"
                v-model="payload.type"
                style="width: 100%"
              >
                <el-option
                  v-for="type in types.results"
                  class="select-danger"
                  :value="type.id"
                  :label="type.name"
                  :key="type.id"
                />
              </el-select>
            </base-input>
          </div>
          <div class="col-6 text-left">
            <base-input
              :label="$t('app_modal.contents.provider.label')"
              :name="$t('app_modal.contents.provider.name')"
              :rules="{ required: true }"
            >
              <el-select
                class="select-danger"
                :placeholder="$t('app_modal.contents.provider.placeholder')"
                v-model="payload.internal_provider"
                style="width: 100%"
              >
                <el-option
                  v-for="internalProvider in internalProviders.results"
                  class="select-danger"
                  :value="internalProvider.id"
                  :label="internalProvider.name"
                  :key="internalProvider.id"
                />
              </el-select>
            </base-input>
          </div>

          <div v-if="!hasPublicationDate" class="col-12 text-left">
            <base-input
              :label="$t('app_modal.contents.publication_date.label')"
              :name="$t('app_modal.contents.publication_date.name')"
              :rules="{ required: !hasPublicationDate }"
              type="datetime-local"
              v-model="publicationDate"
              @input="parseDate"
            >
            </base-input>
          </div>
          <div class="col-12 text-left">
            <base-input
              :label="$t('app_modal.contents.product.label')"
              :name="$t('app_modal.contents.product.name')"
              :rules="{ required: true }"
            >
              <el-select
                class="select-danger"
                :placeholder="$t('app_modal.contents.product.placeholder')"
                v-model="payload.product"
                style="width: 100%"
              >
                <el-option
                  v-for="product in products.results"
                  class="select-danger"
                  :value="product.id"
                  :label="product.name"
                  :key="product.id"
                />
              </el-select>
            </base-input>
          </div>
          <div class="col-12">
            <base-input
              :label="$t('app_modal.contents.tags.label')"
              :name="$t('app_modal.contents.tags.name')"
            >
              <el-select
                multiple
                class="select-primary"
                :placeholder="$t('app_modal.contents.tags.placeholder')"
                v-model="payload.tags"
                style="width: 100%"
                @input="handleTags"
              >
                <el-option
                  v-for="tag in tags.results"
                  class="select-primary"
                  :value="tag.id"
                  :label="tag.name"
                  :key="tag.id"
                />
                <el-option :value="addTagOption">
                  <span class="text-primary">
                    {{ $t('app_modal.contents.tags.button') }}
                  </span>
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-12">
            <base-input
              :label="$t('app_modal.contents.is_publi.label')"
              :name="$t('app_modal.contents.is_publi.name')"
            >
              <base-switch
                v-model="payload.is_publi"
                :on-text="$t('app_modal.contents.is_publi.on_text')"
                :off-text="$t('app_modal.contents.is_publi.off_text')"
              />
            </base-input>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <base-button type="danger" @click="deleteContent" v-if="isUpdate">
              {{ $t('app_modal.contents.buttons.delete') }}
            </base-button>
          </div>
          <div class="col-8 text-right">
            <base-button type="secondary" @click="$emit('close')">
              {{ $t('app_modal.contents.buttons.close') }}
            </base-button>
            <base-button
              type="primary"
              :disabled="invalid || !formUpdated"
              @click="updateContent"
              v-if="isUpdate"
            >
              {{ $t('app_modal.contents.buttons.update') }}
            </base-button>
            <base-button
              type="primary"
              :disabled="invalid"
              @click="createContent"
              v-else
            >
              {{ $t('app_modal.contents.buttons.add') }}
            </base-button>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import Swal from 'sweetalert2'

import { mapActions, mapGetters } from 'vuex'

import isEqual from 'lodash/isEqual'
import getProperty from 'lodash/get'

import getFormData from '@/helpers/getFormData'
import { localToIso, isoToLocal } from '@/helpers/date'

export default {
  name: 'modal-contents',

  props: {
    options: {
      type: [Object, Boolean],
    },
  },

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    'dropzone-file-upload': () =>
      import('@/components/argon/Inputs/DropzoneFileUpload'),
  },

  data() {
    return {
      method: 'create',
      isLoading: false,
      depacker: false,
      contentMedia: false,
      media: false,
      formUpdated: false,
      initialData: false,
      publicationDate: null,
      payload: {
        native_id: null,
        campaign_creator: null,
        product: null,
        internal_provider: null,
        type: null,
        tags: [],
        data: {},
        price: null,
        is_publi: true,
      },
      addTagOption: 'ADD_TAG',
    }
  },

  watch: {
    payload: {
      handler: 'updated',
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      campaign: 'campaigns/getCampaign',
      checkService: 'providers/checkService',
      checkPublicationDate: 'contents/checkPublicationDate',
      campaignCreator: 'campaignCreators/getCampaignCreator',
      content: 'contents/getContent',
      internalProviders: 'internalProviders/getInternalProviders',
      products: 'products/getProducts',
      tags: 'tags/getTags',
      types: 'types/getTypes',
    }),

    isUpdate() {
      return this.options.method && this.options.method === 'update'
    },

    campaignId() {
      return this.$route.params.campaignId
    },

    contentId() {
      return this.options.id
    },

    brandId() {
      return this.campaign ? this.campaign.brand.id : false
    },

    hasMedias() {
      if (!!this.contentMedia.medias && !!this.contentMedia.medias.length > 0) {
        return this.contentMedia.medias[0].media
      }

      if (this.contentMedia.media_url) {
        return this.contentMedia.media_url
      }

      return false
    },

    hasCaption() {
      return !!this.caption
    },

    caption() {
      if (!!this.contentMedia.data && this.contentMedia.data.caption) {
        return this.contentMedia.data.caption || ''
      }
      if (this.contentMedia.caption) {
        return this.contentMedia.caption || ''
      }

      return ''
    },

    isVideo() {
      return !!(
        this.contentMedia &&
        this.contentMedia.medias &&
        this.contentMedia.medias.length &&
        this.contentMedia.medias[0].is_video
      )
    },

    hasProvider() {
      if (this.isUpdate) {
        const provider =
          getProperty(this.content, 'provider', null) ||
          getProperty(this.contentMedia, 'provider', null)

        if (!provider) {
          return false
        }

        return this.checkService(provider.slug)
      }

      return this.checkService(this.options.depacker.provider)
    },

    hasPublicationDate() {
      if (!this.hasProvider) {
        return false
      }

      let provider, type

      if (this.isUpdate) {
        provider = this.content.provider || this.contentMedia.provider
        provider = provider.slug
        type = this.content.type || this.contentMedia.type
        type = type.slug
      } else {
        provider = this.options.depacker.provider
        type = this.options.depacker.type
      }

      if (!provider || !type) {
        return false
      }

      return this.checkPublicationDate({
        provider,
        type,
      })
    },
  },

  methods: {
    ...mapActions({
      mediaCreate: 'medias/create',
      contentCreate: 'contents/create',
      contentShow: 'contents/show',
      contentUpdate: 'contents/update',
      contentDelete: 'contents/delete',
      internalProviderIndex: 'internalProviders/index',
      productIndex: 'products/index',
      tagIndex: 'tags/index',
      typeIndex: 'types/index',
    }),

    async init() {
      this.$emit('status-changed', false)

      if (this.options.method) {
        this.method = this.options.method
      } else {
        this.contentMedia = this.options.content
      }

      if (this.options.previousPayload) {
        this.payload = this.options.previousPayload
        if (!this.hasPublicationDate && this.payload.data.published_at) {
          this.publicationDate = this.toDateTimeLocalInputFormat(
            this.payload.data.published_at
          )
        }
        this.options.previousPayload = null
      } else {
        if (this.isUpdate) {
          await this.initData()
        } else if (this.options.depacker.type) {
          const { data } = await this.typeIndex({
            search: this.options.depacker.type,
          })

          if (data.count) {
            this.payload.type = data.results[0].id
          }

          await this.typeIndex()
        }
      }

      if (!this.tags) await this.tagIndex({ brand__id: this.brandId })
      if (!this.products) await this.productIndex()
      if (!this.internalProviders) await this.internalProviderIndex()
      if (!this.types) await this.typeIndex()
    },

    async addFile(files) {
      this.isLoading = true

      const isVideo = files[0].type.startsWith('video/')

      const payload = getFormData({
        content: this.contentId,
        is_video: isVideo,
      })

      payload.append('media', files[0])

      const { ok, data } = await this.mediaCreate({
        payload,
        headers: this.headers,
      })

      if (ok) {
        this.options.previousPayload = this.payload
        this.contentMedia.medias = [data]
        await this.init()
        this.$emit('status-changed', true)
      }

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t(
              'app_modal.contents.notifications.file.success_message.text'
            )
          : this.$t('app_modal.contents.notifications.file.error_message.text'),
      })

      this.isLoading = false
    },

    async createContent() {
      this.isLoading = true

      let success

      const { media_url: mediaUrl, video_url: videoUrl } = this.contentMedia

      const actualMediaUrl = videoUrl || mediaUrl

      if (actualMediaUrl) {
        const { ok } = await this.contentCreate({
          ...this.payload,
          media_url: actualMediaUrl,
          native_id: this.contentMedia.id,
          campaign_creator: this.campaignCreator.id,
          data: {
            ...this.payload.data,
            ...this.contentMedia,
          },
        })

        success = ok
      } else {
        const { ok } = await this.contentCreate({
          ...this.payload,
          native_id: this.contentMedia.shortcode
            ? this.contentMedia.shortcode
            : this.contentMedia.id,
          campaign_creator: this.campaignCreator.id,
          data: {
            ...this.payload.data,
            ...this.contentMedia,
          },
        })

        success = ok
      }

      this.isLoading = false

      await Swal.fire(
        success
          ? this.$t(
              'app_modal.contents.notifications.creator.add.success_message.title'
            )
          : this.$t(
              'app_modal.contents.notifications.creator.add.error_message.title'
            ),
        success
          ? this.$t(
              'app_modal.contents.notifications.creator.add.success_message.text'
            )
          : this.$t(
              'app_modal.contents.notifications.creator.add.error_message.text'
            ),
        success ? 'success' : 'error'
      )

      if (success) {
        this.closeAndRefetchContents()
      }
    },

    async updateContent() {
      this.isLoading = true

      const { ok } = await this.contentUpdate({
        id: this.options.id,
        payload: this.payload,
      })

      this.isLoading = false

      await Swal.fire(
        ok
          ? this.$t(
              'app_modal.contents.notifications.creator.update.success_message.title'
            )
          : this.$t(
              'app_modal.contents.notifications.creator.update.error_message.title'
            ),
        ok
          ? this.$t(
              'app_modal.contents.notifications.creator.update.success_message.text'
            )
          : this.$t(
              'app_modal.contents.notifications.creator.update.error_message.text'
            ),
        ok ? 'success' : 'error'
      )

      this.closeAndRefetchContents()
    },

    async deleteContent() {
      const { isConfirmed } = await Swal.fire({
        title: this.$t(
          'app_modal.contents.notifications.creator.delete.confirm_message.title'
        ),
        text: this.$t(
          'app_modal.contents.notifications.creator.delete.confirm_message.text'
        ),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t(
          'app_modal.contents.notifications.creator.delete.confirm_message.confirm_button'
        ),
        cancelButtonText: this.$t(
          'app_modal.contents.notifications.creator.delete.confirm_message.cancel_button'
        ),
        reverseButtons: true,
      })

      if (isConfirmed) {
        const { ok } = await this.contentDelete(this.contentId)

        Swal.fire(
          ok
            ? this.$t(
                'app_modal.contents.notifications.creator.delete.success_message.title'
              )
            : this.$t(
                'app_modal.contents.notifications.creator.delete.error_message.title'
              ),
          ok
            ? this.$t(
                'app_modal.contents.notifications.creator.delete.success_message.text'
              )
            : this.$t(
                'app_modal.contents.notifications.creator.delete.error_message.text'
              ),
          ok ? 'success' : 'error'
        )

        this.closeAndRefetchContents()
      }
    },

    updated(data) {
      this.formUpdated = !isEqual(this.initialData, data)
    },

    parseDate(date) {
      this.payload.data.published_at = localToIso(date)
    },

    toDateTimeLocalInputFormat(date) {
      return isoToLocal(date)
    },

    handleTags(tags) {
      const lastTag = tags.slice(-1)[0]
      if (!lastTag || lastTag !== this.addTagOption) {
        return
      }

      this.payload.tags = tags.slice(0, -1)
      this.options.previousPayload = this.payload
      this.$eventBus.$emit('changeModal', 'tags', 'contents', this.options)
    },

    closeAndRefetchContents() {
      this.$emit('close')
      this.refetchContents()
    },

    refetchContents() {
      this.$eventBus.$emit('refetchContents')
    },

    async initData() {
      await this.contentShow(this.options.id)
      if (!this.content) {
        return
      }

      this.contentMedia = this.content

      this.payload = {
        campaign_creator: this.content.campaign_creator.id,
        native_id: this.content.native_id,
        product: this.content.product.id,
        internal_provider: this.content.internal_provider.id,
        type: this.content.type.id,
        tags: this.content.tags.map(tag => tag.id),
        data: this.content.data,
        price: this.content.price,
        is_publi: this.content.is_publi,
      }

      if (!this.hasPublicationDate) {
        this.publicationDate = this.toDateTimeLocalInputFormat(
          this.content.data.published_at
        )
      }

      this.initialData = {
        ...this.payload,
        data: { ...this.payload.data },
      }

      if (this.publicationDate) {
        this.parseDate(this.publicationDate)
      }
    },

    handlePrice(price) {
      if (price === '') {
        this.payload.price = null
      }
    },
  },

  async mounted() {
    await this.init()
  },
}
</script>
