import _get from 'lodash/get'
import _last from 'lodash/last'

export function get(object, path, defaultValue) {
  return _get(object, path, defaultValue)
}

export function last(array) {
  return _last(array)
}
