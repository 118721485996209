import { UserService } from '../../services/user'
import { StorageService } from '../../services/storage'
import router from '../../router'

const state = {
  loggedUser: false,
  accessToken: StorageService.get('token'),
  error: false,
  isPublicUser: StorageService.get('role') === 'public',
  user: null,
}

const getters = {
  loggedUser: state => {
    return state.loggedUser
  },

  loggedIn: state => {
    return !!state.accessToken
  },

  error: state => {
    return state.error
  },

  isPublicUser: state => {
    return state.isPublicUser
  },

  user: state => {
    return state.user
  },
}

const actions = {
  async login({ commit }, { email, password }) {
    try {
      const { token, user } = await UserService.login(email, password)

      if (token && user) {
        commit('loginSuccess', { token, user })

        router.push(router.history.current.query.redirect || '/app')

        return true
      }

      return false
    } catch (err) {
      return err
    }
  },

  logout({ commit }) {
    UserService.logout()

    commit('logoutSuccess')

    router.push('/signin')
  },
}

const mutations = {
  setUser(state, user) {
    state.user = user
  },

  loginRequest(state) {
    state.authenticating = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },

  loginSuccess(state, { token, user }) {
    const publicUser = !user.is_staff && !user.is_superuser
    state.isPublicUser = publicUser
    state.accessToken = token
    state.loggedUser = user
    state.user = {
      id: user.pk,
      username: user.username,
    }
  },

  loginError(state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
    state.user = null
  },

  logoutSuccess(state) {
    state.accessToken = ''
    state.user = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
