<template>
  <component :is="type" />
</template>

<script>
import HighlightedComments from './partials/HighlightedComments'

export default {
  name: 'app-carousel',

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  components: {
    'highlighted-comments': HighlightedComments,
  },
}
</script>
