import ApiService from '@/services/api'

const state = {
  media: false,
}

const getters = {
  getMedia: state => state.media,
}

const actions = {
  async create({ commit }, { payload, headers }) {
    const { data, ok } = await ApiService.create({
      resource: 'medias',
      data: payload,
      headers,
    })

    if (ok) {
      commit('setMedia', data)
    }

    return { data, ok }
  },

  async delete(_, id) {
    const { ok } = await ApiService.delete('medias', id)

    return { ok }
  },
}

const mutations = {
  setMedia(state, media) {
    state.media = media
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
