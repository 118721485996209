<template>
  <validation-observer v-slot="{ invalid, validate }">
    <form @submit.prevent="validate().then(handleSubmit)">
      <div class="modal-body py-0">
        <div class="row">
          <div class="col">
            <h2 class="mb-0">
              {{ isUpdate ? campaign.name : $t('app_modal.campaigns.title') }}
            </h2>
            <p class="text-muted text-sm">
              {{ $t('app_modal.campaigns.subtitle') }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <base-input
              :label="$t('app_modal.campaigns.name.label')"
              :name="$t('app_modal.campaigns.name.name')"
              :placeholder="$t('app_modal.campaigns.name.placeholder')"
              :rules="{ required: true, min: 6 }"
              v-model="payload.name"
            />
          </div>
          <div class="col-4">
            <base-input
              type="number"
              :label="$t('app_modal.campaigns.budget.label')"
              :name="$t('app_modal.campaigns.budget.name')"
              :placeholder="$t('app_modal.campaigns.budget.placeholder')"
              min="0"
              :rules="{ required: true, min_value: 0 }"
              v-model="payload.data.budget"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-input
              :name="$t('app_modal.campaigns.brand.name')"
              :rules="{ required: true }"
            >
              <el-select
                :placeholder="$t('app_modal.campaigns.brand.placeholder')"
                v-model="payload.brand.id"
                clearable
                filterable
                remote
                :remote-method="searchBrand"
              >
                <el-option
                  v-for="brand in brands.results"
                  :value="brand.id"
                  :label="brand.name"
                  :key="brand.id"
                />
              </el-select>
            </base-input>
          </div>
        </div>
        <div class="row items-align-center mt-3">
          <div class="col">
            <h4>{{ $t('app_modal.campaigns.kpis.title') }}</h4>
          </div>
          <div class="col-auto">
            <i class="ni ni-fat-add text-primary" @click="addKpi()" />
          </div>
        </div>
        <div class="row mt-4">
          <slide-y-down-transition
            :duration="400"
            origin="center top"
            mode="out-in"
            group
          >
            <div
              class="col-12 mt--3"
              v-for="(kpi, index) in payload.kpis"
              :key="index"
            >
              <validation-observer>
                <div class="row">
                  <div class="col pr-0">
                    <base-input
                      :name="$t('app_modal.campaigns.kpis.name')"
                      :rules="{ required: true }"
                    >
                      <el-select
                        class="select-danger"
                        :placeholder="
                          $t('app_modal.campaigns.kpis.placeholder')
                        "
                        v-model="kpi.kpi_id"
                      >
                        <el-option
                          v-for="kpiData in kpis.results"
                          class="select-danger"
                          :value="kpiData.id"
                          :label="$t('app_list.kpis.kpis.' + kpiData.slug)"
                          :key="kpiData.id"
                        />
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col pl-2">
                    <base-input
                      type="number"
                      :name="$t('app_modal.campaigns.kpis.value.name')"
                      min="0"
                      :rules="{ required: true, min_value: 0 }"
                      v-model="kpi.target"
                    />
                  </div>
                  <div class="col-auto">
                    <i
                      class="ni ni-fat-remove mt-3 text-light"
                      @click="removeKpi(index)"
                    />
                  </div>
                </div>
              </validation-observer>
            </div>
          </slide-y-down-transition>
          <div
            class="col-12 text-center"
            v-if="!payload.kpis || payload.kpis.length === 0"
          >
            <base-alert type="secondary">
              <h4 class="text-muted mb-0 mt-0">
                {{ $t('app_modal.campaigns.kpis.no_kpis.title') }}
              </h4>
              <span class="text-xs text-muted font-weight-normal">
                {{ $t('app_modal.campaigns.kpis.no_kpis.subtitle') }}
              </span>
            </base-alert>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <base-button type="link" @click="changeOwner">
              {{ $t('app_modal.campaigns.buttons.change_owner') }}
            </base-button>
          </div>
        </div>
        <div class="row mt-3 mb-2">
          <div class="col" v-if="isUpdate">
            <base-button type="danger" @click="deleteCampaign">{{
              $t('app_modal.campaigns.buttons.delete')
            }}</base-button>
          </div>
          <div class="col text-right">
            <base-button type="secondary" @click="$emit('close')">{{
              $t('app_modal.campaigns.buttons.close')
            }}</base-button>
          </div>
          <div class="col-auto">
            <base-button
              v-if="isUpdate"
              native-type="submit"
              type="primary"
              :disabled="invalid"
              >{{ $t('app_modal.campaigns.buttons.update') }}</base-button
            >
            <base-button
              v-else
              native-type="submit"
              type="primary"
              :disabled="invalid"
              >{{ $t('app_modal.campaigns.buttons.add') }}</base-button
            >
          </div>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'modal-campaigns',

  props: {
    options: {
      type: [Object, Boolean],
      default: () => {
        return {
          method: 'create',
          id: false,
        }
      },
    },
  },

  components: {
    SlideYDownTransition,
  },

  watch: {
    options: {
      handler: 'init',
      immediate: true,
    },
  },

  data() {
    return {
      payload: {
        name: '',
        brand: {
          id: '',
        },
        provider: '',
        kpis: [],
        data: {
          budget: '',
        },
      },
      filter: {
        search: '',
      },
      formType: null,
      formCompleted: false,
    }
  },

  computed: {
    ...mapGetters({
      campaign: 'campaigns/getCampaign',
      brands: 'brands/getBrands',
      kpis: 'kpis/getKpis',
      users: 'users/getUsers',
    }),

    isUpdate() {
      return this.options && this.options.method === 'update'
    },

    campaignId() {
      return this.$route.params.campaignId
    },
  },

  methods: {
    ...mapActions({
      brandIndex: 'brands/index',
      userIndex: 'users/index',
      kpiIndex: 'kpis/index',
      campaignCreate: 'campaigns/create',
      campaignUpdate: 'campaigns/update',
      campaignShow: 'campaigns/show',
      campaignDelete: 'campaigns/delete',
    }),

    async init() {
      await this.brandIndex()

      if (!this.kpis) {
        await this.kpiIndex()
      }

      if (this.isUpdate) {
        const { ok } = await this.campaignShow(this.options.id)

        if (ok) {
          Object.keys(this.payload).forEach(key => {
            let value = this.campaign[key]
            if (key === 'kpis') {
              value = value.map(i => {
                return { kpi_id: i.kpi.id, target: i.target }
              })
            }
            this.payload[key] = value
          })
        }
      }
    },

    async createCampaign() {
      const payload = { ...this.payload }
      payload.brand = payload.brand.id
      const { ok, data } = await this.campaignCreate(payload)

      await Swal.fire(
        ok
          ? this.$t(
              'app_modal.campaigns.notifications.create.success_message.title'
            )
          : this.$t(
              'app_modal.campaigns.notifications.create.error_message.title'
            ),
        ok
          ? this.$t(
              'app_modal.campaigns.notifications.create.success_message.text'
            )
          : this.$t(
              'app_modal.campaigns.notifications.create.error_message.text'
            ),
        ok ? 'success' : 'error'
      )

      if (ok) {
        this.closeModalAndRefetchCampaigns()
        this.$router.push(`/app/campaigns/${data.id}`)
      }
    },

    async updateCampaign() {
      const { ok } = await this.campaignUpdate({
        id: this.options.id,
        payload: {
          name: this.payload.name,
          brand: this.payload.brand.id,
          kpis: this.payload.kpis,
          data: {
            budget: +this.payload.data.budget,
          },
        },
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t(
              'app_modal.campaigns.notifications.update.success_message.text'
            )
          : this.$t(
              'app_modal.campaigns.notifications.update.error_message.text'
            ),
      })

      if (ok) this.closeModalAndRefetchCampaigns()
    },

    async deleteCampaign() {
      const { isConfirmed } = await Swal.fire({
        title: this.$t(
          'app_modal.campaigns.notifications.delete.confirm_message.title'
        ),
        text: this.$t(
          'app_modal.campaigns.notifications.delete.confirm_message.text'
        ),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t(
          'app_modal.campaigns.notifications.delete.confirm_message.confirm_button'
        ),
        cancelButtonText: this.$t(
          'app_modal.campaigns.notifications.delete.confirm_message.cancel_button'
        ),
        reverseButtons: true,
      })

      if (isConfirmed) {
        const { ok } = await this.campaignDelete(this.options.id)

        this.$notify({
          type: ok ? 'success' : 'danger',
          message: ok
            ? this.$t(
                'app_modal.campaigns.notifications.delete.success_message.text'
              )
            : this.$t(
                'app_modal.campaigns.notifications.delete.error_message.text'
              ),
        })

        if (ok) {
          this.closeModalAndRefetchCampaigns()
          this.$router.push('/app')
        }
      }
    },

    addKpi() {
      if (!this.payload.kpis) {
        this.payload = {
          ...this.payload,
          kpis: [{ kpi_id: '', target: 0 }],
        }
      } else {
        this.payload.kpis.push({ kpi_id: '', target: 0 })
      }
    },

    removeKpi(index) {
      this.payload.kpis.splice(index, 1)
    },

    closeModalAndRefetchCampaigns() {
      this.$emit('close')
      this.$eventBus.$emit('refetchCampaigns')
    },

    changeOwner() {
      this.$eventBus.$emit('showModal', 'change-campaign-owner')
    },

    handleSubmit() {
      if (this.isUpdate) {
        return this.updateCampaign()
      } else {
        return this.createCampaign()
      }
    },

    async searchBrand(data) {
      await this.brandIndex({
        search: data,
      })
    },
  },
}
</script>
