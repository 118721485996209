<template>
  <div class="wrapper">
    <app-navbar />
    <app-sidebar />
    <div class="main-content">
      <div class="header bg-gradient-default pt-2 pb-6"></div>
      <app-modal
        type="campaigns"
        :show="showModal"
        @close="showModal = false"
      />
      <div class="header bg-gradient-default pt-2 pb-7"></div>
      <div class="container-fluid mt--9">
        <div @click="$sidebar.displaySidebar(false)">
          <fade-transition :duration="200" origin="center top" mode="out-in">
            <router-view></router-view>
          </fade-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions'
import AppSidebar from './Sidebar'
import AppNavbar from './Navbar'

export default {
  components: {
    FadeTransition,
    AppSidebar,
    AppNavbar,
  },

  data() {
    return {
      showNavbar: true,
      showModal: false,
    }
  },
}
</script>
