<template>
  <div>
    <div class="row">
      <div class="col">
        <p class="text-light text-xs text-uppercase mb-0">
          <i class="ni ni-notification-70 mr-1" />
          {{ $t('pages.navbar.title') }}
        </p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-auto">
        <h1 class="text-white mb-0">
          {{ (campaign || {}).name | capitalize }}
        </h1>
      </div>
      <div class="col">
        <i @click="updateCampaign" class="ni ni-settings-gear-65 text-light" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <router-link
          :to="`/app/campaigns/${campaignId}`"
          slot="title"
          class="btn mr-3"
          :class="
            routeName === 'dashboard' ? 'btn-primary' : 'btn-outline-primary'
          "
          @click.native="reloadCampaign"
        >
          <i :class="dashboardIcon"></i> {{ $t('pages.navbar.overview') }}
        </router-link>
        <router-link
          :to="`/app/campaigns/${campaignId}/calendar`"
          slot="title"
          class="btn mr-3"
          :class="
            routeName === 'calendar' ? 'btn-primary' : 'btn-outline-primary'
          "
        >
          <i class="ni ni-calendar-grid-58"></i>
          {{ $t('pages.navbar.calendar') }}
        </router-link>
        <router-link
          :to="`/app/campaigns/${campaignId}/creators`"
          slot="title"
          class="btn mr-3"
          :class="
            routeName === 'creators' ? 'btn-primary' : 'btn-outline-primary'
          "
        >
          <i class="ni ni-circle-08 mr-2"></i> {{ $t('pages.navbar.creators') }}
        </router-link>
        <router-link
          :to="`/app/campaigns/${campaignId}/contents`"
          slot="title"
          class="btn mr-3"
          :class="
            routeName === 'contents' ? 'btn-primary' : 'btn-outline-primary'
          "
        >
          <i class="ni ni-ungroup mr-2"></i> {{ $t('pages.navbar.contents') }}
        </router-link>
        <router-link
          :to="`/app/campaigns/${campaignId}/comments`"
          slot="title"
          class="btn mr-3"
          :class="
            routeName === 'comments' ? 'btn-primary' : 'btn-outline-primary'
          "
        >
          <i class="fas fa-comments mr-2"></i> {{ $t('pages.navbar.comments') }}
        </router-link>
      </div>
      <div class="col-auto text-right">
        <base-button
          icon
          type="primary"
          @click="$eventBus.$emit('showModal', 'depacker')"
        >
          <i class="ni ni-fat-add" />
        </base-button>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { FETCH_CAMPAIGN_EVENT } from '@/helpers/events'

export default {
  name: 'campaigns-router',

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      campaign: 'campaigns/getCampaign',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    routeName() {
      return this.$route.name
    },

    dashboardIcon() {
      if (!this.isLoading) {
        return 'ni ni-chart-pie-35 mr-2'
      }

      return 'fas fa-spin fa-spinner mr-2'
    },
  },

  methods: {
    ...mapActions({
      campaignShow: 'campaigns/show',
      clearCampaign: 'campaigns/clearCampaign',
    }),

    updateCampaign() {
      this.$eventBus.$emit('showModal', 'campaigns', {
        method: 'update',
        id: this.campaignId,
      })
    },

    async loadCampaign(id) {
      this.isLoading = true
      await this.campaignShow(id)
      this.isLoading = false
    },

    reloadCampaign() {
      this.$eventBus.$emit(FETCH_CAMPAIGN_EVENT, this.campaignId)
    },
  },

  async mounted() {
    this.$eventBus.$on(FETCH_CAMPAIGN_EVENT, this.loadCampaign)

    this.loadCampaign(this.campaignId)
  },

  beforeDestroy() {
    this.$eventBus.$off(FETCH_CAMPAIGN_EVENT)
    this.clearCampaign()
  },
}
</script>
