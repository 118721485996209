export function downloadFileFromResponse(response, fileExtension = 'csv') {
  if (!response.ok) {
    throw new Error('Erro ao baixar o arquivo')
  }

  const contentDisposition =
    response.headers['content-disposition'] ||
    `filename="download.${fileExtension}"`

  let name = contentDisposition.split('filename=')[1].replace(/^"|"$/g, '')
  const byteStringMatch = name.match(/^b'(.+)'$/)
  if (byteStringMatch) {
    name = byteStringMatch[1]
  }

  const url = URL.createObjectURL(new Blob([response.data]))
  handleDownloadAnchorElement(url, name)
  URL.revokeObjectURL(url)
}

export async function downloadFileFromUrl(url) {
  handleDownloadAnchorElement(url)
}

function handleDownloadAnchorElement(url, name = '') {
  const anchorElement = document.createElement('a')
  anchorElement.href = url
  anchorElement.download = name
  anchorElement.target = '_blank'
  anchorElement.click()
}
