import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { StorageService } from '@/services/storage'

import en from 'vee-validate/dist/locale/en'
import pt from 'vee-validate/dist/locale/pt_BR'

import enMessages from '../locales/en-us.json'
import ptMessages from '../locales/pt-br.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: StorageService.get('lang') || 'pt-br',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-us',
  messages: {
    'en-us': {
      ...enMessages,
      validations: {
        messages: {
          ...en.messages,
          ...enMessages.validations.messages,
        },
      },
    },
    'pt-br': {
      ...ptMessages,
      validations: {
        messages: {
          ...pt.messages,
          ...ptMessages.validations.messages,
        },
      },
    },
  },
})
