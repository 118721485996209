import ApiService from '@/services/api'

const state = {
  creator: false,
  creators: false,
}

const getters = {
  getCreator: state => state.creator,
  getCreators: state => state.creators,
}

const actions = {
  async index({ commit }, params) {
    const { data, ok } = await ApiService.index('creators', params)

    if (ok) {
      commit('setCreators', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('creators', id)

    if (ok) {
      commit('setCreator', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.update({
      resource: 'creators',
      id,
      data: payload,
    })

    if (ok) {
      commit('setCreators', data)
    }

    return { data, ok }
  },

  async delete(_, id) {
    const { ok } = await ApiService.delete('creators', id)

    return { ok }
  },

  async search({ commit }, { q, origin }) {
    const { data, ok } = await ApiService.index('creators/search', {
      q,
      origin,
    })

    if (ok) {
      if (data.data.length > 0) {
        commit('setCreator', data.data[0])
      }
      commit('setCreators', data)
    }

    return { data, ok }
  },

  async fetch({ commit }, params) {
    const { data, ok } = await ApiService.index('creators/fetch', params)

    if (ok) {
      commit('setCreator', data.data)
    }

    return { data, ok }
  },

  clear({ commit }) {
    commit('setCreator', false)
    commit('setCreators', false)
  },
}

const mutations = {
  setCreator(state, creator) {
    state.creator = creator
  },

  setCreators(state, creators) {
    state.creators = creators
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
