<template>
  <card>
    <template slot="header">
      <h6 class="surtitle">
        {{ $t('app_dash_charts.content_distribution.surtitle') }}
      </h6>
      <h5 class="h3 mb-0">
        {{ $t('app_dash_charts.content_distribution.title') }}
      </h5>
    </template>
    <apexchart
      v-if="hasData"
      type="donut"
      :options="options"
      :series="series"
    ></apexchart>
    <div v-else>
      <p class="m-0 text-center text-muted">
        {{ $t('app_dash_charts.content_distribution.no_data') }}
      </p>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  async mounted() {
    await this.fetchContentDistribution({ id: this.campaignId })
  },

  computed: {
    ...mapGetters({
      contentDistribution: 'campaignDashboard/getContentDistribution',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    data() {
      if (!this.contentDistribution) {
        return []
      }

      return this.contentDistribution.results
    },

    hasData() {
      return !!this.data.length
    },

    series() {
      return this.data.map(i => i.count)
    },

    options() {
      return {
        labels: this.data.map(i => i.provider.name),
        colors: this.data.map(i => this.getProviderColor(i.provider.slug)),
        legend: {
          position: 'bottom',
        },
      }
    },
  },

  methods: {
    ...mapActions({
      fetchContentDistribution: 'campaignDashboard/contentDistribution',
    }),

    getProviderColor(provider) {
      const colors = {
        facebook: '#3b5999',
        twitter: '#1da1f2',
        instagram: '#e4405f',
        youtube: '#cd201f',
        tiktok: '#010101',
        twitch: '#6441a5',
      }

      return colors[provider] || '#000000'
    },
  },
}
</script>
