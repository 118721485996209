import ApiService from '@/services/api'

const state = {
  product: false,
  products: false,
}

const getters = {
  getProduct: state => state.product,
  getProducts: state => state.products,
}

const actions = {
  async index({ commit }) {
    const { data, ok } = await ApiService.index('products')

    if (ok) {
      commit('setProducts', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('products', id)

    if (ok) {
      commit('setProduct', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setProduct(state, product) {
    state.product = product
  },

  setProducts(state, products) {
    state.products = products
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
