<template>
  <validation-provider :name="name" :rules="rules" v-slot="{ errors }">
    <base-input :label="label" :error="errors[0]">
      <textarea
        ref="textArea"
        v-model="value"
        :class="[{ 'is-invalid': !!errors[0] }]"
        rows="10"
      ></textarea>
    </base-input>
  </validation-provider>
</template>

<script>
import SimpleMDE from 'simplemde'

function copyObject(object) {
  const result = {}

  for (const entry of Object.entries(object)) {
    const [key, value] = entry
    if (isPrimitive(value) || isFunction(value)) {
      result[key] = value
    } else if (isObject(value)) {
      if (Object.getPrototypeOf(value).constructor.name === 'Object') {
        result[key] = copyObject(value)
      } else {
        result[key] = value
      }
    } else if (isArray(value)) {
      result[key] = copyArray(value)
    }
  }

  return result
}

function copyArray(value) {
  if (isPrimitive(value)) {
    return value
  } else if (isObject(value)) {
    return copyObject(value)
  }

  return value.map(copyArray)
}

function isPrimitive(value) {
  return (
    value === null ||
    ['boolean', 'number', 'undefined', 'string'].includes(typeof value)
  )
}

function isFunction(value) {
  return typeof value === 'function'
}

function isArray(value) {
  return Array.isArray(value)
}

function isObject(value) {
  return value !== null && typeof value === 'object' && !isArray(value)
}

function mergeObjects(destination, ...sources) {
  for (const source of sources) {
    if (!isObject(source)) {
      continue
    }

    for (const key in source) {
      let value = source[key]

      if (isObject(value)) {
        value = copyObject(value)
      } else if (isArray(value)) {
        value = copyArray(value)
      }

      if (isObject(value) && isObject(destination[key])) {
        destination[key] = mergeObjects(destination[key], value)
      } else {
        destination[key] = value
      }
    }
  }

  return destination
}

export default {
  name: 'app-base-markdown-input',

  props: {
    value: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      default: '',
    },

    rules: {
      type: [String, Object],
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      simplemde_: null,
    }
  },

  async mounted() {
    const mdeOptions = mergeObjects({}, this.defaultOptions, this.options)
    mdeOptions.element = this.$refs.textArea
    this.simplemde_ = new SimpleMDE(mdeOptions)
    this.simplemde_.codemirror.on('change', () => {
      this.$emit('input', this.simplemde_.value())
    })

    await this.$nextTick()
    this.simplemde_.value(this.value)
  },

  computed: {
    defaultOptions() {
      return {
        autoDownloadFontAwesome: false,
        autofocus: true,
        forceSync: true,
        insertTexts: {
          image: ['![](https://', ')'],
          link: ['[', '](https://)'],
        },
        spellChecker: false,
        status: false,
        toolbar: [
          {
            name: 'heading',
            action: SimpleMDE.toggleHeadingSmaller,
            className: 'fas fa-heading',
          },
          'bold',
          'italic',
          'strikethrough',
          '|',
          'quote',
          '|',
          'table',
          'unordered-list',
          'ordered-list',
          '|',
          'link',
          {
            name: 'image',
            action: SimpleMDE.drawImage,
            className: 'fas fa-image',
          },
          '|',
          'horizontal-rule',
          '|',
          'preview',
        ],
      }
    },
  },
}
</script>
