import ApiService from '@/services/api'

const state = {
  post: false,
  posts: false,
}

const getters = {
  getPost: state => state.post,
  getPosts: state => state.posts,
}

const actions = {
  async index({ commit }, { params }) {
    const { data, ok } = await ApiService.index('posts', params)

    if (ok) {
      commit('setPosts', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('posts', id)

    if (ok) {
      commit('setPost', data)
    }

    return { data, ok }
  },

  async create({ commit }, payload) {
    const { data, ok } = await ApiService.create({
      resource: 'posts',
      data: payload,
    })

    if (ok) {
      commit('setPosts', data)
    }

    return { data, ok }
  },

  async check({ commit }, id) {
    const { data, ok } = await ApiService.show(`posts/${id}/check`)

    if (ok) {
      commit('setPost', data)
    }

    return { data, ok }
  },

  async delete(_, id) {
    const { ok } = await ApiService.delete('posts', id)

    return { ok }
  },

  clear({ commit }) {
    commit('setPost', false)
    commit('setPosts', false)
  },
}

const mutations = {
  setPost(state, post) {
    state.post = post
  },

  setPosts(state, posts) {
    state.posts = posts
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
