import ApiService from '@/services/api'

const state = {
  contentComment: false,
  contentComments: false,
}

const getters = {
  getContentComment: state => state.contentComment,
  getContentComments: state => state.contentComments,
}

const actions = {
  async index({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `contents/${id}/comments`,
      params
    )

    if (ok) {
      commit('setContentComments', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.update({
      resource: 'contents',
      id,
      data: payload,
      relation: 'comments',
      relationId: payload.id,
    })

    if (ok) {
      commit('setContentComment', data)
    }

    return { data, ok }
  },

  async load(_, id) {
    const { ok } = await ApiService.index(`contents/${id}/comments/load`, {
      limit: 200,
    })
    return { ok }
  },

  async exportCSV(_, { id }) {
    return ApiService.api.get(
      `contents/${id}/comments/export/?filetype=csv`,
      {},
      { responseType: 'blob', headers: { Accept: 'text/csv' } }
    )
  },
}

const mutations = {
  setContentComment(state, contentComment) {
    state.contentComment = contentComment
  },

  setContentComments(state, contentComments) {
    state.contentComments = contentComments
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
