import ApiService from '@/services/api'

const state = {
  campaign: false,
  campaigns: false,
}

const getters = {
  getCampaign: state => state.campaign,
  getCampaigns: state => state.campaigns,
}

const actions = {
  async index({ commit }, { params }) {
    const { data, ok } = await ApiService.index('campaigns', params)

    if (ok) {
      commit('setCampaigns', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('campaigns', id)

    if (ok) {
      commit('setCampaign', data)
    }

    return { data, ok }
  },

  async create({ commit }, payload) {
    const { data, ok } = await ApiService.create({
      resource: 'campaigns',
      data: payload,
    })

    if (ok) {
      commit('setCampaign', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.update({
      resource: 'campaigns',
      id,
      data: payload,
    })

    if (ok) {
      commit('setCampaign', data)
    }

    return { data, ok }
  },

  async delete({ commit }, id) {
    const { ok } = await ApiService.delete('campaigns', id)

    return { ok }
  },

  async changeOwner({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.create({
      resource: `campaigns/${id}/ownership`,
      data: payload,
    })

    if (ok) {
      commit('setCampaign', data)
    }

    return { data, ok }
  },

  clearCampaign({ commit }) {
    commit('setCampaign', null)
  },
}

const mutations = {
  setCampaign(state, campaign) {
    state.campaign = campaign
  },

  setCampaigns(state, campaigns) {
    state.campaigns = campaigns
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
