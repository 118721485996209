import ApiService from '@/services/api'

const state = {
  commentSentiment: false,
  commentSentiments: false,
}

const getters = {
  getCommentSentiment: state => state.commentSentiment,
  getCommentSentiments: state => state.commentSentiments,
}

const actions = {
  async create(_, { id, payload }) {
    const { ok } = await ApiService.create({
      resource: 'comments',
      id,
      data: payload,
      relation: 'sentiments',
    })
    return { ok }
  },

  async delete(_, { commentId, id }) {
    const { ok } = await ApiService.delete(
      `comments/${commentId}/sentiments`,
      id
    )
    return { ok }
  },
}

const mutations = {
  setCommentSentiment(state, commentSentiment) {
    state.commentSentiment = commentSentiment
  },

  setCommentSentiments(state, commentSentiments) {
    state.commentSentiments = commentSentiments
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
