var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',{attrs:{"backgroundColor":"primary","logo":"https://brmediagroup.com.br/img/logo-dark@3x.png"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.dashboard'),
        icon: 'fas fa-bullhorn',
        path: '/app/dashboard',
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.posts'),
        icon: 'far fa-images',
        path: '/app/posts',
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.settings'),
        icon: 'fas fa-cog',
        path: '/app/settings',
      }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('ul',{staticClass:"navbar-nav mb-md-3"},[_c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"nav-link",on:{"click":_vm.logout}},[_c('i',{staticClass:"fas fa-sign-out-alt"}),_c('span',{staticClass:"nav-link-text"},[_vm._v(" "+_vm._s(_vm.$t('sidebar.logout'))+" ")])])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }