<template>
  <div>
    <div class="modal-body py-0" v-loading="isLoading">
      <h2 class="mb-0">{{ $t('app_modal.depacker.title') }}</h2>
      <p class="text-muted text-sm">
        {{ $t('app_modal.depacker.subtitle') }}
      </p>
      <div class="row my-3">
        <div class="col">
          <app-depacker @updated="setDepackerData" @clear="clear" />
        </div>
      </div>
      <div class="row">
        <div class="col text-center" v-if="postRegistered">
          <p>Post already registered</p>
          <base-button type="primary" outline @click="redirectToPost">
            View post
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      isUpdated: false,
      isLoading: false,
      postRegistered: false,
      depacker: false,
    }
  },

  computed: {
    ...mapGetters({
      checkService: 'providers/checkService',
      contentMedia: 'contents/getContentMedia',
      content: 'contents/getContent',
      providers: 'providers/getProviders',
      types: 'types/getTypes',
      post: 'posts/getPost',
    }),
  },

  methods: {
    ...mapActions({
      showMedia: 'contents/showMedia',
      postCheck: 'posts/check',
      postCreate: 'posts/create',
      typeIndex: 'types/index',
      providerIndex: 'providers/index',
    }),

    async setDepackerData(data) {
      this.depacker = data
      await this.searchData()
    },

    async searchData() {
      this.isLoading = true

      const {
        data: { data: media },
      } = await this.showMedia({
        id: this.depacker.shortcode,
        origin: this.depacker.provider,
      })

      const { ok } = await this.postCheck(media.id)

      if (!ok) {
        await this.typeIndex({
          search: this.depacker.type,
        })

        await this.providerIndex({
          search: this.depacker.provider,
        })

        const hasType = this.types.results.length > 0
        const hasProvider = this.providers.results.length > 0

        if (hasType && hasProvider) {
          const payload = {
            native_id: media.id,
            type: this.types.results[0].id,
            provider: this.providers.results[0].id,
            data: {},
          }

          const { data: post, ok: postOk } = await this.postCreate(payload)

          if (postOk) {
            this.$router.push(`/app/posts/${post.id}`)
          }
        }
      } else {
        this.postRegistered = true
      }

      this.isLoading = false

      // if (this.hasContent) {
      //   await this.searchContent(hasProvider)
      // }

      // this.isUpdated = true
      // this.isLoading = false

      // this.$emit('status-changed', false)
    },

    clear() {
      // this.contentClear({ contents: false })
      // this.isUpdated = false
      // this.contentRegistered = false
      // this.$emit('status-changed', true)
    },

    redirectToPost() {
      this.hideModal()
      this.$router.push(`/app/posts/${this.post.id}`)
    },

    hideModal() {
      this.$emit('close', {
        withConfirmation: false,
        checkObserver: false,
      })
    },
  },

  mounted() {
    // this.clear()
  },

  beforeDestroy() {
    // this.clear()
  },
}
</script>
