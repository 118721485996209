<template>
  <img
    :src="src ? src : defaultImage"
    :alt="alt"
    :onerror="`this.src = '${defaultImage}'`"
  />
</template>

<script>
export default {
  name: 'app-image',

  props: {
    src: {
      type: [String, Boolean],
      default: null,
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      defaultImage: '/img/error-image.png',
    }
  },
}
</script>
