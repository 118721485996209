<template>
  <div>
    <modal
      :show.sync="hasUpdate"
      size="sm"
      body-classes="pb-0"
      footer-classes="pt-0"
    >
      <template v-if="!!currentUpdate">
        <div class="row justify-content-center">
          <h2 class="m-0">Confirmar Atualização</h2>
        </div>
        <div class="row justify-content-center">
          <h6 class="surtitle mb-3">{{ currentUpdate.name }}</h6>
        </div>
        <div class="row align-items-center">
          <div class="col">
            <div class="row flex-row-reverse">
              <p class="m-0 font-weight-bold text-red text-center">
                {{ currentUpdate.display.from }}
              </p>
            </div>
          </div>
          <div class="col-2 text-center">
            <i class="fas fa-arrow-right text-muted"></i>
          </div>
          <div class="col">
            <div class="row">
              <p class="m-0 font-weight-bold text-green text-center">
                {{ currentUpdate.display.to }}
              </p>
            </div>
          </div>
        </div>
        <div
          slot="footer"
          class="row justify-content-end align-items-center mt-4"
        >
          <div class="col-auto">
            <small>{{ updatedCount + 1 }} / {{ updateCount }}</small>
          </div>
          <div class="col-auto">
            <base-button
              type="danger"
              size="sm"
              icon
              class="btn-icon-only"
              native-type="button"
              round
              @click="nextUpdate"
            >
              <i class="fas fa-thumbs-down"></i>
            </base-button>
          </div>
          <div class="col-auto">
            <base-button
              type="success"
              size="sm"
              icon
              class="btn-icon-only"
              native-type="button"
              round
              @click="saveUpdate"
            >
              <i class="fas fa-thumbs-up"></i>
            </base-button>
          </div>
        </div>
      </template>
    </modal>
    <validation-observer tag="div" ref="observer">
      <div v-if="formIsReady">
        <div class="row">
          <template v-for="(field, index) in fields">
            <div :class="`col-${field.size}`" :key="index + '-input'">
              <component
                v-if="field.group"
                :is="`base-${field.type}`"
                :type="field.inputType"
                :label="field.label"
                :name="field.label"
                :placeholder="field.placeholder"
                :model="field.model"
                :rules="getDynamicRules(field, index)"
                immediate
                v-model.number="
                  (payload[field.model][(dynamic[field.model] || {})[index]] ||
                    {})[field.prop]
                "
                @input="handleDynamicInput(field, index)"
                @keydown.enter="
                  addGroup(fields[field.originalIndex], field.originalIndex)
                "
              />
              <component
                v-else
                :is="`base-${field.type}`"
                :type="field.inputType"
                :label="field.label"
                :name="field.label"
                :placeholder="field.placeholder"
                :model="field.model"
                :rules="field.rules"
                v-model.number="payload[field.model]"
                immediate
              />
            </div>
            <div
              v-if="field.dynamic"
              class="col-auto align-self-center mt-2"
              :key="index + '-remove'"
            >
              <base-button
                @click="clearOrRemoveGroup(field, index)"
                type="danger"
                size="sm"
              >
                <i class="fas fa-trash"></i>
              </base-button>
            </div>
          </template>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import Vue from 'vue'

import { last } from '@/helpers/functional'

export default {
  name: 'app-form-generator',

  props: {
    type: {
      type: String,
      required: false,
    },
    schema: {
      type: Array,
      required: true,
    },
    model: {
      type: [Object, Boolean],
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasUpdate() {
      return !!this.currentUpdate
    },
  },

  watch: {
    payload: {
      handler: function() {
        this.$emit('updated', this.payload)
      },
      deep: true,
    },
  },

  data() {
    const payload = {}
    const dynamic = {}
    const dynamicCount = {}
    if (!this.isUpdate) {
      this.schema.map((field, index) => {
        if (field.group) {
          if (!payload[field.model]) {
            Vue.set(payload, field.model, [])
          }

          if (field.group[field.group.length - 1] === index) {
            const obj = {}
            field.group.forEach(i => {
              obj[this.schema[i].prop] = null
            })

            payload[field.model].push(obj)

            if (!dynamic[field.model]) {
              dynamic[field.model] = {}
              dynamicCount[field.model] = 0
            }

            field.group.forEach(index => {
              dynamic[field.model][index] = dynamicCount[field.model]
            })

            dynamicCount[field.model]++
          }
        } else {
          Vue.set(payload, field.model, null)
        }
      })
    }

    return {
      dynamic,
      payload,
      addedCount: {},
      formIsReady: false,
      fields: this.isUpdate ? [] : [...this.schema],
      updates: [],
      currentUpdate: null,
      updateCount: 0,
      updatedCount: 0,
    }
  },

  mounted() {
    if (this.model) {
      this.payload = this.model
      if (this.isUpdate) {
        const fields = []
        const mappedGroups = []
        const dynamic = {}

        this.schema.forEach((field, index) => {
          const { model } = field

          if (field.group && !mappedGroups.includes(model)) {
            mappedGroups.push(model)

            const hasPayload = this.payload[model].length
            if (!hasPayload) {
              const data = {}
              field.group.forEach(index => {
                data[this.schema[index].prop] = null
                data._ignore = true
              })

              this.payload[model] = [data]
            }

            const payload = this.payload[model]

            const lastGroupIndex = last(field.group)
            const groupLength = field.group.length

            this.addedCount[model] = {}
            this.addedCount[model][lastGroupIndex] =
              (payload.length - 1) * groupLength

            dynamic[model] = {}
            let dynamicCount = 0

            payload.forEach((_, payloadIndex) => {
              field.group.forEach(groupIndex => {
                const dynamicIndex = groupIndex + payloadIndex * groupLength
                dynamic[model][dynamicIndex] = dynamicCount

                fields.push({
                  ...this.schema[groupIndex],
                  originalIndex: lastGroupIndex,
                  group: field.group.map(i => i + payloadIndex * groupLength),
                  removable: payloadIndex !== 0,
                })
              })

              dynamicCount++
            })
          } else if (!field.group) {
            fields.push(field)
          }
        })

        this.dynamic = dynamic
        this.fields = fields
      }
    } else {
      this.schema.map((field, index) => {
        if (!field.group) {
          this.payload[field.model] = field.placeholder
        } else {
          const obj = {}
          field.group.forEach(i => {
            obj[this.schema[i].prop] = null
          })

          this.payload[field.model] = [obj]
        }
      })
    }

    this.formIsReady = true
  },

  beforeDestroy() {
    this.formIsReady = false
    this.payload = {}
  },

  methods: {
    addGroup(field, index, { removable = true } = {}) {
      this.$refs.observer.reset()

      let newFields = this.schema.filter((_, index) =>
        field.group.includes(index)
      )

      if (!this.addedCount[field.model]) {
        this.addedCount[field.model] = {}
      }

      if (!this.addedCount[field.model][index]) {
        this.addedCount[field.model][index] = 0
      }

      newFields = newFields.map(f => ({
        ...f,
        originalIndex: [...field.group].reverse()[0],
        group: field.group.map(
          i => i + (this.addedCount[field.model][index] + newFields.length)
        ),
        removable,
      }))

      const dynamicCount = Math.max(...Object.values(this.dynamic[field.model]))
      field.group.forEach((_, i) => {
        const dynamicIndex = index + 1 + this.addedCount[field.model][index] + i
        Vue.set(this.dynamic[field.model], dynamicIndex, dynamicCount + 1)
      })

      const obj = { _ignore: true }
      field.group.forEach(i => {
        obj[this.schema[i].prop] = null
      })

      this.payload[field.model].push(obj)

      this.fields = [
        ...this.fields.slice(
          0,
          index + 1 + this.addedCount[field.model][index]
        ),
        ...newFields,
        ...this.fields.slice(index + 1 + this.addedCount[field.model][index]),
      ]

      this.addedCount[field.model][index] += newFields.length
    },

    removeGroup(field, index) {
      this.fields = this.fields.filter((_, i) => !field.group.includes(i))
      this.addedCount[field.model][field.originalIndex] -= field.group.length
      this.fields
        .filter((f, i) => f.originalIndex === field.originalIndex && i >= index)
        .forEach(f => (f.group = f.group.map(g => g - field.group.length)))

      Vue.delete(
        this.payload[field.model],
        this.dynamic[field.model][field.group[0]]
      )

      Object.keys(this.dynamic[field.model])
        .map(Number)
        .slice(-field.group.length)
        .forEach(i => {
          Vue.delete(this.dynamic[field.model], i)
        })
    },

    clearOrRemoveGroup(field, index) {
      if (!field.removable) {
        const payload = this.payload[field.model][
          this.dynamic[field.model][field.group[0]]
        ]
        for (const key in payload) {
          payload[key] = null
        }
        payload._ignore = true
        return
      }
      this.removeGroup(field, index)
    },

    handleDynamicInput(field) {
      const index = this.dynamic[field.model][field.group[0]]
      if (typeof index === 'number') {
        this.payload[field.model][index]._ignore = false
      }
    },

    getDynamicRules(field) {
      if (!field.group) {
        return ''
      }

      let rules = field.rules

      const index = this.dynamic[field.model][field.group[0]]
      if (typeof index === 'number') {
        const required = !this.payload[field.model][index]._ignore
        if (required) {
          if (rules) {
            rules += '|'
          }
          rules += 'required'
        }
      }

      return rules
    },

    validate() {
      return this.$refs.observer.validate()
    },

    setUpdates(fields) {
      if (!fields.length) return

      this.updates = fields.map(([key, value]) => {
        let index = null
        const field = this.schema.find(field => {
          if (key.includes('.')) {
            const [model, i] = key.split('.')
            index = i
            return field.model === model
          }

          return field.model === key
        })

        const display = {
          from: value.from,
          to: value.to,
        }

        if (typeof value.from === 'object') {
          display.from = ''

          Object.keys(value.from)
            .sort()
            .forEach(key => {
              if (!key.startsWith('_')) display.from += value.from[key] + ' '
            })
        }

        if (typeof value.to === 'object') {
          display.to = ''

          Object.keys(value.to)
            .sort()
            .forEach(key => {
              if (!key.startsWith('_')) display.to += value.to[key] + ' '
            })
        }

        return {
          name: field ? field.label : null,
          model: field ? field.model : null,
          from: value.from,
          to: value.to,
          index,
          display,
        }
      })

      this.updateCount = this.updates.length
      this.currentUpdate = this.updates.pop()
    },

    async nextUpdate() {
      this.currentUpdate = this.updates.pop()
      if (this.currentUpdate) this.updatedCount++

      if (this.currentUpdate && !this.currentUpdate.name) this.nextUpdate()
      else if (!this.currentUpdate) {
        await this.$nextTick()

        const documentClasses = document.body.classList
        documentClasses.add('modal-open')

        this.$eventBus.$emit('saveAnalytics')
      }
    },

    saveUpdate() {
      if (this.currentUpdate.index !== null) {
        this.payload[this.currentUpdate.model][
          this.currentUpdate.index
        ] = this.currentUpdate.to
      } else {
        this.payload[this.currentUpdate.model] = this.currentUpdate.to
      }

      this.nextUpdate()
    },
  },
}
</script>
