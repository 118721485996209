<template>
  <div class="card kpis-card">
    <div class="card-header">
      <h6 class="surtitle">
        {{ $t('app_list.contents_unclassified.surtitle') }}
      </h6>
      <h5 class="h3 mb-0">
        {{ $t('app_list.contents_unclassified.title') }}
      </h5>
    </div>
    <div class="card-body">
      <ul
        v-if="campaignContentsResults.length"
        class="list-group list-group-flush list my--3"
      >
        <li
          v-for="(item, index) in campaignContentsResults"
          :key="index"
          class="list-group-item px-0"
        >
          <div class="row align-items-center">
            <div class="col-auto">
              <div v-if="item.medias.length">
                <div class="avatar media" v-if="!item.medias[0].media">
                  <i class="fas fa-spinner fa-spin" />
                </div>
                <app-image
                  v-else-if="!item.medias[0].is_video"
                  :alt="item.campaign_creator.creator.username"
                  :src="item.medias[0].media"
                  default-image="/img/logo-dots.png"
                  class="media"
                />
                <div class="avatar media" v-else>
                  <i class="fas fa-video" />
                </div>
              </div>
              <div class="avatar media" v-else>
                <i class="fas fa-image" />
              </div>
            </div>
            <div class="col">
              <p class="mb-0">@ {{ item.campaign_creator.creator.username }}</p>
              <router-link
                :to="`/app/campaigns/${campaignId}/contents/${item.id}`"
              >
                <p class="text-xs">
                  {{ $t('app_list.contents_unclassified.see_content') }}
                </p>
              </router-link>
            </div>
          </div>
        </li>
      </ul>
      <div
        v-else
        class="d-flex h-100 align-items-center justify-content-center"
      >
        <p class="m-0 text-center text-muted">
          {{ $t('app_list.contents_unclassified.no_data') }}
        </p>
      </div>
    </div>

    <div class="card-footer" v-if="hasPagination">
      <base-pagination
        v-model="page"
        :page-count="campaignContents.pages.last"
        align="center"
      />
    </div>
  </div>
</template>
<script>
import lodashGet from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      page: 1,
    }
  },

  watch: {
    page: {
      immediate: true,
      handler: 'getCampaignContents',
    },
  },

  computed: {
    ...mapGetters({
      campaignContents: 'campaignContents/getCampaignContents',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    hasPagination() {
      return lodashGet(this.campaignContents, 'pages.has_pagination', false)
    },

    campaignContentsResults() {
      return lodashGet(this.campaignContents, 'results', [])
    },
  },

  methods: {
    ...mapActions({
      fetchCampaignContents: 'campaignContents/index',
    }),

    async getCampaignContents() {
      await this.fetchCampaignContents({
        id: this.campaignId,
        params: {
          per_page: 5,
          page: this.page,
          is_classified: false,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.media {
  object-fit: cover;
  height: 64px;
  width: 64px;
  border-radius: 4px;
}
</style>
