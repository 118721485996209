<template>
  <card
    class="comment-distribution-card"
    :class="{ 'no-data': !hasData }"
    body-classes="chart-wrapper"
  >
    <template slot="header">
      <h6 class="surtitle">
        {{ $t('app_dash_charts.comment_distribution.surtitle') }}
      </h6>
      <h5 class="h3 mb-0">
        {{ $t('app_dash_charts.comment_distribution.title') }}
      </h5>
    </template>
    <apexchart
      v-if="hasData"
      type="line"
      height="100%"
      :options="options"
      :series="series"
    ></apexchart>
    <div v-else>
      <p class="m-0 text-center text-muted">
        {{ $t('app_dash_charts.comment_distribution.no_data') }}
      </p>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  async mounted() {
    await this.fetchCommentsDistribution({ id: this.campaignId })
  },

  computed: {
    ...mapGetters({
      commentsDistribution: 'campaignDashboard/getCommentsDistribution',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    data() {
      if (!this.commentsDistribution) {
        return []
      }

      return this.commentsDistribution.results
    },

    hasData() {
      return !!this.data.length
    },

    series() {
      return [
        {
          name: this.$t('app_dash_charts.comment_distribution.series.name'),
          data: this.data.map(i => i.count),
        },
      ]
    },

    options() {
      return {
        labels: this.data.map(i => i.published_at),
        legend: {
          position: 'bottom',
        },
        stroke: {
          curve: 'smooth',
        },
      }
    },
  },

  methods: {
    ...mapActions({
      fetchCommentsDistribution: 'campaignDashboard/commentsDistribution',
    }),
  },
}
</script>

<style>
.comment-distribution-card {
  height: 465px;
}

.chart-wrapper {
  height: 399px;
}

.comment-distribution-card.no-data,
.comment-distribution-card.no-data .chart-wrapper {
  height: unset;
}
</style>
