import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

import { StorageService } from '../services/storage'
import { UserService } from '../services/user'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  try {
    const isPublic = to.matched.some(record => record.meta.public)
    const onlyWhenLoggedOut = to.matched.some(
      record => record.meta.onlyWhenLoggedOut
    )
    const loggedIn = !!StorageService.get('token')

    if (!isPublic && !loggedIn) {
      return next({
        path: '/signin',
        query: { redirect: to.fullPath },
      })
    }

    if (loggedIn && !store.getters['auth/user']) {
      const user = await UserService.getUser()
      store.commit('auth/setUser', {
        id: user.id,
        username: user.username,
      })
    }

    if (loggedIn && onlyWhenLoggedOut) {
      return next('/app')
    }

    next()
  } catch (_) {
    next('/signin')
  }
})

export default router
