import ApiService from '@/services/api'

const state = {
  providerForm: false,
  providerForms: false,
}

const getters = {
  getProviderForm: state => state.providerForm,
  getProviderForms: state => state.providerForms,
}

const actions = {
  async index({ commit }, params) {
    const { data, ok } = await ApiService.index('analytics/forms/', params)

    if (ok) {
      commit('setProviderForms', data)
    }

    return { data, ok }
  },

  async show({ commit }, { provider, type }) {
    const { data, ok } = await ApiService.show(
      `analytics/forms/${provider}`,
      type.toLowerCase()
    )

    if (ok) {
      commit('setProviderForm', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setProviderForm(state, providerForm) {
    state.providerForm = providerForm
  },

  setProviderForms(state, providerForms) {
    state.providerForms = providerForms
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
