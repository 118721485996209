import Vue from 'vue'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import ApexchartsPlugin from '@/plugins/apexcharts'
import ArgonPlugin from '@/plugins/argon'
import GtagPlugin from '@/plugins/gtag'
import i18n from '@/plugins/i18n'
import MomentPlugin from '@/plugins/moment'
import ValidatePlugin from '@/plugins/validate'

import '@/components/global'
import '@/plugins/service'

import 'simplemde/dist/simplemde.min.css'

Vue.use(ApexchartsPlugin)
Vue.use(ArgonPlugin)
Vue.use(GtagPlugin)
Vue.use(MomentPlugin)
Vue.use(ValidatePlugin)

Vue.prototype.$eventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
