var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-wrapper',{attrs:{"title":_vm.$t('app_table.contents.title'),"is-loading":_vm.isLoading,"pagination":_vm.pagination},on:{"change-page":_vm.onChangePage,"refresh":_vm.onRefresh}},[_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.creatorContents.results,"empty-text":_vm.$t('app_table.contents.no_data')}},[_c('el-table-column',{attrs:{"label":_vm.$t('app_table.contents.media_column.label'),"min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[(row.medias.length > 0)?_c('div',[_c('app-image',{staticClass:"media",attrs:{"alt":row.username,"src":row.medias[0].media,"default-image":"/img/logo-dots.png"}})],1):_vm._e()]),_c('div',{staticClass:"col ml--2"},[_c('p',{staticClass:"text-xs text-muted mb-0"},[_vm._v(" "+_vm._s(_vm._f("truncate")(row.data.caption,60))+" ")])])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('app_table.contents.type_column.label'),"min-width":"60px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.type.name)+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"110px","label":_vm.$t('app_table.contents.tags_column.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._l((row.tags),function(tag,index){return [_c('badge',{key:index,staticClass:"mr-2",attrs:{"type":"default"}},[_vm._v(_vm._s(tag.name))])]})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('app_table.contents.created_at_column.label'),"min-width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("moment")(row.created_at,'from', 'now'))+" ")]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('base-button',{attrs:{"type":"secondary","outline":"","size":"sm"},on:{"click":function($event){return _vm.showModal(row.id)}}},[_c('i',{staticClass:"fas fa-pencil-alt"})]),_c('router-link',{attrs:{"to":("/app/campaigns/" + _vm.campaignId + "/contents/" + (row.id))}},[_c('base-button',{attrs:{"type":"secondary","outline":"","size":"sm"}},[_c('i',{staticClass:"far fa-eye"})])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }