<template>
  <card class="sentiment-card">
    <template slot="header">
      <h6 class="surtitle">
        {{ $t('app_dash_charts.sentiment_distribution.surtitle') }}
      </h6>
      <h5 class="h3 mb-0">
        {{ $t('app_dash_charts.sentiment_distribution.title') }}
      </h5>
    </template>
    <apexchart
      v-if="hasData"
      type="donut"
      :options="options"
      :series="series"
    ></apexchart>
    <div v-else class="d-flex h-100 align-items-center justify-content-center">
      <p class="m-0 text-center text-muted">
        {{ $t('app_dash_charts.sentiment_distribution.no_data') }}
      </p>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  async mounted() {
    await this.fetchSentimentDistribution({ id: this.campaignId })
  },

  computed: {
    ...mapGetters({
      sentimentDistribution: 'campaignDashboard/getSentimentDistribution',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    data() {
      if (!this.sentimentDistribution) {
        return []
      }

      return this.sentimentDistribution.results
    },

    hasData() {
      return !!this.data.length
    },

    series() {
      return this.data.map(i => i.count)
    },

    options() {
      return {
        labels: this.data.map(i => this.$t('sentiments.' + i.slug)),
        colors: this.data.map(i => this.getSentimentColor(i.slug)),
        legend: {
          position: 'bottom',
        },
      }
    },
  },

  methods: {
    ...mapActions({
      fetchSentimentDistribution: 'campaignDashboard/sentimentDistribution',
    }),

    getSentimentColor(provider) {
      const colors = {
        positive: '#2dce89',
        neutral: '#adb5bd',
        negative: '#f5365c',
      }

      return colors[provider] || '#000000'
    },
  },
}
</script>

<style scoped>
.sentiment-card {
  height: calc(100% - 30px);
}
</style>
