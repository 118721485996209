import ApiService from './api'
import PublicService from './public'
import { StorageService } from './storage'

const UserService = {
  login: async function(email, password) {
    const requestData = {
      email: email,
      password: password,
    }

    try {
      const { data } = await ApiService.create({
        resource: '/rest-auth/login',
        data: requestData,
      })

      const publicUser = !data.user.is_staff && !data.user.is_superuser

      StorageService.save('token', data.token)
      StorageService.save('role', publicUser ? 'public' : 'staff')
      ApiService.addErrorMonitor()
      ApiService.setHeader()
      PublicService.addErrorMonitor()
      PublicService.setHeader()

      return data
    } catch (error) {
      return error
    }
  },

  logout() {
    StorageService.remove('role')
    StorageService.remove('token')
    ApiService.removeHeader()
    PublicService.removeHeader()
  },

  async getUser() {
    const { data } = await ApiService.show('/rest-auth/user')

    return data
  },

  async checkTasks() {
    const { ok } = await ApiService.index('tasks/users/me')
    return ok
  },
}

export default UserService

export { UserService }
