<template>
  <card class="card-wrapper" :class="{ 'no-data': !hasComments }">
    <template slot="header">
      <h6 class="surtitle">
        {{ $t('app_carousel.highlighted_comments.surtitle') }}
      </h6>
      <h5 class="h3 mb-0">
        {{ $t('app_carousel.highlighted_comments.title') }}
      </h5>
    </template>
    <template v-if="hasComments">
      <el-carousel
        type="card"
        class="h-100"
        :initial-index="initialIndex"
        @change="handleCarouselChange"
      >
        <el-carousel-item
          v-for="comment in campaignComments.results"
          :key="comment.id"
          :name="comment.id"
        >
          <card
            class="comment-card border"
            body-classes="d-flex justify-content-center align-items-center"
          >
            <div>
              <h3 class="text-center">
                {{ comment.data.text | truncate(250) }}
              </h3>
            </div>
            <template slot="footer">
              <div class="row justify-content-end">
                <template v-if="comment.sentiments.length">
                  <div
                    v-for="commentSentiment in comment.sentiments"
                    :key="commentSentiment.id"
                    class="col-auto"
                  >
                    <i
                      :class="getSentimentIcon(commentSentiment.sentiment.slug)"
                    ></i>
                  </div>
                </template>
                <div v-else class="col-auto">
                  <div class="fas fa-question-circle fa-lg"></div>
                </div>
              </div>
            </template>
          </card>
        </el-carousel-item>
      </el-carousel>
    </template>
    <div v-else class="d-flex h-100 align-items-center justify-content-center">
      <p class="m-0 text-center text-muted">
        {{ $t('app_carousel.highlighted_comments.no_data') }}
      </p>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

/**
 * Note that this state is going to be kept even if this component is destroyed,
 * i.e., the same state is going to be used across all instances of this component.
 * Currently, this is not an issue because el-carousel handles invalid items smoothly
 * and we just use one of this component at a time.
 */
const localState = {
  currentIndex: 0,
}

export default {
  async mounted() {
    this.initialIndex = localState.currentIndex

    await this.fetchCampaignComments({
      id: this.id,
      params: { is_highlight: true },
    })
  },

  data() {
    return {
      initialIndex: 0,
    }
  },

  computed: {
    ...mapGetters({
      campaignComments: 'campaignComments/getCampaignComments',
    }),

    hasComments() {
      return !!(this.campaignComments && this.campaignComments.results.length)
    },

    id() {
      return this.$route.params.campaignId
    },
  },

  methods: {
    ...mapActions({
      fetchCampaignComments: 'campaignComments/index',
    }),

    getSentimentIcon(sentiment) {
      const icons = {
        positive: 'fas fa-smile fa-lg text-success',
        negative: 'fas fa-frown fa-lg text-danger',
        neutral: 'fas fa-meh fa-lg',
      }

      return icons[sentiment] || ''
    },

    handleCarouselChange(current) {
      localState.currentIndex = current
    },
  },
}
</script>

<style scoped>
.card-wrapper {
  height: calc(100% - 30px);
  min-height: 585px;
}

.card-wrapper.no-data {
  min-height: unset;
}

.comment-card {
  max-width: 350px;
  height: calc(100% - 10px);
}
</style>

<style>
.card-wrapper .el-carousel__container {
  height: calc(100% - 32px);
}

.el-carousel__item.is-active .card {
  box-shadow: none !important;
}
</style>
