<template>
  <div>
    <div class="modal-body py-0">
      <validation-observer v-slot="{ validate, invalid }">
        <form @submit.prevent="validate().then(submit)">
          <div class="row">
            <div class="col">
              <h2 class="mb-0">
                Atividade
              </h2>
              <p class="text-muted text-sm">
                Edite sua atividade
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input
                :label="'Título'"
                :name="'name'"
                type="text"
                :rules="{ required: true, min: 1 }"
                v-model="payload.name"
                :placeholder="'Título da sua atividade'"
              />
              <app-base-markdown-input
                :label="'Descrição'"
                name="description"
                v-model="payload.description"
                :options="{ autofocus: false }"
              ></app-base-markdown-input>
            </div>
            <div class="col">
              <base-input
                :label="'Responsável'"
                :name="'assignee'"
                type="text"
                :rules="{ required: true }"
              >
                <el-select
                  :placeholder="'Pessoa que realizará a tarefa'"
                  v-model="payload.assignee"
                  clearable
                  filterable
                  remote
                  :remote-method="searchUser"
                >
                  <el-option
                    v-for="user in users.results"
                    :value="user.username"
                    :label="user.username"
                    :key="user.id"
                  />
                </el-select>
              </base-input>
              <template v-if="iterable">
                <div class="row">
                  <div class="col">
                    <label class="form-control-label">Recorrências</label>
                  </div>
                  <div class="col-auto">
                    <base-button
                      icon
                      size="sm"
                      type="outline-primary"
                      class="icon-only"
                      @click="resetRecurrences"
                    >
                      <i class="fas fa-undo"></i>
                    </base-button>
                  </div>
                </div>
                <div class="row justify-content-center align-items-baseline">
                  <small class="col-auto">
                    Repetir a cada
                  </small>
                  <base-input
                    type="number"
                    v-model="iterations.interval"
                    class="col"
                    :disabled="!hasIterations"
                  ></base-input>
                  <base-input class="col">
                    <el-select
                      v-model="iterations.frequency"
                      @change="frequencyChanged"
                      :disabled="!hasIterations"
                    >
                      <el-option
                        v-for="freq in rrule.freq"
                        :key="freq.value"
                        :value="freq.value"
                        :label="freq.label"
                      />
                    </el-select>
                  </base-input>
                </div>
              </template>
              <div class="row">
                <div v-if="iterable" class="col-6">
                  <base-input
                    :label="'Data inicial'"
                    :name="'repeat'"
                    type="datetime-local"
                    v-model="iterations.start"
                    :disabled="!hasIterations"
                  >
                  </base-input>
                </div>
                <div class="col-6">
                  <base-input
                    :label="'Data prevista'"
                    :name="'dueOnDate'"
                    type="datetime-local"
                    v-model="dueOn.date"
                    @input="parseDue"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <base-input :label="'Dia todo?'" :name="'allDay'">
                    <base-switch
                      :key="allDayUpdateCount"
                      ref="allDaySwitch"
                      v-model="dueOn.allDay"
                      :on-text="'Sim'"
                      :off-text="'Não'"
                      @input="allDayChanged"
                    />
                  </base-input>
                </div>
                <div v-if="iterable" class="col-auto">
                  <base-input
                    :label="'Tem recorrência?'"
                    :name="'hasIterations'"
                  >
                    <base-switch
                      v-model="hasIterations"
                      :on-text="'Sim'"
                      :off-text="'Não'"
                    />
                  </base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-right">
              <base-button type="primary" outline @click="$emit('close')">
                {{ $t('app_modal.cancel_button') }}
              </base-button>
              <base-button
                native-type="submit"
                type="primary"
                :disabled="invalid"
              >
                {{
                  isUpdate
                    ? $t('app_modal.update_button')
                    : $t('app_modal.add_button')
                }}
              </base-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import RRule from 'rrule'
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex'

import { localToIso, isoToLocal } from '@/helpers/date'
import ApiService from '../../../../services/api'

export default {
  name: 'modal-tasks',

  props: {
    options: [Object, Boolean],
  },

  data() {
    return {
      allDayUpdateCount: 0,
      rrule: {
        freq: [
          {
            label: 'minuto',
            value: RRule.MINUTELY,
            hasTime: true,
          },
          {
            label: 'hora',
            value: RRule.HOURLY,
            hasTime: true,
          },
          {
            label: 'dia',
            value: RRule.DAILY,
          },
          {
            label: 'semana',
            value: RRule.WEEKLY,
          },
          {
            label: 'mês',
            value: RRule.MONTHLY,
          },
          {
            label: 'ano',
            value: RRule.YEARLY,
          },
        ],
        wkst: RRule.SU,
      },
      iterations: {
        frequency: RRule.DAILY,
        start: isoToLocal(new Date().toISOString()),
        until: null,
        interval: 1,
      },
      dueOn: {
        date: null,
        time: null,
        allDay: true,
      },
      payload: {
        name: null,
        description: '',
        assignee: '',
        dueOn: null,
      },
      hasIterations: true,
    }
  },

  created() {
    this.payload.assignee = this.user.username
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'users/getUsers',
    }),

    isUpdate() {
      return (this.options || {}).method === 'update'
    },

    iterable() {
      const { parent, iterable, subtasks } = this.payload

      return (
        !parent &&
        !(subtasks || []).length &&
        iterable !== false &&
        !(this.options.extras && this.options.extras.parent)
      )
    },
  },

  methods: {
    ...mapActions({
      fetchUsers: 'users/index',
    }),

    submit() {
      return this.isUpdate ? this.updateTask() : this.createTask()
    },

    async searchUser(search) {
      await this.fetchUsers({
        search,
      })
    },

    localToIso(date) {
      return localToIso(date)
    },

    parseDue(value) {
      const isoDate = localToIso(value)
      this.payload.dueOn = this.dueOn.allDay ? isoDate.split('T')[0] : isoDate
    },

    isSameRule(r1, r2) {
      const keys = ['dtstart', 'freq', 'interval', 'wkst', 'until']

      const r2Keys = Object.keys(r2.options)
      for (const option in r1.options) {
        if (!keys.includes(option)) continue

        if (!r2Keys.includes(option)) return false

        const [r1Value, r2Value] = [r1.options[option], r2.options[option]]

        if (
          r1Value instanceof Date &&
          r2Value instanceof Date &&
          r1Value.getTime() === r2Value.getTime()
        ) {
          continue
        }

        if (r1Value !== r2Value) return false
      }

      return true
    },

    async updateTask() {
      const chronosTask = this.getTask()

      const isSameRule = chronosTask.rrule
        ? !this.hasChangedRule(RRule.fromString(chronosTask.rrule))
        : false

      const { ok } = await ApiService.update({
        resource: `/tasks/chronograms/${chronosTask.chronogram}/tasks/${chronosTask._id}`,
        data: JSON.stringify(chronosTask, (key, value) => {
          if (['iterations'].includes(key)) return undefined
          return value
        }),
      })

      let shouldClose = true

      if (ok) {
        let iterationUpdates = []

        if (
          (!isSameRule && this.hasIterations) ||
          (this.hasIterations && !this.options.data.iterations)
        ) {
          const { isConfirmed } = await Swal.fire({
            title: 'Atenção',
            text:
              'Houve uma mudança nas recorrências desta tarefa e o progresso será perdido ao alterá-las.',
            icon: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Continuar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
          })

          if (isConfirmed) {
            iterationUpdates = [
              ...(this.options.data.iterations || []).map(i => {
                return ApiService.delete(
                  `/tasks/chronograms/${chronosTask.chronogram}/tasks/${chronosTask._id}/iterations`,
                  i._id
                )
              }),
              ApiService.create({
                resource: `/tasks/chronograms/${chronosTask.chronogram}/tasks/${chronosTask._id}/iterations`,
                data: chronosTask.iterations || [],
              }),
            ]
          } else {
            shouldClose = false
          }
        }

        if (!this.hasIterations) {
          iterationUpdates = [
            ApiService.delete(
              `/tasks/chronograms/${chronosTask.chronogram}/tasks/${chronosTask._id}/iterations/`,
              ''
            ),
          ]
        }

        await Promise.all(iterationUpdates)
      }

      this.options.info.view.calendar.refetchEvents()
      if (shouldClose) this.$eventBus.$emit('hideModal')
    },

    async createTask() {
      const chronosTask = this.getTask()

      const { ok } = await ApiService.create({
        resource: `/tasks/chronograms/${chronosTask.chronogram}/tasks`,
        data: chronosTask,
      })

      if (ok) this.options.info.view.calendar.refetchEvents()

      this.$eventBus.$emit('hideModal')
    },

    getUpdatedIterations() {
      if (!this.hasIterations) {
        return {
          iterations: null,
          rrule: null,
        }
      }

      const allDay = this.dueOn.allDay
      const startStr = localToIso(this.iterations.start)
      const endStr = localToIso(this.dueOn.date)

      const rrule = new RRule(
        {
          freq: this.iterations.frequency,
          interval: Number(this.iterations.interval),
          wkst: this.rrule.wkst,
          dtstart: new Date(startStr),
          until: new Date(endStr),
        },
        false
      )

      const isSameRule = !this.hasChangedRule(rrule)

      const iterations = this.isUpdate ? this.options.data.iterations : null
      const dates = rrule.all()
      if (!isSameRule && dates.length > 1) {
        return {
          iterations: dates.map(date => {
            const isoDate = date.toISOString()

            const iteration = iterations
              ? iterations.find(i => {
                  return (
                    i.dueOn === isoDate || i.dueOn === isoDate.split('T')[0]
                  )
                })
              : null

            let completedOn = null
            if (iteration) {
              completedOn = iteration.completedOn
            }

            return {
              _id: iteration ? iteration._id : undefined,
              dueOn: allDay ? isoDate.split('T')[0] : isoDate,
              completedOn,
            }
          }),
          rrule,
        }
      } else {
        return {
          iterations,
          rrule,
        }
      }
    },

    hasChangedRule(rrule) {
      if (this.isUpdate && !this.options.data.rrule) return true

      return this.isUpdate
        ? !this.isSameRule(RRule.fromString(this.options.data.rrule), rrule)
        : true
    },

    getTask() {
      const { iterations, rrule } = this.getUpdatedIterations()
      const dueOn = this.getDueOn(iterations)

      const rruleStr = rrule
        ? !this.dueOn.allDay
          ? rrule.toString()
          : rrule
              .toString()
              .replace(/DTSTART:(\d+)T(\d+Z)/, 'DTSTART:$1')
              .replace(/UNTIL=(\d+)T(\d+Z)/, 'UNTIL=$1')
        : null

      const task = {
        chronogram: this.$route.params.campaignId,
        name: this.payload.name,
        description: (this.payload.description || '').trim() || null,
        iterations,
        rrule: rruleStr,
        assignee: this.payload.assignee,
        dueOn: this.dueOn.allDay
          ? dueOn.toISOString().split('T')[0]
          : dueOn.toISOString(),
      }

      if (this.options.extras && this.options.extras.parent)
        task.parent = this.options.extras.parent

      if (this.isUpdate) task._id = this.options.data._id

      return task
    },

    getDueOn(iterations) {
      if (!this.hasIterations) {
        return new Date(localToIso(this.dueOn.date))
      }

      if (iterations) return new Date(iterations[iterations.length - 1].dueOn)
      return new Date(localToIso(this.iterations.start))
    },

    frequencyChanged(value) {
      this.dueOn.allDay = this.isAllDay(value)
    },

    allDayChanged(value) {
      if (value) {
        const allDay = this.isAllDay(this.iterations.frequency)
        if (allDay !== this.dueOn.allDay) {
          this.dueOn.allDay = allDay
          this.allDayUpdateCount++
        }
      }

      this.parseDue(this.dueOn.date)
    },

    isAllDay(frequency) {
      const freq = this.rrule.freq.find(freq => freq.value === frequency)
      if (freq.hasTime) return false

      return this.dueOn.allDay
    },

    initCreate() {
      const eventData = this.options.info
      const hasData = !!eventData

      if (hasData && !eventData.allDay) {
        this.iterations = {
          frequency: RRule.MINUTELY,
          start: isoToLocal(new Date().toISOString()),
          until: null,
          interval: 30,
        }
      }

      const eventStart = hasData ? eventData.start || eventData.date : null
      if (eventStart) {
        this.iterations.start = isoToLocal(eventStart.toISOString())
      }

      const endDates = this.parseEndDate(eventData)
      if (!endDates) {
        this.hasIterations = false
        return
      }

      if (eventStart) {
        this.hasIterations =
          new Date(eventStart).getTime() !== new Date(endDates.end).getTime()
      } else if (endDates) {
        this.hasIterations = false
      }

      this.dueOn = {
        date: endDates.endStr,
        allDay: endDates.allDay,
      }

      this.parseDue(endDates.endStr)
    },

    initUpdate() {
      const eventData = this.options.data

      this.payload = {
        ...eventData,
        description: eventData.description || '',
      }

      const { dueOn } = eventData
      const allDay = !dueOn.includes('T')

      this.dueOn = {
        date: isoToLocal(this.strToDate(dueOn).toISOString()),
        allDay,
      }

      const { iterations, rrule: rruleStr } = eventData

      this.hasIterations = !!(iterations && iterations.length && rruleStr)

      if (rruleStr) {
        const rrule = RRule.fromString(rruleStr)
        const rruleOptions = rrule.options

        if (allDay) {
          rruleOptions.dtstart.setTime(
            rruleOptions.dtstart.getTime() +
              rruleOptions.dtstart.getTimezoneOffset() * 60000
          )

          if (rruleOptions.until) {
            rruleOptions.until.setTime(
              rruleOptions.until.getTime() +
                rruleOptions.until.getTimezoneOffset() * 60000
            )
          }
        }

        this.iterations = {
          frequency: rruleOptions.freq,
          start: isoToLocal(rruleOptions.dtstart.toISOString()),
          until: rruleOptions.until
            ? isoToLocal(rruleOptions.until.toISOString())
            : null,
          interval: rruleOptions.interval,
        }
      } else {
        this.iterations.start = isoToLocal(
          (iterations
            ? this.strToDate(iterations[0].dueOn)
            : this.strToDate(dueOn)
          ).toISOString()
        )
      }
    },

    parseEndDate(eventData) {
      if (!eventData.end) return null

      const { end, allDay } = eventData

      const date = new Date(end)
      if (allDay) {
        date.setDate(date.getDate() - 1)
        // date.setTime(date.getTime() - 1000)
      }
      const dateStr = isoToLocal(date.toISOString())

      return {
        end: date,
        endStr: dateStr,
        allDay,
      }
    },

    strToDate(str) {
      const allDay = !str.includes('T')
      return new Date(allDay ? `${str}T03:00:00Z` : str)
    },

    resetRecurrences() {
      if (this.isUpdate) {
        const eventData = this.options.data
        const { iterations, dueOn, rrule: rruleStr } = eventData

        if (iterations && rruleStr) {
          const rrule = RRule.fromString(rruleStr)
          const rruleOptions = rrule.options

          this.iterations = {
            frequency: rruleOptions.freq,
            start: isoToLocal(rruleOptions.dtstart.toISOString()),
            until: rruleOptions.until
              ? isoToLocal(rruleOptions.until.toISOString())
              : null,
            interval: rruleOptions.interval,
          }
        } else {
          this.iterations.start = isoToLocal(
            (iterations
              ? this.strToDate(iterations[0].dueOn)
              : this.strToDate(dueOn)
            ).toISOString()
          )
        }
      } else if (this.options.info.allDay) {
        this.iterations = {
          frequency: RRule.DAILY,
          start: isoToLocal(new Date().toISOString()),
          until: null,
          interval: 1,
        }
      } else {
        this.iterations = {
          frequency: RRule.MINUTELY,
          start: isoToLocal(new Date().toISOString()),
          until: null,
          interval: 30,
        }
      }
    },
  },

  mounted() {
    if (this.isUpdate) this.initUpdate()
    else this.initCreate()
  },
}
</script>
