<template>
  <table-wrapper
    :title="$t('app_table.campaigns.title')"
    :is-loading="isLoading"
    :pagination="pagination"
    @change-page="changePage"
    @refresh="refresh"
  >
    <div slot="sub-header" class="mt-4">
      <div class="row">
        <div class="col">
          <form @submit.prevent="fetchCampaigns({ page: 1 })">
            <base-input
              v-model="filter.search"
              :placeholder="$t('app_table.campaigns.search_placeholder')"
            >
            </base-input>
          </form>
        </div>

        <div class="col-auto">
          <base-button type="primary" @click="toggleFilters" outline>
            <i class="fas fa-filter" />
          </base-button>
        </div>
      </div>

      <div class="row" v-if="showFilters">
        <div class="col-12">
          <h4 class="my-3">
            <i class="fas fa-filter mr-2 text-xs" />
            {{ $t('app_table.campaigns.filters.title') }}
          </h4>
        </div>
        <div class="col-3">
          <base-input
            :label="$t('app_table.campaigns.filters.user.placeholder')"
          >
            <el-select
              :placeholder="$t('app_table.campaigns.filters.user.placeholder')"
              v-model="filter.user__id"
              default-first-option
              clearable
              filterable
              remote
              :remote-method="searchUsers"
              @input="refetchCampaigns"
            >
              <el-option
                v-for="user in users.results"
                :value="user.id"
                :label="user.full_name"
                :key="user.id"
              />
            </el-select>
          </base-input>
        </div>
      </div>
    </div>

    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :data="campaigns.results"
      :empty-text="$t('app_table.campaigns.no_data')"
    >
      <el-table-column
        :label="$t('app_table.campaigns.column_campaign')"
        prop="name"
        sortable
      >
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body">
              <router-link
                v-if="row.name"
                :to="`/app/campaigns/${row.id}`"
                class="font-weight-600 name mb-0"
                >{{ row.name }}</router-link
              >
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.campaigns.column_brand')"
        prop="brand.name"
        sortable
      >
        <template v-slot="{ row }">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="avatar avatar-sm rounded-circle">
                <app-image :alt="row.brand.name" :src="row.brand.picture_url" />
              </div>
            </div>
            <div class="col ml--3">
              <h5 class="mb-0">
                <a href="#!">{{ row.brand.name }}</a>
              </h5>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('app_table.campaigns.column_provider')">
        <template v-slot="{ row }">
          {{ row.provider ? provider.name : 'Report' }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('app_table.campaigns.column_user')">
        <template v-slot="{ row: { user } }">
          {{ user.first_name }} {{ user.last_name }}
        </template>
      </el-table-column>

      <el-table-column>
        <template v-slot="{ row }">
          <base-button
            @click="showModal(row.id)"
            type="secondary"
            size="sm"
            outline
            ><i class="fas fa-pencil-alt"
          /></base-button>
          <base-button
            type="secondary"
            size="sm"
            outline
            @click="$router.push(`/app/campaigns/${row.id}`)"
            ><i class="ni ni-curved-next"
          /></base-button>
        </template>
      </el-table-column>
    </el-table>
  </table-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { Table, TableColumn } from 'element-ui'

import debounce from 'lodash/debounce'

import TableWrapper from './TableWrapper'

export default {
  name: 'campaign-index-table',

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TableWrapper,
  },

  data() {
    return {
      isLoading: false,
      showFilters: false,
      filter: {
        search: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      campaigns: 'campaigns/getCampaigns',
      users: 'users/getUsers',
    }),

    pagination() {
      if (!this.campaigns) {
        return { current: 1, perPage: 10, total: 0 }
      }

      const { pages, count } = this.campaigns

      return {
        current: pages.current,
        perPage: pages.per_page,
        total: count,
      }
    },
  },

  created() {
    this.$eventBus.$on('refetchCampaigns', async () => {
      await this.fetchCampaigns({ page: 1 })
    })
  },

  async mounted() {
    await Promise.all([this.fetchCampaigns({ page: 1 }), this.fetchUsers()])
  },

  methods: {
    ...mapActions({
      campaignIndex: 'campaigns/index',
      userIndex: 'users/index',
    }),

    showModal(id) {
      this.$eventBus.$emit('showModal', 'campaigns', { method: 'update', id })
    },

    toggleFilters() {
      this.showFilters = !this.showFilters
    },

    refresh() {
      return this.fetchCampaigns({
        page: this.pagination.current,
      })
    },

    changePage(page) {
      if (page === this.pagination.current) {
        return
      }

      return this.fetchCampaigns({ page })
    },

    refetchCampaigns: debounce(function() {
      return this.fetchCampaigns({
        page: 1,
      })
    }, 500),

    async fetchCampaigns(params) {
      this.isLoading = true

      // eslint-disable-next-line camelcase
      const { search, user__id = '' } = this.filter

      await this.campaignIndex({
        params: {
          ...params,
          search,
          user__id,
          per_page: params.per_page ? params.per_page : this.pagination.perPage,
        },
      })
      this.isLoading = false
    },

    async fetchUsers({ search, ordering } = {}) {
      await this.userIndex({
        search,
        ordering,
        page: 1,
        per_page: 5,
      })
    },

    searchUsers: debounce(function(search) {
      return this.fetchUsers({ search })
    }, 500),
  },
}
</script>
