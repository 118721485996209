<template>
  <table-wrapper
    :title="$t('app_table.contents.title')"
    :is-loading="isLoading"
    :pagination="pagination"
    @change-page="onChangePage"
  >
    <div slot="toolbar">
      <el-tooltip
        :content="$t('app_table.contents.tooltips.export_comments')"
        placement="top"
      >
        <base-button size="sm" type="success" icon @click="exportCommentsCSV">
          <span class="fas fa-file-csv" />
          <span class="btn-inner--text">
            {{ $t('app_table.contents.export_comments') }}
          </span>
        </base-button>
      </el-tooltip>

      <el-tooltip
        :content="$t('app_table.contents.tooltips.export_contents')"
        placement="top"
      >
        <base-button size="sm" type="success" icon @click="exportCSV">
          <span class="fas fa-file-csv" />
          <span class="btn-inner--text">
            {{ $t('app_table.contents.export_contents') }}
          </span>
        </base-button>
      </el-tooltip>

      <el-tooltip
        :content="$t('app_table.contents.tooltips.refresh')"
        placement="top"
      >
        <base-button size="sm" type="secondary" icon @click="onRefresh">
          <span class="fas fa-sync-alt" />
          <span class="btn-inner--text">
            {{ $t('app_table.contents.refresh') }}
          </span>
        </base-button>
      </el-tooltip>
    </div>

    <div slot="sub-header" class="mt-4">
      <div class="row">
        <div class="col">
          <base-input
            group
            input-group-classes="input-group-md"
            form-group-classes="mb-0"
          >
            <input
              v-model="filter.search"
              type="text"
              class="form-control"
              :placeholder="$t('app_table.contents.search_placeholder')"
              :aria-label="$t('app_table.contents.search_placeholder')"
              @keypress.enter="indexContent(1)"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="indexContent(1)"
              >
                {{ $t('app_table.contents.search_button') }}
              </button>
            </div>
          </base-input>
        </div>

        <div class="col-auto">
          <base-button type="primary" @click="toggleFilters" outline>
            <i class="fas fa-filter" />
          </base-button>
        </div>
      </div>
      <div class="row" v-if="showFilters">
        <div class="col-12">
          <h4 class="my-3">
            <i class="fas fa-filter mr-2 text-xs" />
            {{ $t('app_table.contents.filters') }}
          </h4>
        </div>
        <div class="col-3">
          <base-input :label="$t('app_table.contents.tags_select.placeholder')">
            <el-select
              :placeholder="$t('app_table.contents.tags_select.placeholder')"
              v-model="filter.tags"
              multiple
              clearable
              group
              @input="refetchContents"
            >
              <el-option
                v-for="tag in tags.results"
                :value="tag.id"
                :label="tag.name"
                :key="tag.id"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-3" v-if="providers">
          <base-input
            :label="$t('app_table.contents.providers_select.placeholder')"
          >
            <el-select
              :placeholder="
                $t('app_table.contents.providers_select.placeholder')
              "
              v-model="filter.providers"
              multiple
              clearable
              :loading="isLoading"
              @input="refetchContents"
            >
              <el-option
                v-for="provider in providers.results"
                :value="provider.id"
                :label="provider.name"
                :key="provider.id"
              />
            </el-select>
          </base-input>
        </div>

        <div class="col-3" v-if="types">
          <base-input
            :label="$t('app_table.contents.types_select.placeholder')"
          >
            <el-select
              :placeholder="$t('app_table.contents.types_select.placeholder')"
              v-model="filter.types"
              multiple
              clearable
              @input="refetchContents"
            >
              <el-option
                v-for="type in types.results"
                class="select-danger"
                :value="type.id"
                :label="type.name"
                :key="type.id"
              />
            </el-select>
          </base-input>
        </div>

        <div class="col-3" v-if="campaignCreators">
          <base-input
            :label="$t('app_table.contents.creators_select.placeholder')"
          >
            <el-select
              :placeholder="
                $t('app_table.contents.creators_select.placeholder')
              "
              v-model="filter.creator"
              clearable
              filterable
              remote
              :remote-method="searchCampaignCreator"
              @input="refetchContents"
            >
              <el-option
                v-for="creator in campaignCreators.results"
                :value="creator.id"
                :label="creator.creator.username"
                :key="creator.id"
              />
            </el-select>
          </base-input>
        </div>

        <div class="col-4">
          <div class="row">
            <div class="col">
              <base-input
                :placeholder="
                  $t('app_table.contents.min_price_input.placeholder')
                "
                :label="$t('app_table.contents.min_price_input.placeholder')"
                type="number"
                min="0"
                v-model="filter.min_price"
                @input="refetchContents"
              ></base-input>
            </div>
            <div class="col">
              <base-input
                :placeholder="
                  $t('app_table.contents.max_price_input.placeholder')
                "
                :label="$t('app_table.contents.max_price_input.placeholder')"
                type="number"
                min="0"
                v-model="filter.max_price"
                @input="refetchContents"
              ></base-input>
            </div>
          </div>
        </div>

        <div class="col-2">
          <base-input
            :label="$t('app_table.contents.status_select.placeholder')"
          >
            <el-select
              :placeholder="$t('app_table.contents.status_select.placeholder')"
              v-model="filter.is_classified"
              clearable
              @input="refetchContents"
            >
              <el-option
                :value="true"
                :label="
                  $t('app_table.contents.is_classified_select.classified')
                "
              />
              <el-option
                :value="false"
                :label="
                  $t('app_table.contents.is_classified_select.not_classified')
                "
              />
              <el-option
                :value="null"
                :label="$t('app_table.contents.is_classified_select.all')"
              />
            </el-select>
          </base-input>
        </div>

        <div class="col-3">
          <base-input
            :label="$t('app_table.contents.published_after_input.placeholder')"
            :placeholder="
              $t('app_table.contents.published_after_input.placeholder')
            "
            type="date"
            v-model="filter.published_after"
            @input="refetchContents"
          />
        </div>
        <div class="col">
          <base-input
            :label="$t('app_table.contents.published_before_input.placeholder')"
            :placeholder="
              $t('app_table.contents.published_before_input.placeholder')
            "
            type="date"
            v-model="filter.published_before"
            @input="refetchContents"
          />
        </div>
      </div>
    </div>

    <el-table
      ref="tableRef"
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :data="contents.results"
      @sort-change="handleSortChange"
      :empty-text="$t('app_table.contents.no_data')"
    >
      <el-table-column
        :label="$t('app_table.contents.media_column.label')"
        prop="media"
      >
        <template v-slot="{ row }">
          <div class="row align-items-center">
            <div class="col-auto">
              <div v-if="row.medias.length">
                <div class="avatar media" v-if="!row.medias[0].media">
                  <i class="fas fa-spinner fa-spin" />
                </div>
                <app-image
                  v-else-if="!row.medias[0].is_video"
                  :alt="row.username"
                  :src="row.medias[0].media"
                  default-image="/img/logo-dots.png"
                  class="media"
                />
                <div class="avatar media" v-else>
                  <i class="fas fa-video" />
                </div>
              </div>
              <div class="avatar media" v-else>
                <i class="fas fa-image" />
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.contents.creator_column.label')"
        min-width="120"
        prop="campaign_creator__creator__username"
        sortable="custom"
      >
        <template v-slot="{ row: { campaign_creator: { creator } } }">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="avatar avatar-sm rounded-circle">
                <app-image :alt="creator.username" :src="creator.picture" />
              </div>
            </div>
            <div class="col ml--2">
              <h5 class="mb-0">
                <a href="#!">{{ creator.name }}</a>
              </h5>
              <p class="text-xs text-muted mb-0">@{{ creator.username }}</p>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.contents.provider_column.label')"
        min-width="82"
        prop="campaign_creator__creator__provider__name"
        sortable="custom"
      >
        <template v-slot="{ row }">
          <i :class="`fab fa-${row.provider.slug} mr-2`" />
          {{ row.provider.name }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.contents.type_column.label')"
        min-width="60"
        prop="type__name"
        sortable="custom"
      >
        <template v-slot="{ row }">
          {{ row.type.name }}
        </template>
      </el-table-column>

      <el-table-column
        min-width="120"
        :label="$t('app_table.contents.tags_column.label')"
        prop="tags__name"
        sortable="custom"
      >
        <template v-slot="{ row }">
          <template v-for="(tag, index) in row.tags">
            <badge :key="index" type="default" class="mr-2">{{
              tag.name
            }}</badge>
          </template>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.contents.price_column.label')"
        prop="price"
        sortable="custom"
      >
        <template v-slot="{ row }">
          <span v-if="row.price !== null">
            {{
              row.price
                | currency('R$', 2, {
                  thousandsSeparator: '.',
                  decimalSeparator: ',',
                })
            }}
          </span>
          <span v-else>n/d</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.contents.published_at_column.label')"
      >
        <template v-slot="{ row }">
          {{ row.data.published_at | moment('from', 'now') }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.contents.created_at_column.label')"
        prop="created_at"
        sortable="custom"
      >
        <template v-slot="{ row }">
          {{ row.created_at | moment('from', 'now') }}
        </template>
      </el-table-column>

      <el-table-column min-width="100">
        <template v-slot="{ row }">
          <base-button
            type="secondary"
            outline
            size="sm"
            @click="showModal('analytics', row.id)"
          >
            <i class="far fa-chart-bar" />
          </base-button>
          <base-button
            type="secondary"
            outline
            size="sm"
            @click="showModal('contents', row.id)"
          >
            <i class="fas fa-pencil-alt" />
          </base-button>
          <router-link
            :to="`/app/campaigns/${campaignId}/contents/${row.id}`"
            class="table-action-btn"
          >
            <base-button type="secondary" outline size="sm">
              <i class="far fa-eye" />
            </base-button>
          </router-link>
          <el-dropdown trigger="click">
            <span>
              <base-button
                class="table-action-btn"
                type="secondary"
                outline
                native-type="button"
                size="sm"
              >
                <i class="fas fa-ellipsis-v"></i>
              </base-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="exportAnalyticsCSV(row.id)">
                {{ $t('app_table.contents.export_button') }} (.csv)
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </table-wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui'

import { downloadFileFromResponse } from '@/helpers/download'

import TableWrapper from './TableWrapper'

export default {
  name: 'contents-index-table',

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    TableWrapper,
  },

  data() {
    return {
      isLoading: false,
      showFilters: false,
      currentOrdering: ['-created_at', '-updated_at'],
      filter: {
        search: '',
        tags: [],
        providers: [],
        creator: '',
        types: [],
        is_classified: null,
        min_price: null,
        max_price: null,
        published_after: null,
        published_before: null,
      },
    }
  },

  created() {
    this.$eventBus.$on('refetchContents', async () => {
      this.currentOrdering = ['-created_at', '-updated_at']
      this.$refs.tableRef && this.$refs.tableRef.clearSort()
      await this.indexContent(this.pagination.current || 1)
    })
  },

  async mounted() {
    await this.init()
  },

  computed: {
    ...mapGetters({
      campaign: 'campaigns/getCampaign',
      campaignCreators: 'campaignCreators/getCampaignCreators',
      contents: 'contents/getContents',
      products: 'products/getProducts',
      providers: 'providers/getProviders',
      tags: 'tags/getTags',
      types: 'types/getTypes',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    brandId() {
      return this.campaign ? this.campaign.brand.id : null
    },

    pagination() {
      if (!this.contents) {
        return { current: 1 }
      }

      const { pages, count } = this.contents

      return {
        current: pages.current,
        perPage: pages.per_page,
        total: count,
      }
    },
  },

  methods: {
    ...mapActions({
      contentIndex: 'contents/index',
      providerIndex: 'providers/index',
      campaignCreatorsIndex: 'campaignCreators/index',
      campaignCreatorsSearch: 'campaignCreators/searchMany',
      campaignContentsExportCSV: 'campaignContents/exportCSV',
      campaignCommentsExportCSV: 'campaignComments/exportCSV',
      contentAnalyticsExportCSV: 'contentAnalytics/exportCSV',
      tagIndex: 'tags/index',
      typeIndex: 'types/index',
    }),

    async init() {
      await this.indexContent(1)

      const requests = []

      if (this.brandId) {
        requests.push(this.tagIndex({ brand__id: this.brandId }))
      }

      requests.push(this.providerIndex())
      requests.push(this.typeIndex({ per_page: 50 }))
      requests.push(this.campaignCreatorsIndex({ id: this.campaignId }))

      await Promise.all(requests)
    },

    showModal(type, id) {
      this.$eventBus.$emit('showModal', type, { method: 'update', id: id })
    },

    toggleFilters() {
      this.showFilters = !this.showFilters
    },

    async indexContent(page, { ordering } = {}) {
      if (!ordering) {
        ordering = this.currentOrdering.join(',')
      }

      const filterNames = {
        tags: 'tags__id',
        creator: 'campaign_creator__id',
      }

      const filters = {}
      Object.entries(this.filter).forEach(([key, value]) => {
        filters[filterNames[key] || key] = value
      })

      this.isLoading = true
      await this.contentIndex({
        id: this.campaignId,
        params: {
          page,
          ordering,
          ...filters,
        },
      })
      this.isLoading = false
    },

    onChangePage(page) {
      if (page === this.pagination.current) {
        return
      }

      return this.indexContent(page)
    },

    async onRefresh() {
      await this.indexContent(this.pagination.current)
    },

    handleSortChange({ column, prop, order }) {
      if (!prop) {
        this.currentOrdering = this.currentOrdering.slice(1)
      } else {
        const orderPrefix = order === 'descending' ? '-' : ''
        const newOrder = `${orderPrefix}${prop}`
        this.currentOrdering = [
          newOrder,
          ...this.currentOrdering.filter(field => !field.includes(prop)),
        ]
      }

      return this.onRefresh()
    },

    refetchContents: debounce(function() {
      return this.indexContent(1)
    }, 500),

    async searchCampaignCreator(data) {
      console.log(data)
      await this.campaignCreatorsSearch({
        id: this.campaignId,
        search: { search: data },
      })
    },

    async exportCSV() {
      try {
        const response = await this.campaignContentsExportCSV({
          id: this.campaignId,
        })

        downloadFileFromResponse(response)
      } catch (error) {
        this.$notify({
          type: 'danger',
          message: 'Erro ao exportar os conteúdos',
        })
      }
    },

    async exportCommentsCSV() {
      try {
        const response = await this.campaignCommentsExportCSV({
          id: this.campaignId,
        })

        downloadFileFromResponse(response)
      } catch (error) {
        this.$notify({
          type: 'danger',
          message: 'Erro ao exportar os comentários',
        })
      }
    },

    async exportAnalyticsCSV(contentId) {
      try {
        const response = await this.contentAnalyticsExportCSV({
          id: contentId,
        })

        downloadFileFromResponse(response)
      } catch (error) {
        this.$notify({
          type: 'danger',
          message: 'Erro ao exportar analytics',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.media {
  object-fit: cover;
  height: 64px;
  width: 64px;
  border-radius: 4px;
}

.table-action-btn:not(:last-child) {
  margin-right: 0.5rem;
}

.table-action-btn {
  min-width: 37px;
}
</style>
