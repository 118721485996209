<template>
  <div>
    <validation-observer ref="observer">
      <div class="modal-body py-0">
        <div class="row">
          <div class="col">
            <h2 class="mb-0">
              {{ $t('app_modal.analytics.title') }}
            </h2>
            <p class="text-muted text-sm">
              {{ $t('app_modal.analytics.subtitle') }}
            </p>
          </div>
        </div>
        <div class="row" v-loading="loading || ocrLoading">
          <div class="col-12">
            <app-form-generator
              :key="formKey"
              v-if="initialized && analyticsData"
              ref="formGenerator"
              :is-update="!isCreate"
              :schema="providerForm.schema"
              :model="analyticsData"
              @updated="analyticsDataUpdated"
              @close="close({ withConfirmation: true })"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-control-label">
              {{ $t('app_modal.analytics.file.title') }}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <dropzone-file-upload
              v-model="files"
              :loading="loading || ocrLoading"
              @add="addFile"
              @remove="removeFile"
              @download="downloadFile"
              :savedFiles="analyticsFiles"
              multiple
              downloadable
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            <base-button
              type="primary"
              outline
              @click="close({ withConfirmation: false })"
            >
              {{ $t('app_modal.analytics.buttons.cancel') }}
            </base-button>
            <base-button type="primary" @click="updatedForm">
              {{ $t('app_modal.analytics.buttons.update') }}
            </base-button>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getFormData from '@/helpers/getFormData'
import { downloadFileFromUrl } from '@/helpers/download'

export default {
  name: 'modal-analytics',

  props: {
    options: {
      type: [Object, Boolean],
    },
  },

  components: {
    'dropzone-file-upload': () =>
      import('@/components/argon/Inputs/DropzoneFileUpload'),
  },

  mounted() {
    this.$eventBus.$on('saveAnalytics', async () => {
      await this.updateAndRefresh()
    })
  },

  data() {
    return {
      loading: true,
      ocrLoading: false,
      initialized: false,
      updated: false,
      analyticsData: {},
      files: [],
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      formKey: 1,
    }
  },

  watch: {
    options: {
      handler: 'init',
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      providerForm: 'providerForms/getProviderForm',
      contentAnalytics: 'contentAnalytics/getContentAnalytics',
      content: 'contents/getContent',
    }),

    isUpdate() {
      return !this.isCreate
    },

    isCreate() {
      return !this.contentAnalytics.count > 0
    },

    analyticsFiles() {
      return this.isUpdate ? this.contentAnalytics.results[0].files : false
    },

    analyticsId() {
      return this.isUpdate ? this.contentAnalytics.results[0].id : false
    },
  },

  methods: {
    ...mapActions({
      providerFormShow: 'providerForms/show',
      contentAnalyticsShow: 'contentAnalytics/show',
      contentAnalyticsCreate: 'contentAnalytics/create',
      contentAnalyticsUpdate: 'contentAnalytics/update',
      contentAnalyticsClear: 'contentAnalytics/clear',
      fileCreate: 'files/create',
      fileDelete: 'files/delete',
      fileOcr: 'files/ocr',
      contentShow: 'contents/show',
    }),

    analyticsDataUpdated(data) {
      if (this.updated) {
        this.$emit('status-changed', false)
      } else {
        this.updated = true
      }

      if (Object.keys(data).includes('0')) {
        this.analyticsData = data.shift()
      } else {
        this.analyticsData = data
      }
    },

    async addFile(files) {
      this.loading = true

      const payload = getFormData({
        analytics: this.analyticsId,
      })

      payload.append('file', files[0])

      const { ok, data } = await this.fileCreate({
        payload,
        headers: this.headers,
      })

      if (ok) {
        this.ocrFile(data.id)
        await this.init()
      }

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t(
              'app_modal.analytics.notifications.file.add.success_message.text'
            )
          : this.$t(
              'app_modal.analytics.notifications.file.add.error_message.text'
            ),
      })

      this.loading = false
    },

    async removeFile(file) {
      this.loading = true

      const { ok } = await this.fileDelete(file.id)

      if (ok) await this.init()

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t(
              'app_modal.analytics.notifications.file.remove.success_message.text'
            )
          : this.$t(
              'app_modal.analytics.notifications.file.remove.error_message.text'
            ),
      })

      this.loading = false
    },

    async ocrFile(file) {
      if (this.content.provider.slug !== 'instagram') return

      this.ocrLoading = true

      const { ok, data } = await this.fileOcr({ payload: { file } })
      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? 'Imagem lida com sucesso!'
          : data.detail || 'Erro ao ler imagem.',
      })

      if (!ok) {
        this.ocrLoading = false
        return
      }

      const changed = data.has_changes ? Object.keys(data.changes) : []
      if (data.has_changes)
        this.$refs.formGenerator.setUpdates(Object.entries(data.changes))

      Object.entries(data.data).forEach(([k, v]) => {
        if (
          changed.map(c => (c.includes('.') ? c.split('.')[0] : c)).includes(k)
        )
          return
        this.analyticsData[k] = v
      })

      if (!data.has_changes) {
        await this.updateAndRefresh()
      }

      this.ocrLoading = false
    },

    async downloadFile(file) {
      downloadFileFromUrl(file.download_url)
    },

    async createAnalytics() {
      const data = {}
      Object.entries(this.analyticsData).forEach(([key, value]) => {
        if (!Array.isArray(value)) {
          data[key] = value
        } else {
          data[key] = []
        }
      })
      const payload = getFormData({
        computed: data,
        content_type: 'content',
        object_id: this.options.id,
        data: data,
      })

      const { ok } = await this.contentAnalyticsCreate({
        id: this.content.id,
        payload,
        headers: this.headers,
      })

      if (ok) await this.init({ force: true })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t(
              'app_modal.analytics.notifications.analytics.add.success_message.text'
            )
          : this.$t(
              'app_modal.analytics.notifications.analytics.add.error_message.text'
            ),
      })
    },

    async init({ force = false } = {}) {
      this.loading = true

      await this.contentShow(this.options.id)
      await this.contentAnalyticsShow(this.options.id)

      if (this.content) {
        await this.providerFormShow({
          provider: this.content.provider.slug,
          type: this.content.type.slug,
        })
      }

      if (this.initialized && !force) {
        this.loading = false
        return
      }

      if (this.isUpdate) {
        this.analyticsData = this.contentAnalytics.results[0].data
      } else {
        this.providerForm.schema.map(i => {
          if (!i.group) {
            this.analyticsData[i.model] = i.placeholder
          } else {
            const payload = {}
            i.group.forEach(index => {
              payload[this.providerForm.schema[index].prop] = null
            })
            this.analyticsData[i.model] = [payload]
          }
        })
      }

      if (this.isCreate) {
        await this.createAnalytics()
      }

      this.initialized = true
      this.loading = false
    },

    async updatedForm() {
      const isValid = await this.$refs.formGenerator.validate()
      if (!isValid) {
        return
      }

      this.loading = true

      const data = {}
      Object.entries(this.analyticsData).forEach(([key, value]) => {
        if (!Array.isArray(value)) {
          data[key] = value
        } else {
          data[key] = value.filter(item => !item._ignore)
        }
      })

      const payload = getFormData({
        computed: data,
        content_type: 'content',
        object_id: this.options.id,
        data: data,
      })

      const { ok } = await this.contentAnalyticsUpdate({
        contentId: this.content.id,
        id: this.analyticsId,
        payload,
        headers: this.headers,
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t(
              'app_modal.analytics.notifications.analytics.update.success_message.text'
            )
          : this.$t(
              'app_modal.analytics.notifications.analytics.update.error_message.text'
            ),
      })

      if (ok) {
        this.$emit('status-changed', true)
        await this.$refs.observer.reset()
        await this.init({ force: true })
      }

      this.loading = false
    },

    close({ withConfirmation } = {}) {
      this.$emit('close', { withConfirmation, checkObserver: false })
    },

    async updateAndRefresh() {
      await this.updatedForm()
      this.formKey++
    },
  },

  beforeDestroy() {
    this.contentAnalyticsClear()
  },
}
</script>
