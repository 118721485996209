/* eslint-disable import/no-duplicates */
// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills'

import Notifications from '@/components/argon/NotificationPlugin'
import GlobalComponents from '@/components/argon/plugin/globalComponents'
import GlobalDirectives from '@/components/argon/plugin/globalDirectives'
import SideBar from '@/components/argon/SidebarPlugin'
import Vue2Filters from 'vue2-filters'

import '@/assets/sass/argon.scss'
import '@/assets/css/nucleo/css/nucleo.css'

export default {
  install(Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(SideBar)
    Vue.use(Notifications)
    Vue.use(Vue2Filters)
  },
}
