import ApiService from '@/services/api'

const state = {
  kpi: false,
  kpis: false,
}

const getters = {
  getKpi: state => state.kpi,
  getKpis: state => state.kpis,
}

const actions = {
  async index({ commit }, params) {
    const { data, ok } = await ApiService.index('kpis', params)

    if (ok) {
      commit('setKpis', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('kpis', id)

    if (ok) {
      commit('setKpi', data)
    }

    return { data, ok }
  },

  async create({ commit }, payload) {
    const { data, ok } = await ApiService.create({
      resource: 'kpis',
      data: payload,
    })

    if (ok) {
      commit('setKpi', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.update({
      resource: 'kpis',
      id,
      data: payload,
    })

    if (ok) {
      commit('setKpi', data)
    }

    return { data, ok }
  },

  async delete(_, id) {
    const { ok } = await ApiService.delete('kpis', id)

    return { ok }
  },
}

const mutations = {
  setKpi(state, kpi) {
    state.kpi = kpi
  },

  setKpis(state, kpis) {
    state.kpis = kpis
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
