<template>
  <div>
    <div class="modal-body py-0" v-loading="isLoading">
      <h2 class="mb-0">{{ $t('app_modal.depacker.title') }}</h2>
      <p class="text-muted text-sm">
        {{ $t('app_modal.depacker.subtitle') }}
      </p>
      <div class="row my-3">
        <div class="col">
          <app-depacker @updated="setDepackerData" @clear="clear" />
        </div>
      </div>

      <div class="row" v-if="isUpdated && !creatorRegistered">
        <div class="col">
          <base-alert type="secondary" class="d-flex align-items-center">
            <p class="mb-0 text-sm">
              <i class="fas fa-exclamation-triangle text-warning mr-2" />
              {{ $t('app_modal.depacker.creator.not_found') }}
            </p>
            <base-button
              type="primary"
              size="sm"
              class="ml-auto"
              @click="creatorCreate"
            >
              {{ $t('app_modal.depacker.add_button') }}
            </base-button>
          </base-alert>
        </div>
      </div>

      <div
        class="row"
        v-if="creatorRegistered && !contentRegistered && hasContent"
      >
        <div class="col">
          <base-alert type="secondary" class="d-flex align-items-center">
            <p class="mb-0 text-sm">
              <i class="fas fa-exclamation-triangle text-warning mr-2" />
              {{ $t('app_modal.depacker.content.not_found') }}
            </p>
            <base-button
              type="primary"
              size="sm"
              class="ml-auto"
              @click="
                $eventBus.$emit(
                  'changeModal',
                  'contents',
                  'depacker',
                  mbContent
                )
              "
            >
              {{ $t('app_modal.depacker.add_button') }}
            </base-button>
          </base-alert>
        </div>
      </div>

      <div class="row" v-if="content">
        <div class="col">
          <h5>{{ $t('app_modal.depacker.content.title') }}</h5>
          <base-alert type="secondary" class="d-flex align-items-center">
            <app-image
              :src="content.data.media_url"
              class="avatar avatar-sm mr-2"
            />
            <p class="text-xs ml-2 mb-0">
              {{ content.data.caption | truncate(70) }}
            </p>
            <router-link
              class="ml-auto"
              :to="`/app/campaigns/${campaignId}/contents/${content.id}`"
            >
              <base-button
                type="primary"
                size="sm"
                class="ml-auto"
                icon
                @click="hideModal"
              >
                <i class="far fa-eye" />
              </base-button>
            </router-link>
          </base-alert>
        </div>
      </div>

      <div class="row" v-if="creatorRegistered && campaignCreator">
        <div class="col">
          <h5>{{ $t('app_modal.depacker.creator.title') }}</h5>
          <base-alert type="secondary" class="d-flex align-items-center">
            <app-image
              :src="campaignCreator.creator.picture_url"
              class="avatar avatar-sm mr-2"
            />
            {{ campaignCreator.creator.username }}
            <router-link
              class="ml-auto"
              :to="
                `/app/campaigns/${campaignId}/creators/${campaignCreator.id}`
              "
            >
              <base-button
                type="primary"
                size="sm"
                class="ml-auto"
                icon
                @click="hideModal"
              >
                <i class="far fa-eye" />
              </base-button>
            </router-link>
          </base-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      isUpdated: false,
      isLoading: false,
      creatorRegistered: false,
      contentRegistered: false,
      depacker: false,
      creatorData: false,
      contentData: false,
    }
  },

  computed: {
    ...mapGetters({
      checkService: 'providers/checkService',
      contentMedia: 'contents/getContentMedia',
      creator: 'creators/getCreator',
      content: 'contents/getContent',
      campaignCreator: 'campaignCreators/getCampaignCreator',
    }),

    mbContent() {
      const { contentMedia, depacker } = this
      const content = (contentMedia && contentMedia.data) || depacker
      return { content, depacker }
    },

    campaignId() {
      return this.$route.params.campaignId
    },

    hasContent() {
      return this.depacker.shortcode
    },

    isCreatorURL() {
      return (
        !!this.depacker.username &&
        !this.depacker.shortcode &&
        !this.depacker.id
      )
    },
  },

  methods: {
    ...mapActions({
      showMedia: 'contents/showMedia',
      contentShow: 'contents/show',
      contentSearch: 'contents/search',
      contentClear: 'contents/clear',
      creatorSearch: 'creators/search',
      creatorFetch: 'creators/fetch',
      creatorClear: 'creators/clear',
      campaignCreatorSearch: 'campaignCreators/search',
      campaignCreatorCreate: 'campaignCreators/create',
      campaignCreatorClear: 'campaignCreators/create',
      cratorSearchByNativeId: 'campaignCreators/searchByNativeId',
    }),

    async setDepackerData(data) {
      this.depacker = data
      await this.searchData()
    },

    async searchData() {
      this.isLoading = true

      this.clear()

      const hasProvider = this.checkService(this.depacker.provider)

      if (this.hasContent) {
        await this.searchContent(hasProvider)
        await this.searchCreator(hasProvider)
      } else {
        await this.searchCreator(hasProvider)
      }

      this.isUpdated = true
      this.isLoading = false

      this.$emit('status-changed', false)
    },

    async searchContent(hasProvider) {
      const { data } = await this.contentSearch({
        id: this.campaignId,
        search: {
          native_id: this.depacker.shortcode,
          campaign_creator__creator__provider__slug: this.depacker.provider,
        },
      })

      this.contentRegistered = data.count > 0

      if (hasProvider) {
        await this.showMedia({
          id: this.depacker.shortcode,
          origin: this.depacker.provider,
        })
      }
    },

    async searchCreator(hasProvider) {
      if (hasProvider) {
        if (this.depacker.username) {
          const { data } = await this.campaignCreatorSearch({
            id: this.campaignId,
            search: {
              username: this.depacker.username,
              provider: this.depacker.provider,
            },
          })

          if (data.count === 0) {
            await this.creatorSearch({
              q: this.depacker.username,
              origin: this.depacker.provider,
            })
          } else {
            this.creatorRegistered = true
          }
        } else {
          const { ok } = await this.cratorSearchByNativeId({
            campaignId: this.campaignId,
            id: this.contentMedia.data.influencer_id,
          })

          if (!ok) {
            await this.creatorFetch({
              id: this.contentMedia.data.influencer_id,
              origin: this.depacker.provider,
            })
          } else {
            this.creatorRegistered = true
          }
        }
      } else {
        const { data } = await this.campaignCreatorSearch({
          id: this.campaignId,
          search: {
            search: this.depacker.username,
            creator__provider__slug: this.depacker.provider,
          },
        })

        this.creatorRegistered = data.count > 0
      }
    },

    async creatorCreate() {
      let payload

      if (!this.creator) {
        payload = {
          creator: {
            id: `${this.depacker.provider}-${this.depacker.username}`,
            username: this.depacker.username,
            name: this.depacker.username,
            picture_url: null,
            social_media: this.depacker.provider,
            data: {
              username: this.depacker.username,
              name: this.depacker.username,
            },
          },
        }
      } else {
        payload = {
          creator: {
            username: this.creator.username,
            name: this.creator.name,
            id: this.creator.id,
            picture_url: this.creator.picture_url,
            social_media: this.creator.social_media,
            data: this.creator,
          },
        }
      }

      const { ok } = await this.campaignCreatorCreate({
        id: this.campaignId,
        payload,
      })

      Swal.fire(
        ok
          ? this.$t(
              'app_modal.depacker.notifications.creator.success_message.title'
            )
          : this.$t(
              'app_modal.depacker.notifications.creator.error_message.title'
            ),
        ok
          ? this.$t(
              'app_modal.depacker.notifications.creator.success_message.text'
            )
          : this.$t(
              'app_modal.depacker.notifications.creator.error_message.text'
            ),
        ok ? 'success' : 'error'
      )

      await this.searchData()
    },

    clear() {
      this.creatorClear()
      this.contentClear({ contents: false })
      this.campaignCreatorClear()
      this.isUpdated = false
      this.creatorRegistered = false
      this.contentRegistered = false

      this.$emit('status-changed', true)
    },

    hideModal() {
      this.$emit('close', {
        withConfirmation: false,
        checkObserver: false,
      })
    },
  },

  mounted() {
    this.clear()
  },

  beforeDestroy() {
    this.clear()
  },
}
</script>
