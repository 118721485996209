<template>
  <div>
    <div class="tags-container" style="max-width:100%;overflow-x:auto">
      <tag
        v-for="(tag, idx) in tags"
        :key="`tag-${idx}`"
        :label="tag[0]"
        :value="tag[1]"
      />
      <input
        type="text"
        v-model="input"
        v-if="tags === null"
        @keypress.enter="depackURL"
        @keypress.esc="clear"
      />
      <div v-if="!tags" @click="depackURL">
        <i class="fas fa-search text-muted" />
      </div>
      <div v-if="tags" @click="clear">
        <i class="ni ni-fat-remove text-muted" />
      </div>
    </div>
  </div>
</template>

<script>
import { rules } from './partials/config.json'
import Tag from './partials/Tag'

export default {
  name: 'app-depacker',

  components: {
    Tag,
  },

  data() {
    return {
      input: null,
      data: {},
      tags: null,
      items: null,
    }
  },

  methods: {
    async depackURL() {
      if (!this.input) {
        return
      }

      const urlOriginMatch = this.input.match(
        /(\b(?!http|www|watch|\.com))([\w-]+(?:\.[\w-]{2,})?)/
      )

      if (!urlOriginMatch) {
        return
      }

      const [origin] = urlOriginMatch
      const [provider] = origin.match(/(\b(?!http|www|\.com))([\w-]*)/)

      const urlPath = this.input.substring(urlOriginMatch.index + origin.length)
      const urlPathMatch = urlPath.match(
        /(\b(?!watch\?))([\w-]+(?:\.[\w-]{2,})?)/g
      )

      if (!urlPathMatch) {
        return
      }

      let attributes

      if (urlPathMatch.length <= 1) {
        attributes = { username: urlPathMatch[0] }
      } else {
        attributes = urlPathMatch.reduce((acc, item) => {
          rules[provider].forEach(rule => {
            if (Object.values(rule).indexOf(item) > -1) {
              rule.items.map((ruleItem, i) => {
                if (ruleItem === 'type') {
                  acc[ruleItem] = rule.type
                } else if (ruleItem !== null) {
                  acc[ruleItem] = urlPathMatch[i]
                }
              })
            }
          })
          return acc
        }, {})
      }

      this.data = { provider, ...attributes }
      this.items = urlPathMatch

      this.clearInput()
      this.populateTags()
      this.$emit('updated', this.data)
    },

    clearInput() {
      this.input = ''
    },

    clear() {
      this.$emit('clear')
      this.data = {}
      this.tags = null
      this.input = ''
    },

    populateTags() {
      this.tags = Object.entries(this.data)
    },
  },
}
</script>

<style>
.tags-container {
  padding: 4px 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tags-container input {
  flex: 1;
  font-size: 16px;
  padding: 5px;
  outline: none;
  border: none;
}
</style>
