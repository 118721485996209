import { StorageService } from '@/services/storage'
import ApiService from '@/services/api'
import PublicService from '@/services/public'

const state = {
  language: StorageService.get('lang'),
  languages: [
    {
      label: 'PT-BR',
      value: 'pt-br',
      icon: '/img/icons/BR.png',
    },
    {
      label: 'ENG',
      value: 'en-us',
      icon: '/img/icons/US.png',
    },
  ],
}

const getters = {
  getLanguage: state => state.language,
  getLanguages: state => state.languages,
}

const actions = {
  updateLanguage(_, lang) {
    ApiService.setLanguage(lang)
    PublicService.setLanguage(lang)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
}
