import ApiService from '@/services/api'

const state = {
  campaignContents: null,
}

const getters = {
  getCampaignContents: state => state.campaignContents,
}

const actions = {
  async exportCSV(_, { id }) {
    return ApiService.api.get(
      `campaigns/${id}/contents/export/?filetype=csv`,
      {},
      { responseType: 'blob', headers: { Accept: 'text/csv' } }
    )
  },
  async index({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/contents/`,
      params
    )

    if (ok) {
      commit('setCampaignContents', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setCampaignContents(state, campaignContents) {
    state.campaignContents = campaignContents
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
