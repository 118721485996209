<template>
  <table-wrapper
    :title="$t('app_table.comments.title')"
    :is-loading="isLoading"
    :pagination="pagination"
    @change-page="onChangePage"
    @refresh="onRefresh"
  >
    <template slot="toolbar" slot-scope="{ refresh }">
      <el-tooltip :content="$t('app_table.comments.export')" placement="top">
        <base-button size="sm" type="success" icon @click="exportCSV">
          <span class="fas fa-file-csv" />
          <span class="btn-inner--text">
            {{ $t('app_table.comments.export') }}
          </span>
        </base-button>
      </el-tooltip>
      <el-tooltip
        v-if="!campaignMode"
        :content="$t('app_table.comments.load')"
        placement="top"
      >
        <base-button
          size="sm"
          type="primary"
          outline
          icon
          @click="loadComments"
        >
          <span class="fas fa-search" />
          <span class="btn-inner--text">
            {{ $t('app_table.comments.load') }}
          </span>
        </base-button>
      </el-tooltip>
      <el-tooltip :content="$t('app_table.comments.refresh')" placement="top">
        <base-button size="sm" type="secondary" icon @click="refresh">
          <span class="fas fa-sync-alt" />
          <span class="btn-inner--text">
            {{ $t('app_table.comments.refresh') }}
          </span>
        </base-button>
      </el-tooltip>
    </template>

    <div slot="sub-header" class="mt-4">
      <div class="row">
        <div class="col-3">
          <base-input
            :label="$t('app_table.comments.is_classified_select.placeholder')"
          >
            <el-select
              :placeholder="
                $t('app_table.comments.is_classified_select.placeholder')
              "
              v-model="filter.is_classified"
              @input="refetchComments"
            >
              <el-option
                :value="true"
                :label="
                  $t('app_table.comments.is_classified_select.classified')
                "
              />
              <el-option
                :value="false"
                :label="
                  $t('app_table.comments.is_classified_select.not_classified')
                "
              />
              <el-option
                :value="null"
                :label="$t('app_table.comments.is_classified_select.all')"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-3">
          <base-input
            :label="$t('app_table.comments.highlight_select.placeholder')"
          >
            <el-select
              :placeholder="
                $t('app_table.comments.highlight_select.placeholder')
              "
              v-model="filter.is_highlight"
              @input="refetchComments"
            >
              <el-option
                :value="true"
                :label="$t('app_table.comments.highlight_select.highlighted')"
              />
              <el-option
                :value="false"
                :label="
                  $t('app_table.comments.highlight_select.not_highlighted')
                "
              />
              <el-option
                :value="null"
                :label="$t('app_table.comments.highlight_select.all')"
              />
            </el-select>
          </base-input>
        </div>
      </div>
    </div>
    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :empty-text="$t('app_table.comments.no_data')"
      :data="comments ? comments.results : []"
      v-loading="isLoading"
    >
      <el-table-column
        :label="$t('app_table.comments.user_column.label')"
        min-width="90px"
      >
        <template v-slot="{ row }">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="avatar avatar-sm rounded-circle">
                <app-image
                  :alt="row.data.owner.name"
                  :src="row.data.owner.picture_url"
                  default-image="/img/logo-dots.png"
                />
              </div>
            </div>
            <div class="col ml--2">
              <h5 class="mb-0">
                {{ row.data.owner.name | truncate(15) }}
              </h5>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.comments.comment_column.label')"
        min-width="120px"
      >
        <template v-slot="{ row }">
          <div class="row align-items-center">
            <div class="col">
              <p class="text-xs my-0" v-if="expandIndex !== row.id">
                {{ row.data.text | truncate(70) }}
              </p>
              <p class="text-xs my-0" v-else>
                {{ row.data.text }}
              </p>

              <div
                v-if="row.data.text.length > 70"
                class="btn btn-outline-secondary btn-sm text-xs font-primary mt-2"
                @click="expandIndex = expandIndex === row.id ? false : row.id"
              >
                {{
                  expandIndex !== row.id
                    ? $t('app_table.comments.comment_column.expand')
                    : $t('app_table.comments.comment_column.contract')
                }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.comments.created_at_column.label')"
        prop="created_at"
        sortable
      >
        <template v-slot="{ row }">
          {{ row.created_at | moment('from', 'now') }}
        </template>
      </el-table-column>

      <el-table-column label="ML" min-width="30px">
        <template v-slot="{ row: { sentiments } }">
          <template v-if="sentiments.length > 0">
            <el-tooltip
              :content="
                sentiments[0].is_ml_classified
                  ? $t('app_table.comments.ml_column.ml_classified')
                  : $t('app_table.comments.ml_column.user_classified')
              "
              placement="top"
            >
              <i
                :class="
                  sentiments[0].is_ml_classified
                    ? 'fas fa-robot'
                    : 'fas fa-user'
                "
              />
            </el-tooltip>
          </template>
        </template>
      </el-table-column>

      <el-table-column min-width="130px">
        <template v-slot="{ row }" v-if="sentiments">
          <div class="d-flex">
            <div
              v-for="sentiment in sentiments.results"
              :key="sentiment.id"
              @click="toggleSentiment(row, sentiment.id)"
              class="sentiment"
              :class="isSelected(row, sentiment.id) ? 'selected' : ''"
            >
              {{ $t('sentiments.' + sentiment.slug) }}
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column min-width="50px">
        <template v-slot="{ row }" v-if="sentiments">
          <base-button
            size="sm"
            type="primary"
            :outline="!row.is_highlight"
            icon
            @click="toggleFavorite(row)"
          >
            <i class="fa fa-heart" />
          </base-button>
        </template>
      </el-table-column>
    </el-table>
  </table-wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import TableWrapper from './TableWrapper'

import { downloadFileFromResponse } from '@/helpers/download'

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui'

export default {
  name: 'comments-index-table',

  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    TableWrapper,
  },

  data() {
    return {
      isLoading: false,
      selectedComment: false,
      sentimentId: false,
      expandIndex: false,
      filter: {
        is_classified: null,
        is_highlight: null,
      },
    }
  },

  async mounted() {
    await this.init()
  },

  computed: {
    ...mapGetters({
      sentiments: 'sentiments/getSentiments',
    }),

    comments() {
      const getter = this.campaignMode
        ? 'campaignComments/getCampaignComments'
        : 'contentComments/getContentComments'

      return this.$store.getters[getter]
    },

    campaignMode() {
      return this.options.mode === 'campaign'
    },

    campaignId() {
      return this.$route.params.campaignId
    },

    contentId() {
      return this.$route.params.contentId
    },

    pagination() {
      if (!this.comments) {
        return { current: 1 }
      }

      const { pages, count } = this.comments

      return {
        current: pages.current,
        perPage: pages.per_page,
        total: count,
      }
    },
  },

  methods: {
    ...mapActions({
      contentCommentIndex: 'contentComments/index',
      campaignCommentIndex: 'campaignComments/index',
      commentUpdate: 'contentComments/update',
      commentLoad: 'contentComments/load',
      commentSentimentCreate: 'commentSentiments/create',
      commentSentimentDelete: 'commentSentiments/delete',
      sentimentIndex: 'sentiments/index',
      contentCommentsExportCSV: 'contentComments/exportCSV',
      campaignCommentsExportCSV: 'campaignComments/exportCSV',
    }),

    commentIndex(args) {
      const action = this.campaignMode
        ? 'campaignComments/index'
        : 'contentComments/index'

      return this.$store.dispatch(action, args)
    },

    showModal(id) {
      this.$eventBus.$emit('showModal', 'contents', {
        method: 'update',
        id: id,
      })
    },

    async init() {
      await Promise.all([this.sentimentIndex(), this.indexComments(1)])
    },

    async indexComments(page) {
      this.isLoading = true

      const filterNames = {}

      const filters = {}
      Object.entries(this.filter).forEach(([key, value]) => {
        filters[filterNames[key] || key] = value
      })

      await this.commentIndex({
        id: this.contentId || this.campaignId,
        params: {
          page,
          ...filters,
        },
      })

      this.isLoading = false
    },

    async onChangePage(page) {
      await this.indexComments(page)
    },

    async onRefresh() {
      await this.indexComments(this.pagination.current)
    },

    async loadComments() {
      this.isLoading = true

      const { ok } = await this.commentLoad(this.contentId)

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_table.comments.load_comments_notify.success')
          : this.$t('app_table.comments.load_comments_notify.error'),
      })

      if (ok) await this.indexComments(1)

      this.isLoading = false
    },

    isSelected(comment, id) {
      if (comment && id) {
        return !!comment.sentiments.find(i => i.sentiment.id === id)
      }
      return false
    },

    async toggleSentiment(comment, id) {
      const isSelected = this.isSelected(comment, id)

      if (isSelected) {
        await this.removeSentiment(comment, id)
      } else {
        const hasSentiments = comment.sentiments.length > 0

        if (hasSentiments) {
          comment.sentiments.map(
            async s => await this.removeSentiment(comment, s.sentiment.id)
          )
        }
        await this.addSentiment(comment, id)
      }
    },

    async toggleFavorite(comment) {
      let payload = {}

      this.comments.results.map(c => {
        if (c.id === comment.id) {
          c.is_highlight = !c.is_highlight
          payload = c
        }
      })

      const { ok } = await this.commentUpdate({
        id: this.contentId || comment.content,
        payload,
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_table.comments.update_comment_notify.success')
          : this.$t('app_table.comments.update_comment_notify.error'),
      })
    },

    async addSentiment(comment, id) {
      const { ok } = await this.commentSentimentCreate({
        id: comment.id,
        payload: {
          sentiment_id: id,
          severity: 1,
        },
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_table.comments.sentiment_notify.success')
          : this.$t('app_table.comments.sentiment_notify.error'),
      })

      await this.indexComments(this.pagination.current)
    },

    async removeSentiment(comment, id) {
      const sentiment = comment.sentiments.find(i => i.sentiment.id === id)

      const { ok } = await this.commentSentimentDelete({
        commentId: sentiment.comment,
        id: sentiment.id,
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_table.comments.sentiment_notify.success')
          : this.$t('app_table.comments.sentiment_notify.error'),
      })

      await this.indexComments(this.pagination.current)
    },

    refetchComments: debounce(function() {
      return this.indexComments(1)
    }, 500),

    async exportCSV() {
      try {
        const response = await this._exportCSV()

        downloadFileFromResponse(response)
      } catch (error) {
        this.$notify({
          type: 'danger',
          message: this.$t('app_table.comments.export_notify.error'),
        })
      }
    },

    _exportCSV() {
      if (this.campaignMode) {
        return this.campaignCommentsExportCSV({ id: this.campaignId })
      }

      return this.contentCommentsExportCSV({ id: this.contentId })
    },
  },
}
</script>

<style lang="scss" scoped>
.sentiment {
  border: 1px solid #ebeef5;
  color: #8898aa;
  border-radius: 8px;
  font-size: 0.7rem !important;
  padding: 2px 6px;
  margin-right: 8px;

  &.selected {
    background-color: #5e72e4;
    border-color: #5e72e4;
    color: #fff;
  }
}
</style>
