<template>
  <component :is="type" :data="data" />
</template>

<script>
import kpis from './partials/Kpis'
import contentsUnclassified from './partials/ContentsUnclassified'
import apiKeys from './partials/ApiKeys'
import tags from './partials/Tags'

export default {
  name: 'app-list',

  props: {
    type: {
      type: String,
      required: true,
      default: 'kpis',
    },
    data: {
      type: [Object, Boolean],
    },
  },

  components: {
    kpis,
    'contents-unclassified': contentsUnclassified,
    'api-keys': apiKeys,
    tags,
  },
}
</script>
