import ApiService from '@/services/api'

const state = {
  creatorContent: false,
  creatorContents: false,
}

const getters = {
  getCreatorContent: state => state.creatorContent,
  getCreatorContents: state => state.creatorContents,
}

const actions = {
  async index({ commit }, { params }) {
    const { data, ok } = await ApiService.index('contents/', params)

    if (ok) {
      commit('setCreatorContents', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setCreatorContent(state, creatorContent) {
    state.creatorContent = creatorContent
  },

  setCreatorContents(state, creatorContents) {
    state.creatorContents = creatorContents
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
