var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-wrapper',{attrs:{"title":_vm.$t('app_table.posts.title'),"is-loading":_vm.isLoading,"pagination":_vm.pagination},on:{"change-page":_vm.onChangePage}},[_c('el-table',{ref:"tableRef",staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.posts.results,"empty-text":_vm.$t('app_table.posts.no_data')}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('app-image',{staticClass:"avatar avatar-sm rounded-circle",attrs:{"src":row.data.media_url}})]}}])}),_c('el-table-column',{attrs:{"label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type && _vm.types.results)?_c('span',[_vm._v(" "+_vm._s(_vm.postType(row.type).name)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Provider"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.provider && _vm.providers.results)?_c('span',[_vm._v(" "+_vm._s(_vm.postProvider(row.provider).name)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Created at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.row.data;
return [_vm._v(" "+_vm._s(_vm._f("moment")(data.created_at,'from', 'now'))+" ")]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"table-action-btn",attrs:{"to":("/app/posts/" + (row.id))}},[_c('base-button',{attrs:{"type":"secondary","outline":"","size":"sm"}},[_vm._v(" Ver comentários "),_c('i',{staticClass:"far fa-comments"})])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }