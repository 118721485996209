<template>
  <div class="modal-body py-0">
    <div class="row">
      <div class="col">
        <h2 class="mb-0">
          {{ $t('app_modal.change_campaign_owner.title') }}
        </h2>
        <p class="text-muted text-sm">
          {{ $t('app_modal.change_campaign_owner.subtitle') }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <base-input
          :label="$t('app_modal.change_campaign_owner.user.label')"
          :name="$t('app_modal.change_campaign_owner.user.name')"
          :rules="{ required: true }"
        >
          <el-select
            :placeholder="
              $t('app_modal.change_campaign_owner.user.placeholder')
            "
            v-model="payload.user.id"
            clearable
            filterable
            remote
            :remote-method="searchUser"
          >
            <el-option
              v-for="user in users.results"
              :value="user.id"
              :label="user.full_name"
              :key="user.id"
            />
          </el-select>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <base-button type="secondary" @click="$emit('close')">
          {{ $t('app_modal.change_campaign_owner.buttons.close') }}
        </base-button>
        <base-button type="primary" @click="changeOwner">
          {{ $t('app_modal.change_campaign_owner.buttons.update') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'change-campaign-owner',

  data() {
    return {
      payload: {
        user: {
          id: '',
        },
      },
      filter: {
        search: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      campaign: 'campaigns/getCampaign',
      users: 'users/getUsers',
    }),

    campaignId() {
      return this.campaign ? this.campaign.id : false
    },
  },

  methods: {
    ...mapActions({
      userIndex: 'users/index',
      campaignChangeOwner: 'campaigns/changeOwner',
    }),

    async searchUser(data) {
      await this.userIndex({
        search: data,
      })
    },

    async changeOwner() {
      const { ok } = await this.campaignChangeOwner({
        id: this.campaignId,
        payload: { user: this.payload.user.id },
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_modal.change_campaign_owner.notifications.success')
          : this.$t('app_modal.change_campaign_owner.notifications.error'),
      })

      if (ok) this.closeModalAndRefetchCampaigns()
    },

    closeModalAndRefetchCampaigns() {
      this.$emit('close')
      this.$eventBus.$emit('refetchCampaigns')
    },
  },

  async mounted() {
    await this.userIndex({ per_page: 20 })

    if (this.campaign) {
      this.payload.user = this.campaign.user
    }
  },
}
</script>
