<template>
  <table-wrapper
    :title="$t('app_table.creators.title')"
    :is-loading="isLoading"
    :pagination="pagination"
    @change-page="onPageChange"
    @refresh="onRefresh"
  >
    <div slot="sub-header" class="row mt-4">
      <div class="col-3">
        <base-input>
          <el-select
            :placeholder="$t('app_table.contents.tags_select.placeholder')"
            v-model="filter.tags"
            multiple
            clearable
            @input="fetch"
          >
            <el-option
              v-for="tag in tags.results"
              :value="tag.id"
              :label="tag.name"
              :key="tag.id"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col">
        <base-input
          group
          input-group-classes="input-group-md"
          form-group-classes="mb-0"
        >
          <input
            v-model="search"
            type="text"
            class="form-control"
            :placeholder="$t('app_table.creators.fields.search.placeholder')"
            :aria-label="$t('app_table.creators.fields.search.placeholder')"
            @keypress.enter="onSearch"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-primary"
              type="button"
              @click="onSearch"
            >
              {{ $t('app_table.creators.buttons.search') }}
            </button>
          </div>
        </base-input>
      </div>
    </div>

    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :data="creators.results"
      v-if="creators"
      :empty-text="$t('app_table.creators.no_data')"
    >
      <el-table-column
        :label="$t('app_table.creators.columns.creator')"
        prop="name"
        min-width="120px"
        sortable
      >
        <template v-slot="{ row }">
          <div class="row align-items-center">
            <div class="col-auto d-flex">
              <picture class="avatar avatar-sm rounded-circle">
                <app-image
                  alt="Image placeholder"
                  :src="row.creator.picture_url"
                />
              </picture>
            </div>
            <div class="col ml--2">
              <h5 class="mb-0">
                {{ row.creator.name }}
              </h5>
              <p class="text-xs text-muted mb-0">@{{ row.creator.username }}</p>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.creators.columns.provider')"
        prop="name"
        min-width="90px"
        sortable
      >
        <template v-slot="{ row }">
          <div class="row align-items-center">
            <div class="col-auto">
              <i
                :class="
                  `fab fa-${$options.filters.lowercase(
                    row.creator.provider.name
                  )} text-md`
                "
              />
            </div>
            <div class="col ml--3">
              <h6 class="text-xs mb-0">
                {{ row.creator.provider.name }}
              </h6>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column>
        <template v-slot="{ row }">
          <div class="d-flex">
            <router-link
              class="mr-2"
              :to="`/app/campaigns/${campaignId}/creators/${row.id}`"
            >
              <base-button type="secondary" outline size="sm">
                <i class="far fa-eye" />
              </base-button>
            </router-link>
            <div
              @click="
                $eventBus.$emit('showModal', 'creators', {
                  method: 'update',
                  id: row.creator.id,
                })
              "
            >
              <base-button type="secondary" outline size="sm">
                <i class="fas fa-pencil-alt" />
              </base-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </table-wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TableWrapper from './TableWrapper'

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui'

export default {
  name: 'creators-index-table',

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    TableWrapper,
  },

  data() {
    return {
      currentPage: 1,
      search: '',
      isLoading: false,
      filter: {
        tags: [],
      },
    }
  },

  async mounted() {
    await this.fetch({ page: this.currentPage })
  },

  computed: {
    ...mapGetters({
      campaign: 'campaigns/getCampaign',
      creators: 'campaignCreators/getCampaignCreators',
      tags: 'tags/getTags',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    brandId() {
      return this.campaign ? this.campaign.brand.id : null
    },

    pagination() {
      if (!this.creators) {
        return { current: 1 }
      }

      const { pages, count } = this.creators

      return {
        current: pages.current,
        perPage: pages.per_page,
        total: count,
      }
    },
  },

  methods: {
    ...mapActions({
      campaignCreatorsIndex: 'campaignCreators/index',
      tagIndex: 'tags/index',
    }),

    async fetch({ page, search }) {
      this.isLoading = true
      await this.campaignCreatorsIndex({
        id: this.campaignId,
        params: {
          page,
          search,
          tags: this.filter.tags,
        },
      })

      if (this.brandId) {
        await this.tagIndex({ brand__id: this.brandId })
      }
      this.isLoading = false
    },

    async onSearch() {
      await this.fetch({
        page: 1,
        search: this.search,
      })
    },

    async onRefresh() {
      await this.fetch({ page: this.pagination.current, search: this.search })
    },

    async onPageChange(page) {
      await this.fetch({
        page,
        search: this.search,
      })
    },
  },
}
</script>
