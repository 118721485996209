import VueGtag from 'vue-gtag'

import router from '@/router'

export default {
  install(Vue) {
    const id = process.env.VUE_APP_GOOGLE_ANALYTICS_ID
    Vue.use(
      VueGtag,
      {
        config: { id },
      },
      router
    )
  },
}
