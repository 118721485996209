var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-wrapper',{attrs:{"title":_vm.$t('app_table.comments.title'),"is-loading":_vm.isLoading,"pagination":_vm.pagination},on:{"change-page":_vm.onChangePage,"refresh":_vm.onRefresh}},[_c('template',{slot:"toolbar"},[_c('el-tooltip',{attrs:{"content":_vm.$t('app_table.comments.load'),"placement":"top"}},[_c('base-button',{attrs:{"size":"sm","type":"primary","outline":"","icon":""},on:{"click":_vm.loadComments}},[_c('span',{staticClass:"fas fa-search"}),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t('app_table.comments.load'))+" ")])])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","empty-text":_vm.$t('app_table.comments.no_data'),"data":_vm.comments ? _vm.comments.results : []}},[_c('el-table-column',{attrs:{"label":_vm.$t('app_table.comments.user_column.label'),"min-width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm rounded-circle"},[_c('app-image',{attrs:{"alt":row.data.owner.name,"src":row.data.owner.picture_url,"default-image":"/img/logo-dots.png"}})],1)]),_c('div',{staticClass:"col ml--2"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm._f("truncate")(row.data.owner.name,15))+" ")])])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('app_table.comments.comment_column.label'),"min-width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[(_vm.expandIndex !== row.id)?_c('p',{staticClass:"text-xs my-0"},[_vm._v(" "+_vm._s(_vm._f("truncate")(row.data.text,70))+" ")]):_c('p',{staticClass:"text-xs my-0"},[_vm._v(" "+_vm._s(row.data.text)+" ")]),(row.data.text.length > 70)?_c('div',{staticClass:"btn btn-outline-secondary btn-sm text-xs font-primary mt-2",on:{"click":function($event){_vm.expandIndex = _vm.expandIndex === row.id ? false : row.id}}},[_vm._v(" "+_vm._s(_vm.expandIndex !== row.id ? _vm.$t('app_table.comments.comment_column.expand') : _vm.$t('app_table.comments.comment_column.contract'))+" ")]):_vm._e()])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('app_table.comments.created_at_column.label'),"prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("moment")(row.created_at,'from', 'now'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"ML","min-width":"30px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var sentiments = ref.row.sentiments;
return [(sentiments.length > 0)?[_c('el-tooltip',{attrs:{"content":sentiments[0].is_ml_classified
                ? _vm.$t('app_table.comments.ml_column.ml_classified')
                : _vm.$t('app_table.comments.ml_column.user_classified'),"placement":"top"}},[_c('i',{class:sentiments[0].is_ml_classified
                  ? 'fas fa-robot'
                  : 'fas fa-user'})])]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"130px"},scopedSlots:_vm._u([(_vm.sentiments)?{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('div',{staticClass:"d-flex"},_vm._l((_vm.sentiments.results),function(sentiment){return _c('div',{key:sentiment.id,staticClass:"sentiment",class:_vm.isSelected(row, sentiment.id) ? 'selected' : '',on:{"click":function($event){return _vm.toggleSentiment(row, sentiment.id)}}},[_vm._v(" "+_vm._s(_vm.$t('sentiments.' + sentiment.slug))+" ")])}),0)]}}:null],null,true)}),_c('el-table-column',{attrs:{"min-width":"50px"},scopedSlots:_vm._u([(_vm.sentiments)?{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('base-button',{attrs:{"size":"sm","type":"primary","outline":!row.is_highlight,"icon":""},on:{"click":function($event){return _vm.toggleFavorite(row)}}},[_c('i',{staticClass:"fa fa-heart"})])]}}:null],null,true)})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }