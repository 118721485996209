<template>
  <div class="card tags-card">
    <div class="card-header">
      <h6 class="surtitle">
        {{ $t('app_list.tags.surtitle') }}
      </h6>
      <h5 class="h3 mb-0">{{ $t('app_list.tags.title') }}</h5>
    </div>
    <div class="card-body">
      <ul
        v-if="campaignTagsResults.length"
        class="list-group list-group-flush list my--3"
      >
        <li
          v-for="(item, index) in campaignTagsResults"
          :key="index"
          class="list-group-item px-0"
        >
          <div class="row align-items-center">
            <div class="col">
              <h5>
                {{ item.name }}
              </h5>
            </div>
          </div>
        </li>
      </ul>
      <div
        v-else
        class="d-flex h-100 align-items-center justify-content-center"
      >
        <p class="m-0 text-center text-muted">
          {{ $t('app_list.tags.no_data') }}
        </p>
      </div>
    </div>

    <div class="card-footer" v-if="hasPagination">
      <base-pagination
        v-model="page"
        :page-count="campaignTags.pages.last"
        align="center"
      />
    </div>
  </div>
</template>
<script>
import lodashGet from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      page: 1,
    }
  },

  watch: {
    page: {
      immediate: true,
      handler: 'getCampaignTags',
    },
  },

  computed: {
    ...mapGetters({
      campaignTags: 'campaignTags/getCampaignTags',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    hasPagination() {
      return lodashGet(this.campaignTags, 'pages.has_pagination', false)
    },

    campaignTagsResults() {
      return lodashGet(this.campaignTags, 'results', [])
    },
  },

  methods: {
    ...mapActions({
      fetchCampaignTags: 'campaignTags/index',
    }),

    async getCampaignTags() {
      await this.fetchCampaignTags({
        id: this.campaignId,
        params: {
          per_page: 4,
          page: this.page,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group-item {
  &.disabled {
    h5,
    p {
      color: #c5c4c4;
    }
  }
}

.tags-card {
  height: calc(100% - 30px);
}
</style>
