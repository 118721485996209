import ApiService from '@/services/api'

const state = {
  type: false,
  types: false,
}

const getters = {
  getType: state => state.type,
  getTypes: state => state.types,
}

const actions = {
  async index({ commit }, params) {
    const { data, ok } = await ApiService.index('types', params)

    if (ok) {
      commit('setTypes', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('types', id)

    if (ok) {
      commit('setType', data)
    }

    return { data, ok }
  },

  async create({ commit }, payload) {
    const { data, ok } = await ApiService.create({
      resource: 'types',
      data: payload,
    })

    if (ok) {
      commit('setType', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.update({
      resource: 'types',
      id,
      data: payload,
    })

    if (ok) {
      commit('setType', data)
    }

    return { data, ok }
  },

  async delete(_, id) {
    const { ok } = await ApiService.delete('types', id)

    return { ok }
  },
}

const mutations = {
  setType(state, type) {
    state.type = type
  },

  setTypes(state, types) {
    state.types = types
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
