<template>
  <div>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <div class="modal-body py-0">
        <div class="row">
          <div class="col">
            <h2 class="mb-0">{{ $t('app_modal.creators.title') }}</h2>
            <p class="text-muted text-sm">
              {{ $t('app_modal.creators.subtitle') }} {{ campaign.name }}
            </p>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col" v-if="creator">
            <div class="row align-items-center">
              <div class="col-auto">
                <a href="#" class="avatar rounded-circle">
                  <app-image
                    alt="Image placeholder"
                    :src="creator.picture_url"
                  />
                </a>
              </div>
              <div class="col ml--2">
                <h4 class="mb-0">
                  <a href="#!">{{ creator.name }}</a>
                </h4>
                <small>@{{ creator.username }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <base-input
              :label="$t('app_modal.creators.fields.name.label')"
              :placeholder="$t('app_modal.creators.fields.name.placeholder')"
              name="name"
              :rules="{ required: true, min: 6 }"
              v-model="payload.name"
            />
            <base-input
              :label="$t('app_modal.creators.fields.username.label')"
              :placeholder="
                $t('app_modal.creators.fields.username.placeholder')
              "
              name="username"
              :rules="{ required: true, min: 6 }"
              v-model="payload.username"
              disabled
            />
            <base-input
              :label="$t('app_modal.creators.fields.provider.label')"
              name="provider"
              :rules="{ required: true }"
            >
              <el-select
                class="select-danger"
                :placeholder="
                  $t('app_modal.creators.fields.provider.placeholder')
                "
                v-model="payload.provider"
                style="width:100%"
              >
                <el-option
                  v-for="provider in providers.results"
                  class="select-danger"
                  :value="provider.id"
                  :label="provider.name"
                  :key="provider.id"
                />
              </el-select>
            </base-input>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <!-- TODO: implement this later (if necessary) -->
        <!-- <div class="col">
          <base-button type="danger">Deletar</base-button>
        </div> -->
        <div class="col text-right">
          <base-button type="secondary" @click="$emit('close')">
            {{ $t('app_modal.creators.buttons.close') }}
          </base-button>
          <base-button
            type="primary"
            :disabled="invalid"
            @click="creatorUpdate"
          >
            {{ $t('app_modal.creators.buttons.update') }}
          </base-button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'modal-creators',

  props: {
    options: {
      type: [Object, Boolean],
      default: () => {
        return {
          id: false,
        }
      },
    },
  },

  data() {
    return {
      isLoading: false,
      payload: false,
    }
  },

  computed: {
    ...mapGetters({
      providers: 'providers/getProviders',
      creator: 'creators/getCreator',
      campaign: 'campaigns/getCampaign',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    id() {
      return this.options.id
    },
  },

  methods: {
    ...mapActions({
      indexProviders: 'providers/index',
      showCreator: 'creators/show',
      updateCreator: 'creators/update',
    }),

    async campaignCreatorShow() {
      const { data, ok } = await this.showCreator(this.id)
      if (ok) {
        this.payload = {
          name: data.name,
          username: data.username,
          provider: data.provider.id,
        }
      }
    },

    async creatorUpdate() {
      const { ok } = await this.updateCreator({
        id: this.id,
        payload: this.payload,
      })

      if (ok) {
        this.$refs.observer.reset()
      }

      Swal.fire(
        ok
          ? this.$t('app_modal.creators.notifications.success_message.title')
          : this.$t('app_modal.creators.notifications.error_message.title'),
        ok
          ? this.$t('app_modal.creators.notifications.success_message.text')
          : this.$t('app_modal.creators.notifications.error_message.text'),
        ok ? 'success' : 'error'
      )
    },
  },

  async mounted() {
    await this.campaignCreatorShow()
    await this.indexProviders()
  },
}
</script>
