<template>
  <div>
    <div class="modal-body py-0">
      <validation-observer v-slot="{ invalid }">
        <div class="row">
          <div class="col">
            <h2 class="mb-0">
              {{ $t('app_modal.api_keys.title') }}
            </h2>
            <p class="text-muted text-sm">
              {{ $t('app_modal.api_keys.subtitle') }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-input
              :label="$t('app_modal.api_keys.name_input.label')"
              :name="$t('app_modal.api_keys.name_input.name')"
              type="text"
              :rules="{ required: true, min: 6 }"
              v-model="payload.name"
              :placeholder="$t('app_modal.api_keys.name_input.placeholder')"
            />
            <base-input
              :label="$t('app_modal.api_keys.expiration_date_input.label')"
              :name="$t('app_modal.api_keys.expiration_date_input.name')"
              type="datetime-local"
              v-model="expirationDate"
              @input="parseDate"
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            <base-button type="primary" outline @click="$emit('close')">
              {{ $t('app_modal.cancel_button') }}
            </base-button>
            <base-button
              type="primary"
              :disabled="invalid"
              @click="isUpdate ? apiKeyUpdate() : apiKeyCreate()"
            >
              {{
                isUpdate
                  ? $t('app_modal.update_button')
                  : $t('app_modal.add_button')
              }}
            </base-button>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import { mapActions } from 'vuex'

import { localToIso, isoToLocal } from '@/helpers/date'

export default {
  name: 'modal-apikeys',

  props: {
    options: [Object, Boolean],
  },

  data() {
    return {
      expirationDate: null,
      payload: {
        name: null,
        expiry_date: null,
      },
    }
  },

  computed: {
    isUpdate() {
      return (this.options || {}).method === 'update'
    },
  },

  methods: {
    ...mapActions({
      createApiKey: 'apiKeys/create',
      updateApiKey: 'apiKeys/update',
      indexApiKeys: 'apiKeys/index',
    }),

    parseDate(date) {
      this.payload.expiry_date = localToIso(date)
    },

    async apiKeyUpdate() {
      const { ok } = await this.updateApiKey({
        payload: this.payload,
        id: this.payload.prefix,
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_modal.api_keys.notify.update.success')
          : this.$t('app_modal.api_keys.notify.update.error'),
      })

      if (ok) {
        await this.indexApiKeys()
        this.$eventBus.$emit('hideModal')
      }
    },
    async apiKeyCreate() {
      const { data, ok } = await this.createApiKey(this.payload)

      if (ok) {
        const { isConfirmed } = await Swal.fire({
          title: this.$t('app_modal.api_keys.notify.add.title'),
          html: `${this.$t('app_modal.api_keys.notify.add.html', {
            key: data.key,
          })}`,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.$t(
            'app_modal.api_keys.notify.add.confirm_button'
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
        })

        if (isConfirmed) {
          await this.indexApiKeys()
          this.$eventBus.$emit('hideModal')
        }
      }
    },
  },

  mounted() {
    if (this.isUpdate) {
      this.payload = {
        ...this.options.data,
      }

      this.expirationDate = isoToLocal(this.options.data.expiry_date)
    }
  },
}
</script>
