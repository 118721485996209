<template>
  <div class="card">
    <div v-if="!isConnected" class="card-header">
      <div class="row justify-content-end">
        <div class="col-auto">
          <base-button icon type="danger" class="badge-pill" @click="connect">
            <i class="fab fa-google"></i>
            Conectar
          </base-button>
        </div>
      </div>
    </div>
    <div class="card-body position-relative">
      <div
        v-if="!isConnected"
        class="d-flex position-absolute justify-content-center align-items-center w-100 h-100 m--4 backdrop-light"
        style="z-index: 1000"
      >
        <div class="row justify-content-end">
          <div class="col-auto">
            <base-button icon type="danger" class="badge-pill" @click="connect">
              <i class="fab fa-google"></i>
              Conectar
            </base-button>
          </div>
        </div>
      </div>
      <full-calendar ref="fullcalendar" :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import rrulePlugin from '@fullcalendar/rrule'

import ApiService from '../../../services/api'
import { UserService } from '../../../services/user'

export default {
  components: {
    FullCalendar,
  },

  data() {
    return {
      /** @type import('@fullcalendar/common').CalendarOptions */
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          rrulePlugin,
        ],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        locale: 'pt-br',
        select: this.handleSelect,
        eventClick: this.handleEventClick,
        editable: false, // no drag-and-drop
        selectable: true,
        selectMirror: true,
        events: async info => {
          const { ok, data } = await ApiService.index(
            `${process.env.VUE_APP_ROOT_API}/tasks/chronograms/${this.$route.params.campaignId}/tasks/`,
            {
              start: info.start.valueOf(),
              end: info.end.valueOf(),
            }
          )

          if (!ok) return []
          else return data.data ? data.data : data
        },
        eventDataTransform: this.handleCustomEvent,
        eventClassNames: e => {
          const task = e.event.extendedProps.chronosTask
          if (!task) return []

          const iteration =
            (task.iterations || []).find(i => i.dueOn === e.event.startStr) ||
            null
          if (
            task.completedOn ||
            (e.event.allDay && iteration && iteration.completedOn)
          )
            return ['fc-completed']
          return []
        },
      },
      id: 0,
      task: {
        id: null,
        title: '',
        description: null,
      },
      isConnected: true,
    }
  },

  async mounted() {
    this.isConnected = await UserService.checkTasks()
  },

  methods: {
    handleSelect(info) {
      this.$eventBus.$emit('showModal', 'tasks', {
        modalClasses: 'modal-xl',
        info,
      })
    },

    async handleEventClick(info) {
      this.task = {
        ...info.event.extendedProps,
        id: info.event.id,
        info,
      }

      if (!this.task.chronosTask._id) {
        const { ok, data } = await ApiService.create({
          resource: `/tasks/chronograms/${this.task.chronosTask.chronogram}/tasks/connect`,
          data: {
            id: this.task.chronosTask.google.id,
            list: this.task.chronosTask.google.list,
          },
        })

        if (!ok) return
        this.task.chronosTask = data
        this.task.info.view.calendar.refetchEvents()
      }

      this.$eventBus.$emit('showModal', 'task-show', {
        modalClasses: 'modal-lg',
        info: this.task.info,
        data: this.task.chronosTask,
      })
    },

    handleCustomEvent(eventData) {
      const startDate = eventData.iterations
        ? eventData.iterations[0].dueOn
        : eventData.dueOn

      const recurrence = {
        allDay: !startDate.includes('T'),
      }

      const view = this.$refs.fullcalendar.getApi().view.type

      if (eventData.rrule && (recurrence.allDay || view === 'dayGridMonth')) {
        recurrence.rrule = eventData.rrule
      } else {
        let endDate = !eventData.iterations
          ? startDate
          : eventData.iterations[eventData.iterations.length - 1].dueOn

        if (endDate.includes('T')) {
          endDate = new Date(endDate)
          // endDate.setDate(endDate.getDate() + 1)
          endDate = endDate.toISOString()
        } else {
          endDate = new Date(`${endDate}T00:00:00-03:00`)
          endDate.setDate(endDate.getDate() + 1)
          endDate = endDate.toISOString().split('T')[0]
        }

        recurrence.start = startDate
        recurrence.end = endDate
      }

      return {
        id: eventData._id,
        title: eventData.name,
        ...recurrence,
        extendedProps: {
          chronosTask: eventData,
        },
      }
    },

    async connect() {
      const { data } = await ApiService.index('/tasks/auth/init', {
        redirect: 'false',
        next: window.location.href,
      })

      const a = document.createElement('a')
      a.href = data.url
      a.click()
    },
  },
}
</script>

<style>
.fc-completed div {
  text-decoration: line-through !important;
}
</style>
