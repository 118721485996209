<template>
  <table-wrapper
    :title="$t('app_table.posts.title')"
    :is-loading="isLoading"
    :pagination="pagination"
    @change-page="onChangePage"
  >
    <el-table
      ref="tableRef"
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :data="posts.results"
      :empty-text="$t('app_table.posts.no_data')"
    >
      <el-table-column>
        <template v-slot="{ row }">
          <app-image
            :src="row.data.media_url"
            class="avatar avatar-sm rounded-circle"
          />
        </template>
      </el-table-column>
      <el-table-column label="Type">
        <template v-slot="{ row }">
          <span v-if="row.type && types.results">
            {{ postType(row.type).name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Provider">
        <template v-slot="{ row }">
          <span v-if="row.provider && providers.results">
            {{ postProvider(row.provider).name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Created at">
        <template v-slot="{ row: { data } }">
          {{ data.created_at | moment('from', 'now') }}
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot="{ row }">
          <router-link :to="`/app/posts/${row.id}`" class="table-action-btn">
            <base-button type="secondary" outline size="sm">
              Ver comentários <i class="far fa-comments" />
            </base-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </table-wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui'

import TableWrapper from './TableWrapper'

export default {
  name: 'posts-index-table',

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    TableWrapper,
  },

  data() {
    return {
      isLoading: false,
      currentOrdering: ['-created_at', '-updated_at'],
    }
  },

  async mounted() {
    await this.init()
  },

  computed: {
    ...mapGetters({
      posts: 'posts/getPosts',
      types: 'types/getTypes',
      providers: 'providers/getProviders',
    }),

    pagination() {
      if (!this.posts) {
        return { current: 1 }
      }

      const { pages, count } = this.posts

      return {
        current: pages.current,
        perPage: pages.per_page,
        total: count,
      }
    },
  },

  methods: {
    ...mapActions({
      postIndex: 'posts/index',
      typeIndex: 'types/index',
      providerIndex: 'providers/index',
    }),

    async init() {
      await this.indexPost(1)
      await this.typeIndex()
      await this.providerIndex()
    },

    async indexPost(page, { ordering } = {}) {
      if (!ordering) {
        ordering = this.currentOrdering.join(',')
      }

      this.isLoading = true
      await this.postIndex({
        id: this.campaignId,
        params: {
          page,
          ordering,
        },
      })
      this.isLoading = false
    },

    async onRefresh() {
      await this.indexPost(this.pagination.current)
    },

    onChangePage(page) {
      if (page === this.pagination.current) {
        return
      }

      return this.indexPost(page)
    },

    postType(id) {
      if (!id) return
      return this.types.results.find(type => type.id === id)
    },

    postProvider(id) {
      if (!id) return
      return this.providers.results.find(provider => provider.id === id)
    },
  },
}
</script>

<style lang="scss" scoped>
.media {
  object-fit: cover;
  height: 64px;
  width: 64px;
  border-radius: 4px;
}

.table-action-btn:not(:last-child) {
  margin-right: 0.5rem;
}

.table-action-btn {
  min-width: 37px;
}
</style>
