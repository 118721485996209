<template>
  <div>
    <el-select v-model="activeLanguage">
      <el-option
        v-for="option in languages"
        class="select-default"
        :value="option.value"
        :label="option.label"
        :key="option.label"
      >
        <img :src="option.icon" /> {{ option.label }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'app-locale',

  data() {
    return {
      show: true,
      activeLanguage: false,
    }
  },

  watch: {
    activeLanguage: {
      handler: 'changeLocale',
    },
  },

  computed: {
    ...mapGetters({
      language: 'languages/getLanguage',
      languages: 'languages/getLanguages',
    }),
  },

  methods: {
    ...mapActions({
      updateLanguage: 'languages/updateLanguage',
    }),

    changeLocale() {
      this.$i18n.locale = this.activeLanguage
      this.$moment.locale(this.activeLanguage)
      this.updateLanguage(this.activeLanguage)
    },
  },

  mounted() {
    if (this.language) {
      this.activeLanguage = this.language
    }
  },
}
</script>
