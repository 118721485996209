import ApiService from '@/services/api'

const state = {
  campaignCreator: false,
  campaignCreators: false,
}

const getters = {
  getCampaignCreators: state => state.campaignCreators,
  getCampaignCreator: state => state.campaignCreator,
}

const actions = {
  async searchByNativeId({ commit }, { campaignId, id }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${campaignId}/creators/native/${id}`
    )

    if (ok) {
      commit('setCampaignCreator', data)
    }

    return { data, ok }
  },

  async search({ commit }, { id, search }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/creators`,
      search
    )

    if (ok && (data || {}).results.length) {
      commit('setCampaignCreator', data.results[0])
    }

    return { data, ok }
  },

  async searchMany({ commit }, { id, search }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/creators`,
      search
    )

    if (ok) {
      commit('setCampaignCreators', data)
    }

    return { data, ok }
  },

  async index({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/creators`,
      params
    )

    if (ok) {
      commit('setCampaignCreators', data)
    }

    return { data, ok }
  },

  async show({ commit }, { campaignId, id }) {
    const { data, ok } = await ApiService.show(
      `campaigns/${campaignId}/creators`,
      id
    )

    if (ok) {
      commit('setCampaignCreator', data)
    }

    return { data, ok }
  },

  async create({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.create({
      resource: 'campaigns',
      id,
      data: payload,
      relation: 'creators',
    })

    if (ok) {
      commit('setCampaignCreator', data)
    }

    return { data, ok }
  },

  clear({ commit }) {
    commit('setCampaignCreator', false)
    commit('setCampaignCreators', false)
  },
}

const mutations = {
  setCampaignCreators(state, campaignCreators) {
    state.campaignCreators = campaignCreators
  },

  setCampaignCreator(state, campaignCreator) {
    state.campaignCreator = campaignCreator
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
