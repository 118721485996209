<template>
  <card class="provider-sentiment-card">
    <template slot="header">
      <h6 class="surtitle">
        {{ $t('app_dash_charts.provider_sentiment_distribution.surtitle') }}
      </h6>
      <h5 class="h3 mb-0">
        {{ $t('app_dash_charts.provider_sentiment_distribution.title') }}
      </h5>
    </template>
    <apexchart
      v-if="hasData"
      type="bar"
      height="90%"
      :options="options"
      :series="series"
    ></apexchart>
    <div v-else class="d-flex h-100 align-items-center justify-content-center">
      <p class="m-0 text-center text-muted">
        {{ $t('app_dash_charts.provider_sentiment_distribution.no_data') }}
      </p>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  async mounted() {
    await this.fetchProviderSentimentDistribution({ id: this.campaignId })
  },

  computed: {
    ...mapGetters({
      providerSentimentDistribution:
        'campaignDashboard/getProviderSentimentDistribution',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    data() {
      if (!this.providerSentimentDistribution) {
        return []
      }

      return this.providerSentimentDistribution.results
    },

    hasData() {
      return !!this.data.length
    },

    sentiments() {
      const sentiments = {}

      this.data.forEach(data => {
        data.sentiments.forEach(({ count, ...sentiment }) => {
          sentiments[sentiment.id] = sentiment
        })
      })

      return Object.values(sentiments)
    },

    categories() {
      return this.data.map(d => d.name)
    },

    series() {
      const map = new Map()
      const sentimentMap = new Map()

      this.data.forEach(data => {
        data.sentiments.forEach(sentiment => {
          sentimentMap[sentiment.id] = this.$t('sentiments.' + sentiment.slug)
        })
      })

      const sentiments = Object.entries(sentimentMap)
      sentiments.forEach(([id, name]) => {
        map[id] = {
          data: new Array(sentiments.length).fill(0),
          name,
        }
      })

      this.data.forEach((data, index) => {
        data.sentiments.forEach(sentiment => {
          map[sentiment.id].data[index] = sentiment.count
        })
      })

      return Object.values(map)
    },

    options() {
      return {
        colors: this.sentiments.map(s => this.getSentimentColor(s.slug)),
        chart: {
          type: 'bar',
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          show: false,
        },
        legend: {
          position: 'right',
        },
      }
    },
  },

  methods: {
    ...mapActions({
      fetchProviderSentimentDistribution:
        'campaignDashboard/providerSentimentDistribution',
    }),

    getSentimentColor(sentiment) {
      const colors = {
        negative: '#f5365c',
        neutral: '#adb5bd',
        positive: '#2dce89',
      }

      return colors[sentiment] || '#000000'
    },
  },
}
</script>

<style scoped>
.provider-sentiment-card {
  height: calc(100% - 30px);
}
</style>
