import ApiService from '@/services/api'

const state = {
  users: [],
}

const getters = {
  getUsers: state => state.users,
}

const actions = {
  async updatePassword(_, { newPassword, confirmation }) {
    try {
      const { data, ok } = await ApiService.create({
        resource: 'rest-auth/password/change',
        data: {
          new_password1: newPassword,
          new_password2: confirmation,
        },
      })

      return { data, ok }
    } catch (err) {
      return err
    }
  },

  async index({ commit }, params) {
    const { data, ok } = await ApiService.index('users', params)

    if (ok) {
      commit('setUsers', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setUsers(state, users) {
    state.users = users
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
