import qs from 'qs'
import { StorageService } from '../services/storage'
import store from '../store'
import urlBuilder from '../helpers/urlBuilder'

const authorizationHeader = 'Authorization'
const languageHeader = 'Accept-Language'

class GenericService {
  constructor(api) {
    this.api = api
  }

  setHeader() {
    const token = StorageService.get('token')
    if (!token) {
      this.removeHeader()
      return
    }

    this.api.setHeader(authorizationHeader, `Bearer ${token}`)
    this.api.setHeader(languageHeader, 'pt-br')

    StorageService.save('lang', 'pt-br')
  }

  setLanguage(lang) {
    this.api.setHeader(languageHeader, lang)
    StorageService.save('lang', lang)
  }

  removeHeader() {
    StorageService.remove('token')
    this.api.deleteHeader(authorizationHeader)
  }

  index(resource, params) {
    return this.api.get(this._appendSlash(resource), params, {
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  }

  show(resource, id = '') {
    return this.api.get(this._appendSlash(`${resource}/${id}`))
  }

  create({ resource, data, headers, id, relation }) {
    const url = this._appendSlash(urlBuilder({ resource, id, relation }))
    return this.api.post(url, data, headers)
  }

  update({ resource, data, headers, id, relation, relationId }) {
    const url = this._appendSlash(
      urlBuilder({ resource, id, relation, relationId })
    )
    return this.api.patch(url, data, headers)
  }

  delete(resource, id) {
    return this.api.delete(this._appendSlash(`${resource}/${id}`))
  }

  addErrorMonitor() {
    return this.api.addMonitor(async response => {
      if (response.status === 401) {
        await store.dispatch('auth/logout')
      }
    })
  }

  _appendSlash(resource) {
    return resource.endsWith('/') ? resource : resource + '/'
  }
}

export default GenericService
