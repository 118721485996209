import auth from './auth'
import brands from './brands'
import campaignCreators from './campaignCreators'
import campaigns from './campaigns'
import commentSentiments from './commentSentiments'
import contentAnalytics from './contentAnalytics'
import contentComments from './contentComments'
import contents from './contents'
import creatorContents from './creatorContents'
import creators from './creators'
import files from './files'
import internalProviders from './internalProviders'
import kpis from './kpis'
import languages from './languages'
import medias from './medias'
import postComments from './postComments'
import posts from './posts'
import products from './products'
import providerForms from './providerForms'
import providers from './providers'
import sentiments from './sentiments'
import tags from './tags'
import types from './types'
import users from './users'
import campaignDashboard from './campaignDashboard'
import campaignContents from './campaignContents'
import campaignComments from './campaignComments'
import campaignTags from './campaignTags'
import apiKeys from './apiKeys'

export default {
  auth,
  brands,
  campaignCreators,
  campaigns,
  commentSentiments,
  contentAnalytics,
  contentComments,
  contents,
  creatorContents,
  creators,
  files,
  internalProviders,
  kpis,
  languages,
  medias,
  postComments,
  posts,
  products,
  providerForms,
  providers,
  sentiments,
  tags,
  types,
  users,
  campaignDashboard,
  campaignContents,
  campaignComments,
  campaignTags,
  apiKeys,
}
