import ApiService from '@/services/api'

const state = {
  postComment: false,
  postComments: false,
}

const getters = {
  getPostComment: state => state.postComment,
  getPostComments: state => state.postComments,
}

const actions = {
  async index({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(`posts/${id}/comments`, params)

    if (ok) {
      commit('setPostComments', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.update({
      resource: 'posts',
      id,
      data: payload,
      relation: 'comments',
      relationId: payload.id,
    })

    if (ok) {
      commit('setPostComment', data)
    }

    return { data, ok }
  },

  async load(_, id) {
    const { ok } = await ApiService.index(`posts/${id}/comments/load`, {
      limit: 200,
    })
    return { ok }
  },

  async exportCSV(_, { id }) {
    return ApiService.api.get(
      `posts/${id}/comments/export/?filetype=csv`,
      {},
      { responseType: 'blob', headers: { Accept: 'text/csv' } }
    )
  },
}

const mutations = {
  setPostComment(state, postComment) {
    state.postComment = postComment
  },

  setPostComments(state, postComments) {
    state.postComments = postComments
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
