import PublicService from '@/services/public'

const state = {
  apiKey: false,
  apiKeys: false,
}

const getters = {
  getApiKey: state => state.apiKey,
  getApiKeys: state => state.apiKeys,
}

const actions = {
  async index({ commit }, params) {
    const { data, ok } = await PublicService.index('api_keys', params)

    if (ok) {
      commit('setApiKeys', data)
    }

    return { data, ok }
  },

  async create({ commit }, payload) {
    const { data, ok } = await PublicService.create({
      resource: 'api_keys',
      data: payload,
    })

    if (data) {
      commit('setApiKey', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await PublicService.update({
      resource: 'api_keys',
      data: payload,
      id,
    })

    if (ok) {
      commit('setApiKey', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await PublicService.show('api_keys', id)

    if (ok) {
      commit('setApiKey', data)
    }

    return { data, ok }
  },

  async delete({ commit }, id) {
    const { data, ok } = await PublicService.delete('api_keys', id)
    return { data, ok }
  },
}

const mutations = {
  setApiKey(state, apiKey) {
    state.apiKey = apiKey
  },

  setApiKeys(state, apiKeys) {
    state.apiKeys = apiKeys
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
