import ApiService from '@/services/api'

const state = {
  file: false,
}

const getters = {
  getFile: state => state.file,
}

const actions = {
  async create({ commit }, { payload, headers }) {
    const { data, ok } = await ApiService.create({
      resource: 'files',
      data: payload,
      headers,
    })

    if (ok) {
      commit('setFile', data)
    }

    return { data, ok }
  },

  async ocr(_, { payload }) {
    const { data, ok } = await ApiService.create({
      resource: 'ocr',
      data: payload,
    })

    return { data, ok }
  },

  async delete(_, id) {
    const { ok } = await ApiService.delete('files', id)

    return { ok }
  },
}

const mutations = {
  setFile(state, file) {
    state.file = file
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
