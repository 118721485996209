import PagesLayout from '../views/layouts/Pages'
import AppLayout from '../views/layouts/app/Layout'
import CampaignsRouter from '../views/app/campaigns/Router'

const routes = [
  {
    path: '/',
    component: PagesLayout,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
    children: [
      {
        path: '',
        redirect: 'signin',
      },
      {
        path: 'signin',
        name: 'signin',
        component: () => import('../views/pages/Signin.vue'),
      },
    ],
  },
  {
    path: '/app',
    component: AppLayout,
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
    },
    children: [
      {
        path: '',
        redirect: 'dashboard',
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/app/Dashboard.vue'),
      },
      {
        path: 'posts',
        name: 'post index',
        component: () => import('../views/app/posts/Index.vue'),
      },
      {
        path: 'posts/:postId',
        name: 'post show',
        component: () => import('../views/app/posts/Show.vue'),
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('../views/app/Settings.vue'),
      },
      {
        path: 'campaigns/:campaignId',
        name: 'campaign',
        component: CampaignsRouter,
        children: [
          {
            path: '',
            name: 'show',
            component: () => import('../views/app/campaigns/Show.vue'),
          },
          {
            path: 'calendar',
            name: 'calendar',
            component: () =>
              import('../views/app/campaigns/calendar/Index.vue'),
          },
          {
            path: 'creators',
            name: 'creators',
            component: () =>
              import('../views/app/campaigns/creators/Index.vue'),
          },
          {
            path: 'creators/:creatorId',
            name: 'creator',
            component: () => import('../views/app/campaigns/creators/Show.vue'),
          },
          {
            path: 'contents',
            name: 'contents',
            component: () =>
              import('../views/app/campaigns/contents/Index.vue'),
          },
          {
            path: 'contents/:contentId',
            name: 'content',
            component: () => import('../views/app/campaigns/contents/Show.vue'),
          },
          {
            path: 'comments',
            name: 'comments',
            component: () =>
              import('../views/app/campaigns/comments/Index.vue'),
          },
        ],
      },
    ],
  },
]

export default routes
