<template>
  <component :is="type" />
</template>

<script>
import ContentDistribution from './partials/ContentDistribution'
import ProviderSentimentDistribution from './partials/ProviderSentimentDistribution'
import SentimentDistribution from './partials/SentimentDistribution'
import CommentsDistribution from './partials/CommentsDistribution'

export default {
  name: 'app-dash-charts',

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  components: {
    'content-distribution': ContentDistribution,
    'provider-sentiment-distribution': ProviderSentimentDistribution,
    'sentiment-distribution': SentimentDistribution,
    'comments-distribution': CommentsDistribution,
  },
}
</script>
