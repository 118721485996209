import ApiService from '@/services/api'

const state = {
  sentiment: false,
  sentiments: false,
}

const getters = {
  getSentiment: state => state.sentiment,
  getSentiments: state => state.sentiments,
}

const actions = {
  async index({ commit }, params) {
    const { data, ok } = await ApiService.index('sentiments', params)

    if (ok) {
      commit('setSentiments', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('sentiments', id)

    if (ok) {
      commit('setSentiment', data)
    }

    return { data, ok }
  },

  async create({ commit }, payload) {
    const { data, ok } = await ApiService.create({
      resoruce: 'sentiments',
      data: payload,
    })

    if (ok) {
      commit('setSentiment', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.update({
      resource: 'sentiments',
      id,
      data: payload,
    })

    if (ok) {
      commit('setSentiment', data)
    }

    return { data, ok }
  },

  async delete(_, id) {
    const { ok } = await ApiService.delete('sentiments', id)

    return { ok }
  },
}

const mutations = {
  setSentiment(state, sentiment) {
    state.sentiment = sentiment
  },

  setSentiments(state, sentiments) {
    state.sentiments = sentiments
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
