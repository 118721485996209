<template>
  <div class="tag bg-primary">
    <div class="label-header">
      <small class="label text-white">{{ label }}</small>
    </div>
    <div class="h5 mt--1 pb-0 mb-0 text-white">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },

  methods: {
    remove() {
      this.$emit('removed', this.label)
    },
  },
}
</script>

<style>
.tag {
  padding: 4px 16px;
  margin: 4px;
  border: none;
  border-radius: 99px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.label-header {
  display: flex;
  justify-content: flex-start;
}

.label {
  font-size: 9px;
}

.value {
  padding: 5px 0px;
  font-size: 13px;
}
</style>
