<template>
  <div>
    <div class="modal-body pb-0">
      <div class="row">
        <div class="col">
          <h2>Tags</h2>
          <p class="text-muted text-sm">
            Gerencie as tags de sua campanha.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input v-model="tagInput" placeholder="Nome da tag" />
        </div>
        <div class="col-auto pl-0">
          <base-button @click="createTag" type="primary">
            <i class="ni ni-fat-add" />
          </base-button>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <ul class="list-group">
            <li
              v-for="(tag, index) in tags.results"
              :key="index"
              class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                text-xs
              "
            >
              <template v-if="!isEditable(index)">
                {{ tag.name }}
                <i
                  @click="changeToEdit(tag, index)"
                  class="fas fa-pencil-alt"
                />
              </template>
              <template v-else>
                <base-input
                  v-model="editInput"
                  :value="payload.name"
                  input-classes="form-control-flush"
                  input-group-classes="m--5 pt-4 pl-5"
                />
                <div>
                  <template v-if="deleteConfirmation">
                    <span
                      class="text-xs text-primary"
                      @click="deleteTag(tag.id)"
                      >Ok, deletar</span
                    >
                    ou
                    <span
                      class="text-xs text-primary mr-1"
                      @click="deleteConfirmation = false"
                      >Cancelar</span
                    >
                  </template>
                  <base-button
                    @click="deleteConfirmation = true"
                    type="danger"
                    size="sm"
                    outline
                  >
                    <i class="fas fa-times" />
                  </base-button>
                  <base-button
                    @click="updateTag"
                    :disabled="hasChanges"
                    type="success"
                    size="sm"
                    outline
                  >
                    <i class="fas fa-check" />
                  </base-button>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Select, Option } from 'element-ui'

export default {
  name: 'tags-create-modal',

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      tagInput: null,
      editInput: null,
      payload: null,
      tagToEdit: null,
      deleteConfirmation: false,
    }
  },

  computed: {
    ...mapGetters({
      campaign: 'campaigns/getCampaign',
      tags: 'tags/getTags',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    brandId() {
      return this.campaign ? this.campaign.brand.id : false
    },

    hasChanges() {
      return this.payload.name === this.editInput
    },
  },

  methods: {
    ...mapActions({
      tagIndex: 'tags/index',
      tagCreate: 'tags/create',
      tagUpdate: 'tags/update',
      tagDelete: 'tags/delete',
    }),

    reset() {
      this.$data = {
        tagInput: null,
        editInput: null,
        payload: null,
        tagToEdit: null,
        deleteConfirmation: false,
      }
    },

    /**
     * Change to edit input
     */

    changeToEdit(payload = false, index = null) {
      if (payload) {
        this.editInput = payload.name
        this.payload = {
          id: payload.id,
          name: payload.name,
          brand: payload.brand.id,
        }
      } else {
        this.payload = false
      }

      this.deleteConfirmation = false
      this.tagToEdit = index
    },

    /**
     * Index Tags
     */

    async indexTag() {
      await this.tagIndex({
        brand__id: this.brandId,
      })
    },

    /**
     * Creating Tag
     */

    async createTag() {
      const { ok } = await this.tagCreate({
        name: this.tagInput,
        brand: this.brandId,
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok ? 'Tag criada com sucesso' : 'Erro ao criar tag',
      })

      if (ok) {
        await this.indexTag()
        this.tagInput = null
      }
    },

    /**
     * Updating tag
     */

    async updateTag() {
      const { ok } = await this.tagUpdate({
        payload: {
          ...this.payload,
          name: this.editInput,
        },
        id: this.payload.id,
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok ? 'Tag editada com sucesso' : 'Erro ao editar tag',
      })

      if (ok) {
        await this.indexTag()
        this.changeToEdit()
      }
    },

    /**
     * Deleting tag
     */

    async deleteTag(id) {
      const ok = await this.tagDelete(this.payload.id)

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok ? 'Tag deletada com sucesso' : 'Erro ao deletar tag',
      })

      if (ok) {
        await this.indexTag()
        this.changeToEdit()
      }
    },

    /**
     * Verify if is editable mode
     */

    isEditable(index) {
      return this.tagToEdit === index
    },
  },

  async mounted() {
    await this.indexTag()
  },
}
</script>
