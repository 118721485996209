<template>
  <component :is="type" :data="data" :options="options" />
</template>

<script>
import campaigns from './partials/Campaigns'
import comments from './partials/Comments'
import contents from './partials/Contents'
import creators from './partials/Creators'
import creatorContents from './partials/CreatorContents'
import posts from './partials/Posts'
import postComments from './partials/PostComments'

export default {
  name: 'app-table',

  props: {
    type: {
      type: String,
      required: true,
      default: 'campaigns',
    },
    data: {
      type: [Object, Boolean],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    campaigns,
    comments,
    contents,
    creators,
    'creator-contents': creatorContents,
    posts,
    'post-comments': postComments,
  },
}
</script>
