import ApiService from '@/services/api'

const state = {
  contentAnalytics: false,
}

const getters = {
  getContentAnalytics: state => state.contentAnalytics,
}

const actions = {
  async show({ commit }, id) {
    const { data, ok } = await ApiService.show(`contents/${id}/analytics`)

    if (ok) {
      commit('setContentAnalytics', data)
    }

    return { data, ok }
  },

  async create({ commit }, { id, payload, headers }) {
    const { data, ok } = await ApiService.create({
      resource: 'contents',
      data: payload,
      headers,
      id,
      relation: 'analytics',
    })

    if (ok) {
      commit('setContentAnalytics', data)
    }

    return { data, ok }
  },

  async update({ commit }, { contentId, id, payload, headers }) {
    const { data, ok } = await ApiService.update({
      resource: 'contents',
      data: payload,
      headers,
      id: contentId,
      relation: 'analytics',
      relationId: id,
    })

    if (ok) {
      commit('setContentAnalytics', data)
    }

    return { data, ok }
  },

  async exportCSV(_, { id }) {
    return ApiService.api.get(
      `contents/${id}/analytics/export/?filetype=csv`,
      {},
      { responseType: 'blob', headers: { Accept: 'text/csv' } }
    )
  },

  clear({ commit }) {
    commit('clearData')
  },
}

const mutations = {
  setContentAnalytics(state, contentAnalytics) {
    state.contentAnalytics = contentAnalytics
  },

  clearData(state) {
    state.contentAnalytics = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
