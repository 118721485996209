<template>
  <table-wrapper
    :title="$t('app_table.contents.title')"
    :is-loading="isLoading"
    :pagination="pagination"
    @change-page="onChangePage"
    @refresh="onRefresh"
  >
    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :data="creatorContents.results"
      :empty-text="$t('app_table.contents.no_data')"
    >
      <el-table-column
        :label="$t('app_table.contents.media_column.label')"
        min-width="150px"
      >
        <template v-slot="{ row }">
          <div class="row align-items-center">
            <div class="col-auto">
              <div v-if="row.medias.length > 0">
                <app-image
                  :alt="row.username"
                  :src="row.medias[0].media"
                  default-image="/img/logo-dots.png"
                  class="media"
                />
              </div>
            </div>
            <div class="col ml--2">
              <p class="text-xs text-muted mb-0">
                {{ row.data.caption | truncate(60) }}
              </p>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.contents.type_column.label')"
        min-width="60px"
      >
        <template v-slot="{ row }">
          {{ row.type.name }}
        </template>
      </el-table-column>

      <el-table-column
        min-width="110px"
        :label="$t('app_table.contents.tags_column.label')"
      >
        <template v-slot="{ row }">
          <template v-for="(tag, index) in row.tags">
            <badge :key="index" type="default" class="mr-2">{{
              tag.name
            }}</badge>
          </template>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.contents.created_at_column.label')"
        min-width="90px"
      >
        <template v-slot="{ row }">
          {{ row.created_at | moment('from', 'now') }}
        </template>
      </el-table-column>

      <el-table-column>
        <template v-slot="{ row }">
          <base-button
            type="secondary"
            outline
            size="sm"
            @click="showModal(row.id)"
          >
            <i class="fas fa-pencil-alt" />
          </base-button>
          <router-link :to="`/app/campaigns/${campaignId}/contents/${row.id}`">
            <base-button type="secondary" outline size="sm">
              <i class="far fa-eye" />
            </base-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </table-wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TableWrapper from './TableWrapper'

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui'

export default {
  name: 'creator-contents-index-table',

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    TableWrapper,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  async mounted() {
    await this.indexContent(1)
  },

  computed: {
    ...mapGetters({
      creatorContents: 'creatorContents/getCreatorContents',
    }),

    campaignCreatorId() {
      return this.$route.params.creatorId
    },

    campaignId() {
      return this.$route.params.campaignId
    },

    pagination() {
      if (!this.creatorContents) {
        return { current: 1 }
      }

      const { pages, count } = this.creatorContents

      return {
        current: pages.current,
        perPage: pages.per_page,
        total: count,
      }
    },
  },

  methods: {
    ...mapActions({
      creatorContentsIndex: 'creatorContents/index',
    }),

    showModal(id) {
      this.$eventBus.$emit('showModal', 'contents', {
        method: 'update',
        id: id,
      })
    },

    async indexContent(page) {
      this.isLoading = true
      await this.creatorContentsIndex({
        params: {
          campaign_creator__id: this.campaignCreatorId,
          page: page,
        },
      })
      this.isLoading = false
    },

    async onChangePage(page) {
      await this.indexContent(page)
    },

    async onRefresh() {
      await this.indexContent(this.pagination.current)
    },
  },
}
</script>

<style lang="scss" scoped>
.media {
  object-fit: cover;
  height: 64px;
  width: 64px;
  border-radius: 4px;
}
</style>
