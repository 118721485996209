<template>
  <div class="card kpis-card">
    <div class="card-header">
      <h6 class="surtitle">{{ $t('app_list.kpis.surtitle') }}</h6>
      <h5 class="h3 mb-0">{{ $t('app_list.kpis.title') }}</h5>
    </div>
    <div class="card-body">
      <ul
        v-if="kpiProgressResults.length"
        class="list-group list-group-flush list my--3"
      >
        <li
          v-for="(item, index) in kpiProgressResults"
          :key="index"
          :class="{ disabled: !item.progress.is_available }"
          class="list-group-item px-0"
        >
          <div class="row align-items-center">
            <div class="col">
              <h5>
                {{ $t('app_list.kpis.kpis.' + item.campaign_kpi.kpi.slug) }} -
                {{ getProgress(item) | number }}%

                <small v-if="!item.progress.is_available">
                  {{ $t('app_list.kpis.not_available') }}
                </small>
              </h5>
              <p class="text-xs">
                {{ $t('app_list.kpis.current') }}:
                {{ getProgressCurrentValue(item) | number('0,0a') }} |
                {{ $t('app_list.kpis.goal') }}:
                {{ getProgressTarget(item) | number('0,0a') }}
              </p>
              <base-progress
                class="progress-xs mb-0 mt-3"
                :type="
                  getNormalizedProgress(item) >= 100 ? 'success' : 'primary'
                "
                :value="getNormalizedProgress(item)"
              />
            </div>
          </div>
        </li>
      </ul>
      <div
        v-else
        class="d-flex h-100 align-items-center justify-content-center"
      >
        <p class="m-0 text-center text-muted">
          {{ $t('app_list.kpis.no_data') }}
        </p>
      </div>
    </div>

    <div class="card-footer" v-if="hasPagination">
      <base-pagination
        v-model="page"
        :page-count="kpiProgress.pages.last"
        align="center"
      />
    </div>
  </div>
</template>
<script>
import lodashGet from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      page: 1,
    }
  },

  watch: {
    page: {
      immediate: true,
      handler: 'getKpiProgress',
    },
  },

  computed: {
    ...mapGetters({
      kpiProgress: 'campaignDashboard/getKpiProgress',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    hasPagination() {
      return lodashGet(this.kpiProgress, 'pages.has_pagination', false)
    },

    kpiProgressResults() {
      return lodashGet(this.kpiProgress, 'results', [])
    },
  },

  methods: {
    ...mapActions({
      fetchKpiProgress: 'campaignDashboard/kpiProgress',
    }),

    async getKpiProgress() {
      await this.fetchKpiProgress({
        id: this.campaignId,
        params: {
          per_page: 4,
          page: this.page,
        },
      })
    },

    getNormalizedProgress(kpiProgress) {
      return Math.min(100, this.getProgress(kpiProgress))
    },

    getProgress(kpiProgress) {
      return kpiProgress.progress.is_available
        ? kpiProgress.progress.progress * 100
        : 0
    },

    getProgressCurrentValue(kpiProgress) {
      return kpiProgress.progress.is_available
        ? kpiProgress.progress.current
        : 0
    },

    getProgressTarget(kpiProgress) {
      return kpiProgress.progress.is_available ? kpiProgress.progress.target : 0
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group-item {
  &.disabled {
    h5,
    p {
      color: #c5c4c4;
    }
  }
}

.kpis-card {
  height: calc(100% - 30px);
}
</style>
