<template>
  <card v-loading="isLoading" no-body header-classes="border-0">
    <div slot="header">
      <div class="row">
        <div class="col d-flex align-items-center">
          <h3 class="mb-0 d-flex align-items-center">
            {{ title }}
            <badge type="primary" class="ml-2">{{ pagination.total }}</badge>
          </h3>
        </div>
        <div class="col-auto text-right">
          <slot name="toolbar" v-bind:refresh="onRefresh">
            <el-tooltip
              :content="$t('app_table.table_wrapper.refresh_tooltip')"
              placement="top"
            >
              <base-button size="sm" type="secondary" icon @click="onRefresh">
                <span class="fas fa-sync-alt" />
                <span class="btn-inner--text">
                  {{ $t('app_table.table_wrapper.refresh') }}
                </span>
              </base-button>
            </el-tooltip>
          </slot>
        </div>
      </div>
      <slot name="sub-header" />
    </div>

    <slot />

    <div slot="footer" class="row">
      <div class="offset-6 col-6 d-flex justify-content-end">
        <base-pagination
          class="mb-0"
          :value="pagination.current"
          :per-page="pagination.perPage"
          :total="pagination.total"
          @input="onPageChange"
        />
      </div>
    </div>
  </card>
</template>

<script>
import * as functional from '@/helpers/functional'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    pagination: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    perPage() {
      return functional.get(this.list, 'pages.per_page', null)
    },

    current() {
      return functional.get(this.list, 'pages.current', null)
    },
  },

  methods: {
    onPageChange(page) {
      this.$emit('change-page', page)
    },

    onRefresh() {
      this.$emit('refresh')
    },
  },
}
</script>
