import ApiService from '@/services/api'
import PublicService from '@/services/public'
import { StorageService } from '@/services/storage'

if (StorageService.get('token')) {
  PublicService.addErrorMonitor()
  PublicService.setHeader()
  ApiService.addErrorMonitor()
  ApiService.setHeader()
} else {
  PublicService.removeHeader()
  ApiService.removeHeader()
}
