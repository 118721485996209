import ApiService from '@/services/api'

const state = {
  provider: false,
  providers: false,
  providerService: {
    instagram: true,
    youtube: true,
    facebook: false,
    tiktok: false,
    twitter: true,
    twitch: false,
  },
}

const getters = {
  checkService: ({ providerService }) => provider =>
    !!providerService[provider],
  getProvider: state => state.provider,
  getProviders: state => state.providers,
}

const actions = {
  async index({ commit }, params) {
    const { data, ok } = await ApiService.index('providers', params)

    if (ok) {
      commit('setProviders', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('providers', id)

    if (ok) {
      commit('setProvider', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setProvider(state, provider) {
    state.provider = provider
  },

  setProviders(state, providers) {
    state.providers = providers
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
