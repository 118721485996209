<template>
  <modal
    :show.sync="show"
    @close="close({ withConfirmation: true })"
    :modal-classes="options ? options.modalClasses || '' : ''"
  >
    <validation-observer ref="observer">
      <div class="d-flex align-items-center">
        <span
          v-if="backTo"
          @click="setType(backTo, options)"
          class="text-xs text-primary"
        >
          <i class="ni ni-bold-left mr-1" /> Voltar
        </span>
        <div @click="close({ withConfirmation: true })" class="ml-auto">
          <i class="text-muted ni ni-fat-remove" />
        </div>
      </div>
      <slide-y-up-transition :duration="400" origin="center top" mode="out-in">
        <component
          :is="type"
          :options="options"
          @close="close"
          @status-changed="handleChange"
        />
      </slide-y-up-transition>
    </validation-observer>
  </modal>
</template>

<script>
import Swal from 'sweetalert2'
import { SlideYUpTransition } from 'vue2-transitions'

import campaigns from './partials/Campaigns'
import changeCampaignOwner from './partials/ChangeCampaignOwner'
import depacker from './partials/Depacker'
import analytics from './partials/Analytics'
import contents from './partials/Contents'
import creators from './partials/Creators'
import tags from './partials/Tags'
import apikeys from './partials/ApiKeys'
import tasks from './partials/Tasks'
import taskShow from './partials/TaskShow'
import createPost from './partials/CreatePost'

export default {
  name: 'app-modal',

  components: {
    SlideYUpTransition,
    campaigns,
    changeCampaignOwner,
    depacker,
    analytics,
    contents,
    creators,
    tags,
    apikeys,
    tasks,
    taskShow,
    createPost,
  },

  data() {
    return {
      show: false,
      type: null,
      backTo: false,
      options: false,
      saved: true,
    }
  },

  created() {
    this.$eventBus.$on('hideModal', () => this.hideModal())

    this.$eventBus.$on('showModal', (type, options = false) => {
      this.setType(type, options, false)
      this.showModal()
    })

    this.$eventBus.$on(
      'changeModal',
      (type, backTo = false, options = false) => {
        this.setType(type, options, backTo)
      }
    )
  },

  methods: {
    showModal() {
      this.show = true
    },

    setType(type, options, backTo) {
      const valid =
        [
          'campaigns',
          'change-campaign-owner',
          'depacker',
          'analytics',
          'contents',
          'creators',
          'tags',
          'apikeys',
          'tasks',
          'task-show',
          'create-post',
        ].indexOf(type) !== -1

      if (valid) this.type = type

      this.options = options
      this.backTo = backTo
    },

    async close({ withConfirmation, checkObserver = true } = {}) {
      if (checkObserver) await this.$refs.observer.validate({ silent: true })

      if (
        withConfirmation &&
        (!this.saved || (checkObserver && this.$refs.observer.flags.dirty))
      ) {
        const { isConfirmed } = await Swal.fire({
          title: this.$t('app_modal.confirm_message.title'),
          text: this.$t('app_modal.confirm_message.text'),
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: true,
          cancelButtonText: this.$t('app_modal.confirm_message.cancel_button'),
        })

        if (!isConfirmed) {
          this.show = true
          return
        }
      }

      this.hideModal()
    },

    hideModal() {
      this.show = false
      this.type = null
      this.backTo = false
      this.options = false
      this.saved = true
    },

    handleChange(status) {
      this.saved = status
    },
  },
}
</script>
