import { configure, extend } from 'vee-validate'

import * as rules from 'vee-validate/dist/rules'

import i18n from '@/plugins/i18n'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('positive', value => {
  if (value >= 0) {
    return true
  }
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
})

export default {
  install(Vue) {
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'],
      },
      defaultMessage: (field, values) => {
        // TODO: refactor messages to group field names.
        // as of today fields are being translated by their context
        // the idea is make field name translation global so that is easier to update.
        if (i18n.te(`fields.${field}`)) {
          values._field_ = i18n.t(`fields.${field}`)
        }
        return i18n.t(`validations.messages.${values._rule_}`, values)
      },
    })
  },
}
