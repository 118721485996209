const getFormDataMixin = object =>
  Object.keys(object).reduce((formData, key) => {
    if (typeof object[key] === 'object') {
      formData.append(key, JSON.stringify(object[key]))
    } else {
      formData.append(key, object[key])
    }
    return formData
  }, new FormData())

export default getFormDataMixin
