<template>
  <div>
    <base-nav
      v-model="show"
      class="navbar navbar-horizontal navbar-expand-lg bg-gradient-default"
      containerClasses="container-fluid"
    >
      <div class="ml-auto">
        <app-locale />
      </div>
    </base-nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    }
  },
}
</script>
