export function localToIso(localDate) {
  console.log('iso', new Date(localDate).toISOString())
  return new Date(localDate).toISOString()
}

export function isoToLocal(isoDate) {
  if (!isoDate) {
    return null
  }

  const timeIndex = isoDate.indexOf('T')
  if (timeIndex === -1) {
    return null
  }

  isoDate = utcToLocal(isoDate).toISOString()

  const datePart = isoDate.substring(0, timeIndex)
  const timePart = isoDate.substring(timeIndex, timeIndex + 6)
  const localDate = datePart + timePart
  console.log('localDate', localDate)
  return localDate
}

function utcToLocal(utcDate) {
  const date = new Date(utcDate)

  const timezoneOffset = date.getTimezoneOffset() * 60000
  const localTime = date.getTime() - timezoneOffset
  date.setTime(localTime)

  return date
}
