<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-center">
        <div>
          <h6 class="surtitle">{{ $t('app_list.api_keys.subtitle') }}</h6>
          <h5 class="h3 mb-0">{{ $t('app_list.api_keys.title') }}</h5>
          <a :href="docsUrl" target="_blank">
            <small>
              <i class="fas fa-external-link-alt text-xs"></i>
              {{ $t('app_list.api_keys.documentation') }}
            </small>
          </a>
        </div>
        <div class="ml-auto">
          <base-button type="primary" icon size="sm" @click="addApiKey">
            <i class="fa fa-plus" />
          </base-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ul
        v-if="apiKeysResults.length"
        class="list-group list-group-flush list my--3"
      >
        <li
          v-for="(item, index) in apiKeysResults"
          :key="index"
          :class="{ disabled: item.revoked }"
          class="list-group-item px-2 py-3"
        >
          <div class="d-flex align-items-top mb-3">
            <div>
              <h5 class="mb-0">
                <badge type="danger" v-if="item.revoked" class="mr-2">
                  {{ $t('app_list.api_keys.revoked') }}
                </badge>
                {{ item.name }}
              </h5>
              <p class="text-xs mb-0 mt-2">
                {{ $t('app_list.api_keys.prefix') }}:
                <span class="text-primary">{{ item.prefix }}</span>
              </p>
              <p class="text-xs ml-auto mb-0 mt-1">
                {{ $t('app_list.api_keys.created') }}
                {{ item.created | moment('from', 'now') }}
              </p>
              <p class="text-xs mb-0 mt-1" v-if="item.expiry_date !== null">
                {{ $t('app_list.api_keys.expires') }}
                {{ item.expiry_date | moment('from', 'now') }}
              </p>
            </div>
            <div class="ml-auto">
              <base-button
                type="danger"
                size="sm"
                outline
                :disabled="item.revoked"
                @click="revokeApiKey(item.prefix)"
              >
                {{ $t('app_list.api_keys.revoke') }}
              </base-button>
              <base-button
                type="primary"
                size="sm"
                outline
                :disabled="item.revoked"
                @click="editApiKey(item)"
              >
                {{ $t('app_list.api_keys.edit') }}
              </base-button>
            </div>
          </div>
        </li>
      </ul>
      <div
        v-else
        class="d-flex h-100 align-items-center justify-content-center"
      >
        <p class="m-0 text-center text-muted">
          {{ $t('app_list.api_keys.empty') }}
        </p>
      </div>
    </div>

    <div class="card-footer" v-if="hasPagination">
      <base-pagination
        v-model="page"
        :page-count="apiKeys.pages.last"
        align="center"
      />
    </div>
  </div>
</template>
<script>
import lodashGet from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      page: 1,
    }
  },

  watch: {
    page: {
      immediate: true,
      handler: 'getApiKeys',
    },
  },

  computed: {
    ...mapGetters({
      apiKeys: 'apiKeys/getApiKeys',
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    hasPagination() {
      return lodashGet(this.apiKeys, 'pages.has_pagination', false)
    },

    apiKeysResults() {
      return lodashGet(this.apiKeys, 'results', [])
    },

    docsUrl() {
      return process.env.VUE_APP_PUBLIC_API_URL + '/docs'
    },
  },

  methods: {
    ...mapActions({
      fetchApiKeys: 'apiKeys/index',
      deleteApiKeys: 'apiKeys/delete',
    }),

    async getApiKeys() {
      await this.fetchApiKeys({
        params: {
          per_page: 4,
          page: this.page,
        },
      })
    },

    addApiKey() {
      this.$eventBus.$emit('showModal', 'apikeys')
    },

    async revokeApiKey(prefix) {
      const { isConfirmed } = await Swal.fire({
        title: this.$t('app_list.api_keys.alerts.revoke_confirmation.title'),
        text: this.$t('app_list.api_keys.alerts.revoke_confirmation.text'),
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.$t(
          'app_list.api_keys.alerts.revoke_confirmation.cancel_button'
        ),
        confirmButtonText: this.$t(
          'app_list.api_keys.alerts.revoke_confirmation.confirm_button'
        ),
      })

      if (isConfirmed) {
        const { ok } = await this.deleteApiKeys(prefix)

        this.$notify({
          type: ok ? 'success' : 'danger',
          message: ok
            ? this.$t(
                'app_list.api_keys.alerts.revoke_feedback.success_message'
              )
            : this.$t('api_list.api_keys.alerts.revoke_feedback.error_message'),
        })

        if (ok) await this.getApiKeys()
      }
    },

    editApiKey(data) {
      this.$eventBus.$emit('showModal', 'apikeys', {
        method: 'update',
        data,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group-item {
  &.disabled {
    h5,
    p {
      color: #c5c4c4;
    }
  }
}
</style>
