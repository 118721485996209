import ApiService from '@/services/api'

const state = {
  content: false,
  contentMedia: false,
  contents: false,
  noComments: {
    twitter: true,
    instagram: {
      story: true,
      stories: true,
    },
  },
}

const checkComments = ({ noComments }, getters, rootState, rootGetters) => ({
  provider,
  type,
}) => {
  const hasProvider = rootGetters['providers/checkService'](provider)
  if (!hasProvider) {
    return false
  }

  try {
    const noComment = noComments[provider]

    if (noComment === true) {
      return false
    }

    const hasComments = !noComment[type]
    return hasComments
  } catch {
    return true
  }
}

const checkPublicationDate = (state, getters, rootState, rootGetters) => ({
  provider,
  type,
}) => {
  if (provider === 'twitter') {
    return true
  }

  return checkComments(
    state,
    getters,
    rootState,
    rootGetters
  )({ provider, type })
}

const getters = {
  getContent: state => state.content,
  getContentMedia: state => state.contentMedia,
  getContents: state => state.contents,
  checkComments,
  checkPublicationDate,
}

const actions = {
  async index({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/contents/`,
      params
    )

    if (ok) {
      commit('setContents', data)
    }

    return { data, ok }
  },

  async search({ commit }, { id, search }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/contents/`,
      search
    )

    if (ok && data.count === 1) {
      commit('setContent', data.results[0])
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('contents', id)

    if (data) {
      commit('setContent', data)
    }

    return { data, ok }
  },

  async create({ commit }, payload) {
    const { data, ok } = await ApiService.create({
      resource: 'contents',
      data: payload,
    })

    if (data) {
      commit('setContent', data)
    }

    return { data, ok }
  },

  async update({ commit }, { id, payload }) {
    const { data, ok } = await ApiService.update({
      resource: 'contents',
      id,
      data: payload,
    })

    if (ok) {
      commit('setContent', data)
    }

    return { data, ok }
  },

  async updateData({ commit }, id) {
    const { data, ok } = await ApiService.create({
      resource: 'contents',
      id,
      relation: 'update',
    })

    return { data, ok }
  },

  async delete(_, id) {
    const { ok } = await ApiService.delete('contents', id)

    return { ok }
  },

  async dynamicForm(_, payload) {
    const { provider, type } = payload
    const url = `contents/forms/${provider}/${type}/`
    const { data, ok } = await ApiService.index(url)
    if (ok) {
      return data
    }
    return null
  },

  async showMedia({ commit }, payload) {
    const { data, ok } = await ApiService.index('contents/fetch', payload)

    if (ok) {
      commit('setContentMedia', data)
    }

    return { data, ok }
  },

  clear(
    { commit },
    { content = true, contents = true, contentMedia = true } = {}
  ) {
    content && commit('setContent', false)
    contents && commit('setContents', false)
    contentMedia && commit('setContentMedia', false)
  },

  clearMedia({ commit }) {
    commit('setContentMedia', false)
  },
}

const mutations = {
  setContent(state, content) {
    state.content = content
  },

  setContentMedia(state, contentMedia) {
    state.contentMedia = contentMedia
  },

  setContents(state, contents) {
    state.contents = contents
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
