import VueMoment from 'vue-moment'
import moment from 'moment'

import 'moment/locale/pt-br'

export default {
  install(Vue) {
    Vue.use(VueMoment, { moment })
  },
}
