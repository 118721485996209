import ApiService from '@/services/api'

const state = {
  campaignComments: null,
}

const getters = {
  getCampaignComments: state => state.campaignComments,
}

const actions = {
  async exportCSV(_, { id }) {
    return ApiService.api.get(
      `campaigns/${id}/comments/export/?filetype=csv`,
      {},
      { responseType: 'blob', headers: { Accept: 'text/csv' } }
    )
  },

  async index({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(
      `campaigns/${id}/comments`,
      params
    )

    if (ok) {
      commit('setCampaignComments', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setCampaignComments(state, campaignComments) {
    state.campaignComments = campaignComments
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
