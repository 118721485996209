<template>
  <table-wrapper
    :title="$t('app_table.comments.title')"
    :is-loading="isLoading"
    :pagination="pagination"
    @change-page="onChangePage"
    @refresh="onRefresh"
  >
    <template slot="toolbar">
      <el-tooltip :content="$t('app_table.comments.load')" placement="top">
        <base-button
          size="sm"
          type="primary"
          outline
          icon
          @click="loadComments"
        >
          <span class="fas fa-search" />
          <span class="btn-inner--text">
            {{ $t('app_table.comments.load') }}
          </span>
        </base-button>
      </el-tooltip>
    </template>
    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :empty-text="$t('app_table.comments.no_data')"
      :data="comments ? comments.results : []"
      v-loading="isLoading"
    >
      <el-table-column
        :label="$t('app_table.comments.user_column.label')"
        min-width="90px"
      >
        <template v-slot="{ row }">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="avatar avatar-sm rounded-circle">
                <app-image
                  :alt="row.data.owner.name"
                  :src="row.data.owner.picture_url"
                  default-image="/img/logo-dots.png"
                />
              </div>
            </div>
            <div class="col ml--2">
              <h5 class="mb-0">
                {{ row.data.owner.name | truncate(15) }}
              </h5>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.comments.comment_column.label')"
        min-width="120px"
      >
        <template v-slot="{ row }">
          <div class="row align-items-center">
            <div class="col">
              <p class="text-xs my-0" v-if="expandIndex !== row.id">
                {{ row.data.text | truncate(70) }}
              </p>
              <p class="text-xs my-0" v-else>
                {{ row.data.text }}
              </p>

              <div
                v-if="row.data.text.length > 70"
                class="btn btn-outline-secondary btn-sm text-xs font-primary mt-2"
                @click="expandIndex = expandIndex === row.id ? false : row.id"
              >
                {{
                  expandIndex !== row.id
                    ? $t('app_table.comments.comment_column.expand')
                    : $t('app_table.comments.comment_column.contract')
                }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('app_table.comments.created_at_column.label')"
        prop="created_at"
        sortable
      >
        <template v-slot="{ row }">
          {{ row.created_at | moment('from', 'now') }}
        </template>
      </el-table-column>

      <el-table-column label="ML" min-width="30px">
        <template v-slot="{ row: { sentiments } }">
          <template v-if="sentiments.length > 0">
            <el-tooltip
              :content="
                sentiments[0].is_ml_classified
                  ? $t('app_table.comments.ml_column.ml_classified')
                  : $t('app_table.comments.ml_column.user_classified')
              "
              placement="top"
            >
              <i
                :class="
                  sentiments[0].is_ml_classified
                    ? 'fas fa-robot'
                    : 'fas fa-user'
                "
              />
            </el-tooltip>
          </template>
        </template>
      </el-table-column>

      <el-table-column min-width="130px">
        <template v-slot="{ row }" v-if="sentiments">
          <div class="d-flex">
            <div
              v-for="sentiment in sentiments.results"
              :key="sentiment.id"
              @click="toggleSentiment(row, sentiment.id)"
              class="sentiment"
              :class="isSelected(row, sentiment.id) ? 'selected' : ''"
            >
              {{ $t('sentiments.' + sentiment.slug) }}
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column min-width="50px">
        <template v-slot="{ row }" v-if="sentiments">
          <base-button
            size="sm"
            type="primary"
            :outline="!row.is_highlight"
            icon
            @click="toggleFavorite(row)"
          >
            <i class="fa fa-heart" />
          </base-button>
        </template>
      </el-table-column>
    </el-table>
  </table-wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import TableWrapper from './TableWrapper'

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui'

export default {
  name: 'posts-comments-index-table',

  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    TableWrapper,
  },

  data() {
    return {
      isLoading: false,
      selectedComment: false,
      sentimentId: false,
      expandIndex: false,
      filter: {
        is_classified: null,
        is_highlight: null,
      },
    }
  },

  async mounted() {
    await this.init()
  },

  computed: {
    ...mapGetters({
      sentiments: 'sentiments/getSentiments',
      comments: 'postComments/getPostComments',
    }),

    postId() {
      return this.$route.params.postId
    },

    pagination() {
      if (!this.comments) {
        return { current: 1 }
      }

      const { pages, count } = this.comments

      return {
        current: pages.current,
        perPage: pages.per_page,
        total: count,
      }
    },
  },

  methods: {
    ...mapActions({
      postCommentIndex: 'postComments/index',
      commentLoad: 'postComments/load',
      commentUpdate: 'postComments/update',
      commentSentimentCreate: 'commentSentiments/create',
      commentSentimentDelete: 'commentSentiments/delete',
      sentimentIndex: 'sentiments/index',
    }),

    async commentIndex(args) {
      return this.postCommentIndex(args)
    },

    async init() {
      await Promise.all([this.sentimentIndex(), this.indexComments(1)])
    },

    async indexComments(page) {
      this.isLoading = true

      const filterNames = {}

      const filters = {}
      Object.entries(this.filter).forEach(([key, value]) => {
        filters[filterNames[key] || key] = value
      })

      await this.commentIndex({
        id: this.postId || this.campaignId,
        params: {
          page,
          ...filters,
        },
      })

      this.isLoading = false
    },

    async onChangePage(page) {
      await this.indexComments(page)
    },

    async onRefresh() {
      await this.indexComments(this.pagination.current)
    },

    async loadComments() {
      this.isLoading = true

      const { ok } = await this.commentLoad(this.postId)

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_table.comments.load_comments_notify.success')
          : this.$t('app_table.comments.load_comments_notify.error'),
      })

      if (ok) await this.indexComments(1)

      this.isLoading = false
    },

    isSelected(comment, id) {
      if (comment && id) {
        return !!comment.sentiments.find(i => i.sentiment.id === id)
      }
      return false
    },

    async toggleSentiment(comment, id) {
      const isSelected = this.isSelected(comment, id)

      if (isSelected) {
        await this.removeSentiment(comment, id)
      } else {
        const hasSentiments = comment.sentiments.length > 0

        if (hasSentiments) {
          comment.sentiments.map(
            async s => await this.removeSentiment(comment, s.sentiment.id)
          )
        }
        const { ok } = await this.addSentiment(comment, id)

        this.$notify({
          type: ok ? 'success' : 'danger',
          message: ok
            ? this.$t('app_table.comments.update_comment_notify.success')
            : this.$t('app_table.comments.update_comment_notify.error'),
        })
      }
    },

    async toggleFavorite(comment) {
      let payload = {}

      this.comments.results.map(c => {
        if (c.id === comment.id) {
          c.is_highlight = !c.is_highlight
          payload = c
        }
      })

      const { ok } = await this.commentUpdate({
        id: this.postId || comment.content,
        payload,
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_table.comments.update_comment_notify.success')
          : this.$t('app_table.comments.update_comment_notify.error'),
      })
    },

    async addSentiment(comment, id) {
      const { ok } = await this.commentSentimentCreate({
        id: comment.id,
        payload: {
          sentiment_id: id,
          severity: 1,
        },
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_table.comments.sentiment_notify.success')
          : this.$t('app_table.comments.sentiment_notify.error'),
      })

      await this.indexComments(this.pagination.current)
    },

    async removeSentiment(comment, id) {
      const sentiment = comment.sentiments.find(i => i.sentiment.id === id)

      const { ok } = await this.commentSentimentDelete({
        commentId: sentiment.comment,
        id: sentiment.id,
      })

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t('app_table.comments.sentiment_notify.success')
          : this.$t('app_table.comments.sentiment_notify.error'),
      })

      await this.indexComments(this.pagination.current)
    },

    refetchComments: debounce(function() {
      return this.indexComments(1)
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.sentiment {
  border: 1px solid #ebeef5;
  color: #8898aa;
  border-radius: 8px;
  font-size: 0.7rem !important;
  padding: 2px 6px;
  margin-right: 8px;

  &.selected {
    background-color: #5e72e4;
    border-color: #5e72e4;
    color: #fff;
  }
}
</style>
