import ApiService from '@/services/api'

const state = {
  campaignTags: null,
}

const getters = {
  getCampaignTags: state => state.campaignTags,
}

const actions = {
  async index({ commit }, { id, params }) {
    const { data, ok } = await ApiService.index(`campaigns/${id}/tags`, params)

    if (ok) {
      commit('setCampaignTags', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setCampaignTags(state, campaignTags) {
    state.campaignTags = campaignTags
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
