import ApiService from '@/services/api'

const state = {
  internalProvider: false,
  internalProviders: false,
}

const getters = {
  getInternalProvider: state => state.internalProvider,
  getInternalProviders: state => state.internalProviders,
}

const actions = {
  async index({ commit }) {
    const { data, ok } = await ApiService.index('internal_providers')

    if (ok) {
      commit('setInternalProviders', data)
    }

    return { data, ok }
  },

  async show({ commit }, id) {
    const { data, ok } = await ApiService.show('internal_providers', id)

    if (ok) {
      commit('setInternalProvider', data)
    }

    return { data, ok }
  },
}

const mutations = {
  setInternalProvider(state, internalProvider) {
    state.internalProvider = internalProvider
  },

  setInternalProviders(state, internalProviders) {
    state.internalProviders = internalProviders
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
