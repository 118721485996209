/* eslint-disable quote-props */
<template>
  <notifications />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'app-alerts',

  watch: {
    alerts: function() {
      this.alerts.map(n => this.$notify(n))
      this.clearWithTimeout()
    },
  },

  computed: {
    ...mapGetters({
      alerts: 'alerts/getAlerts',
    }),
  },

  methods: {
    ...mapActions({
      setAlert: 'alerts/setAlert',
    }),

    clearWithTimeout() {
      if (this.alerts.length > 0) {
        setTimeout(() => {
          this.setAlert(false)
        }, 2000)
      } else {
        clearTimeout()
      }
    },
  },
}
</script>
