<template>
  <div>
    <div class="row align-items-center mt--2">
      <div class="col">
        <h2 class="mb-0">
          {{ options.data.name }}
        </h2>
        <p class="text-muted text-xs mb-0">
          Criada {{ new Date(options.data.createdOn) | moment('from', 'now') }}
        </p>
      </div>
      <div class="col-2">
        <p class="mb-0 text-xs font-weight-bold text-muted">
          Responsável
        </p>
        <p class="mb-0">{{ options.data.assignee }}</p>
      </div>
      <div class="col-2">
        <p class="mb-0 text-xs font-weight-bold text-muted">
          Concluir
        </p>
        <p class="mb-0">
          {{ new Date(options.data.dueOn) | moment('from', 'now') }}
        </p>
      </div>
      <div class="col-2">
        <p class="mb-0 text-xs font-weight-bold text-muted">
          Status
        </p>
        <p class="mb-0">
          <badge size="sm" :type="taskClass">
            {{ taskStatus }}
          </badge>
        </p>
      </div>
    </div>
    <hr class="my-4" />
    <div v-if="task.description" class="row">
      <div class="col">
        <p class="mb-0 text-xs font-weight-bold text-muted">
          Descrição
        </p>
        <div
          v-html="marked(task.description || '')"
          style="max-height:250px;overflow-y:auto;"
        />
      </div>
    </div>
    <div
      v-if="!task.parent && !hasIterations"
      class="row align-items-center mt-3"
    >
      <div class="col">
        <p class="mb-0 text-xs font-weight-bold text-muted">
          Subtasks ({{ completedSubtasks }} / {{ subtasksCount }})
          <base-button type="link" size="sm" @click="addSubtask(task)">
            Adicionar
          </base-button>
        </p>
      </div>
      <div v-if="subtasksCount" class="col">
        <base-progress
          class="progress-xs mb-0"
          :value="completedPercentage"
          :type="completedPercentage >= 100 ? 'success' : 'primary'"
        />
      </div>
    </div>
    <div class="row" v-if="subtasksCount">
      <div class="col">
        <ul class="list-group list-group-flush mt-3">
          <template v-for="subtask in subtasks">
            <li class="list-group-item" :key="subtask._id">
              <div
                class="checklist-item"
                :class="
                  subtask.completedOn !== null ? 'checklist-item-checked' : ''
                "
                @click="toggleSubtask(subtask._id)"
              >
                <div class="checklist-info">
                  <p class="checklist-title mb-0">
                    <span>{{ subtask.name }}</span>
                    <base-button
                      class="text-decoration-none"
                      type="link"
                      size="sm"
                      @click.stop="openSubtask(subtask)"
                    >
                      Olhar
                    </base-button>
                  </p>
                </div>
                <base-button
                  :type="subtask.completedOn !== null ? 'success' : 'secondary'"
                  size="sm"
                  :outline="subtask.completedOn === null"
                >
                  {{ subtask.completedOn === null ? 'Aguardando' : 'Completo' }}
                </base-button>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <base-button type="primary" size="sm" @click="editTask" outline>
          Editar
        </base-button>
      </div>
      <div class="col text-right">
        <base-button
          type="success"
          size="sm"
          :outline="!isCompleted"
          @click="completeTask"
        >
          <i class="fas fa-check" />
          {{ isCompleted ? 'Concluída' : 'Concluir tarefa' }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked'
import ApiService from '../../../../services/api'

export default {
  name: 'modal-task-show',

  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      marked,
    }
  },

  computed: {
    task() {
      return this.options.data
    },

    subtasks() {
      return this.task.subtasks
    },

    subtasksCount() {
      return this.subtasks !== null ? this.subtasks.length : 0
    },

    completedSubtasks() {
      return this.subtasksCount > 0
        ? (this.subtasks || []).filter(subtask => subtask.completedOn !== null)
            .length
        : 0
    },

    completedPercentage() {
      return (this.completedSubtasks * 100) / (this.subtasksCount || 1)
    },

    hasIterations() {
      return this.task.iterations !== null
    },

    isAllDay() {
      return !this.task.dueOn.includes('T')
    },

    eventDate() {
      return this.options.info.event._instance.range.start
        .toISOString()
        .split('T')[0]
    },

    iteration() {
      return this.hasIterations
        ? [...this.task.iterations]
            .sort(
              (a, b) =>
                new Date(a.dueOn).getTime() - new Date(b.dueOn).getTime()
            )
            .find(i => i.dueOn.split('T')[0] === this.eventDate)
        : null
    },

    isCompleted() {
      return this.hasIterations && this.isAllDay
        ? this.iteration.completedOn !== null
        : this.task.completedOn !== null
    },

    taskStatus() {
      const dueOn = new Date(this.options.data.dueOn)
      const now = new Date()
      const isCompleted = this.isCompleted

      return isCompleted ? 'Concluída' : dueOn < now ? 'Atrasada' : 'Aguardando'
    },

    taskClass() {
      const classes = {
        // eslint-disable-next-line prettier/prettier
        'Concluída': 'success',
        Atrasada: 'danger',
        Aguardando: 'primary',
      }

      return classes[this.taskStatus]
    },
  },

  methods: {
    addSubtask(task) {
      const allDay = !task.dueOn.includes('T')
      const date = new Date(allDay ? `${task.dueOn}T03:00:00Z` : task.dueOn)
      if (allDay) date.setDate(date.getDate() + 1)

      this.$eventBus.$emit('showModal', 'tasks', {
        modalClasses: 'modal-xl',
        info: {
          allDay,
          end: date,
          view: this.options.info.view,
        },
        extras: {
          parent: task._id,
        },
      })
    },

    editTask() {
      this.$eventBus.$emit('showModal', 'tasks', {
        modalClasses: 'modal-xl',
        info: this.options.info,
        data: this.options.data,
        method: 'update',
      })
    },

    openSubtask(subtask) {
      this.$eventBus.$emit('showModal', 'task-show', {
        modalClasses: 'modal-lg',
        info: this.options.info,
        data: subtask,
      })
    },

    toggleSubtask(id) {
      this.subtasks.map(s =>
        s._id === id
          ? s.completedOn !== null
            ? ((s.completedOn = null), this.updateSubtask(s))
            : ((s.completedOn = new Date()), this.updateSubtask(s))
          : s
      )
    },

    async completeTask() {
      const { _id: id, chronogram } = this.options.data

      const url =
        this.hasIterations && this.isAllDay
          ? `/tasks/chronograms/${chronogram}/tasks/${id}/iterations/${this.iteration._id}`
          : `/tasks/chronograms/${chronogram}/tasks/${id}`

      if (this.hasIterations && this.isAllDay) {
        this.iteration.completedOn = this.isCompleted ? null : new Date()
      } else {
        this.options.data.completedOn = this.isCompleted ? null : new Date()
      }

      const body =
        this.hasIterations && this.isAllDay ? this.iteration : this.options.data

      const { ok } = await ApiService.update({
        resource: url,
        data: { completedOn: body.completedOn },
      })

      if (ok && this.subtasksCount && body.completedOn)
        this.subtasks.forEach(subtask => {
          subtask.completedOn = body.completedOn
        })

      this.options.info.view.calendar.refetchEvents()
    },

    async updateSubtask(subtask) {
      const { _id: taskId, chronogram } = this.options.data

      await ApiService.update({
        resource: `/tasks/chronograms/${chronogram}/tasks/${taskId}/subtasks/${subtask._id}`,
        data: { completedOn: subtask.completedOn },
      })
    },
  },

  async mounted() {
    const { _id: id, chronogram } = this.options.data

    const { data } = await ApiService.index(
      `/tasks/chronograms/${chronogram}/tasks/${id}/subtasks`
    )

    this.task.subtasks = data
  },
}
</script>
