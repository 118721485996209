import BaseInput from '@/components/argon/Inputs/BaseInput.vue'
import BaseDropdown from '@/components/argon/BaseDropdown.vue'
import Card from '@/components/argon/Cards/Card.vue'
import Modal from '@/components/argon/Modal.vue'
import StatsCard from '@/components/argon/Cards/StatsCard.vue'
import BaseButton from '@/components/argon/BaseButton.vue'
import Badge from '@/components/argon/Badge.vue'
import RouteBreadcrumb from '@/components/argon/Breadcrumb/RouteBreadcrumb'
import BaseCheckbox from '@/components/argon/Inputs/BaseCheckbox.vue'
import BaseSwitch from '@/components/argon/BaseSwitch.vue'
import BaseRadio from '@/components/argon/Inputs/BaseRadio'
import BaseProgress from '@/components/argon/BaseProgress'
import BasePagination from '@/components/argon/BasePagination'
import BaseAlert from '@/components/argon/BaseAlert'
import BaseNav from '@/components/argon/Navbar/BaseNav'
import BaseHeader from '@/components/argon/BaseHeader'
import Tab from '@/components/argon/Tabs/Tab'
import Tabs from '@/components/argon/Tabs/Tabs'
import Breadcrumb from '@/components/argon/Breadcrumb/Breadcrumb'
import BreadcrumbItem from '@/components/argon/Breadcrumb/BreadcrumbItem'
import BarChart from '@/components/argon/Charts/BarChart'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Tooltip,
  Popover,
  Select,
  Option,
  Tag,
  Carousel,
  CarouselItem
} from 'element-ui'
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Badge.name, Badge)
    Vue.component(BaseAlert.name, BaseAlert)
    Vue.component(BaseButton.name, BaseButton)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseHeader.name, BaseHeader)
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseNav.name, BaseNav)
    Vue.component(BasePagination.name, BasePagination)
    Vue.component(BaseProgress.name, BaseProgress)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseSwitch.name, BaseSwitch)
    Vue.component(Card.name, Card)
    Vue.component(Modal.name, Modal)
    Vue.component(StatsCard.name, StatsCard)
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb)
    Vue.component(Tab.name, Tab)
    Vue.component(Tabs.name, Tabs)
    Vue.component(Breadcrumb.name, Breadcrumb)
    Vue.component(BreadcrumbItem.name, BreadcrumbItem)
    Vue.component(BarChart.name, BarChart)
    Vue.component('validation-provider', ValidationProvider)
    Vue.component('validation-observer', ValidationObserver)
    Vue.use(Tooltip)
    Vue.use(Popover)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Tag)
    Vue.use(Dropdown)
    Vue.use(DropdownItem)
    Vue.use(DropdownMenu)
    Vue.use(Carousel)
    Vue.use(CarouselItem)
  },
}

export default GlobalComponents
