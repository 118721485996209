<template>
  <side-bar
    backgroundColor="primary"
    logo="https://brmediagroup.com.br/img/logo-dark@3x.png"
  >
    <template slot="links">
      <sidebar-item
        :link="{
          name: $t('sidebar.dashboard'),
          icon: 'fas fa-bullhorn',
          path: '/app/dashboard',
        }"
      />
      <sidebar-item
        :link="{
          name: $t('sidebar.posts'),
          icon: 'far fa-images',
          path: '/app/posts',
        }"
      />
      <sidebar-item
        :link="{
          name: $t('sidebar.settings'),
          icon: 'fas fa-cog',
          path: '/app/settings',
        }"
      />
    </template>
    <template slot="links-after">
      <hr class="my-3" />
      <ul class="navbar-nav mb-md-3">
        <li class="nav-item">
          <div class="nav-link" @click="logout">
            <i class="fas fa-sign-out-alt" />
            <span class="nav-link-text">
              {{ $t('sidebar.logout') }}
            </span>
          </div>
        </li>
      </ul>
    </template>
  </side-bar>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'app-sidebar',
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
  },
}
</script>

<style>
.nav-link {
  cursor: pointer;
}
</style>
